import React, { useRef } from 'react'
import { RCDockLayout, DockLayout } from '@digitalworkflow/dwreactcommon'
import { RcGroup } from '../../components/RcGroup'
import WorkgroupAccessRequestsList from '../../components/WorkgroupAccessRequestsList/WorkgroupAccessRequestsList'

const ManageAccessRequests = () => {
  const dockLayoutRef = useRef<DockLayout | null>(null)

  const defaultLayout: any = {
    dockbox: {
      mode: 'vertical',
      children: [
        {
          id: 'workgroup_access_request',
          tabs: [
            {
              id: 'workgroup_access_request_list',
              close: false,
              title: 'Workgroup Access Requests',
              content: (
                <div className='p-2 flex flex-column grid-table'>
                  <h6>Workgroup Access Requests</h6>
                  <WorkgroupAccessRequestsList />
                </div>
              ),
              panelStyle: 'bg-transparent',
              group: 'headless'
            }
          ]
        }
      ]
    },
    floatbox: {
      children: []
    }
  }

  return (
    <RCDockLayout
      dockLayoutRef={dockLayoutRef}
      defaultLayout={defaultLayout}
      groups={RcGroup}
    />
  )
}
export default ManageAccessRequests
