import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { DockLayout, RCDockLayout } from '@digitalworkflow/dwreactcommon'
// import BulkActionProgress from './BulkActionProgress'
import ActionSelection from './ActionSelection'
import { IFactoryResponseUserListType, UserActionDataType } from './types'
import FromTheList from './FromTheList'
import ExceptionList from './ExceptionList'
import { RcGroup } from '../../components/RcGroup'
interface IBulkAction {
  layoutRef: RefObject<DockLayout | null>
  userListRef: RefObject<any>
}

const BulkAction = ({ layoutRef, userListRef }: IBulkAction) => {
  const dockLayoutRef = useRef<DockLayout | null>(null)
  const [userActionData, setUserActionData] =
    useState<UserActionDataType | null>(null)

  useEffect(() => {
    if (dockLayoutRef && dockLayoutRef.current && userActionData) {
      const _layouts = dockLayoutRef.current.getLayout()
      dockLayoutRef.current.loadLayout(_layouts)
    }
  }, [userActionData])

  const handleUserData = useCallback(
    (
      userList: IFactoryResponseUserListType[],
      exceptionList: IFactoryResponseUserListType[]
    ) => {
      setUserActionData({
        userList,
        exceptionList
      })

      const dockInstance = dockLayoutRef.current
      if (dockLayoutRef && dockInstance) {
        const _layout: any = dockInstance.find('exceptionList')
        if (_layout && _layout.parent) {
          _layout.parent.activeId = 'fromTheList'
          dockInstance.loadLayout(dockInstance.saveLayout())
        }
      }
    },
    [setUserActionData, dockLayoutRef]
  )

  const handleBulkSelection = () => {
    if (dockLayoutRef && dockLayoutRef.current) {
      const layouts = dockLayoutRef.current.getLayout()
      const _workgroupListTab: any = layouts.maxbox?.children.find(
        (item: any) => item.activeId === 'selection'
      )
      if (_workgroupListTab && layouts.maxbox) {
        layouts.maxbox.children = layouts.maxbox?.children.filter(
          (item: any) => item.activeId !== 'selection'
        )
        layouts.dockbox.children = layouts.dockbox?.children.map(
          (item: any) => {
            item = item.activeId === 'selection' ? actionSelection : item
            return item
          }
        )

        dockLayoutRef.current.loadLayout(layouts)
      }
    }
  }

  const actionSelection = {
    size: 400,
    children: [
      {
        tabs: [
          {
            id: 'selection',
            title: 'Action Selection',
            content: (
              <ActionSelection
                getResponseData={handleUserData}
                dockLayoutRef={dockLayoutRef}
                handleBulkSelection={handleBulkSelection}
              />
            ),
            group: 'close-all'
          }
        ],
        group: 'close-all'
      }
    ]
  }

  const defaultLayout: any = {
    dockbox: {
      mode: 'horizontal',
      children: [
        actionSelection,
        {
          mode: 'vertical',
          size: 800,
          children: [
            {
              tabs: [
                {
                  id: 'fromTheList',
                  title: 'From the list',
                  content: (
                    <FromTheList
                      userListRef={userListRef}
                      userList={userActionData?.userList ?? []}
                      layoutRef={layoutRef}
                    />
                  ),
                  group: 'close-all'
                },
                {
                  id: 'exceptionList',
                  title: 'Exception List',
                  content: (
                    <ExceptionList
                      exceptionList={userActionData?.exceptionList ?? []}
                    />
                  ),
                  group: 'close-all'
                }
              ],
              group: 'close-all'
            }
          ]
        }
      ]
    },
    floatbox: {
      children: []
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <RCDockLayout
        defaultLayout={defaultLayout}
        dockLayoutRef={dockLayoutRef}
        groups={RcGroup}
      />
    </div>
  )
}
export default BulkAction
