import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import style from './manageUser.module.scss'
import {
  AuthService,
  BulkFactoryType,
  BulkService,
  RoleService,
  PortalService,
  WorkgroupService,
  WorkgroupType,
  TemplateService
} from '@digitalworkflow/dwloginclient'
import { OptionProps } from 'react-select'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Col, Row, Spinner } from 'reactstrap'
import { IFactoryResponseUserListType } from './types'
import { sortArray } from '../../utils/sortArray'
import { toast } from 'react-toastify'
// import { GrapesjsReact } from 'grapesjs-react'
// import gjspresetwebpage from 'grapesjs-preset-webpage'
import { Actions, getActionDropdown } from '../../utils/bulkActions'
import {
  addFloatBox,
  getTab,
  removeRCTab,
  updateRcTab
} from '../../lib/rcDock-lib'
import { DockLayout, SelectDropdown } from '@digitalworkflow/dwreactcommon'
import CSVUpload from './CSVUpload'
import { userPermissionOfReadOnly } from '../../utils/userPermissions'

const authInstance = AuthService.instance()
const workgroupService = new WorkgroupService()
const portalService = new PortalService()
const roleService = new RoleService()
const bulkService = new BulkService()
// const templateService = new TemplateService()

WorkgroupService.setAuthServiceInstance(authInstance)
PortalService.setAuthServiceInstance(authInstance)
RoleService.setAuthServiceInstance(authInstance)
BulkService.setAuthServiceInstance(authInstance)
TemplateService.setAuthServiceInstance(authInstance)
interface IFormValue {
  resource: string
  userList: string
  value: string
  // notify: boolean
  templateKey: string
}

const validationSchema = Yup.object().shape({
  resource: Yup.string()
    .required('Resource  is required')
    .min(1, 'Resource must not be empty'),
  value: Yup.string().when('resource', ([param], schema) => {
    return param !== Actions.None
      ? schema.required('Value is required')
      : schema.notRequired()
  }),

  userList: Yup.string()
    .required('Emails are required')
    .test('isValidEmails', (value) => {
      // remove leading and trailing commas and white spaces
      const trimmedValue = value.trim().replace(/^,+\s*|\s*,+$/g, '')
      const emailRegex = /^[A-Za-z0-9'._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/
      const emailArray = trimmedValue
        .split(',')
        .map((email) => email.trim().toLowerCase())
      if (emailArray.length === 1) {
        const isValid = emailRegex.test(emailArray[0])
        if (!isValid) {
          throw new Yup.ValidationError(
            'Enter valid email address',
            value,
            'userList'
          )
        }
        return isValid
      } else {
        const isValid = emailArray.every((email) => {
          return emailRegex.test(email)
        })
        if (!isValid) {
          throw new Yup.ValidationError(
            'Enter valid email addresses',
            value,
            'userList'
          )
        }
        const uniqueEmails = new Set(emailArray)
        const isUnique = uniqueEmails.size === emailArray.length
        if (!isUnique) {
          throw new Yup.ValidationError(
            'Email addresses must be unique',
            value,
            'userList'
          )
        }
        return isUnique
      }
    })
  // notify: Yup.bool()
  // templateKey: Yup.string().when('notify', ([notify], schema) => {
  //   return notify
  //     ? schema.required('Email template is required')
  //     : schema.notRequired()
  // })
})

const initialValues: IFormValue = {
  resource: '',
  userList: '',
  value: '',
  // notify: false,
  templateKey: ''
}

interface IActionSelection {
  getResponseData: (
    userList: IFactoryResponseUserListType[],
    exceptionList: IFactoryResponseUserListType[]
  ) => void
  dockLayoutRef: RefObject<DockLayout | null>
  handleBulkSelection: () => void
}

const ActionSelection = ({
  getResponseData,
  dockLayoutRef,
  handleBulkSelection
}: IActionSelection) => {
  const [activeAction, setActiveAction] = useState<string | null>(null)
  const [activeActionType, setActiveActionType] = useState<string | null>(null)
  const [dropdownData, setDropdownData] = useState<OptionProps[]>([])
  // const [templateData, setTemplateData] = useState<OptionProps[]>()
  // const [templates, setTemplates] = useState<any[]>([])
  const [isWorkgroupLoading, setIsWorkgroupLoading] = useState<boolean>(false)
  const [isRoleLoading, setIsRoleLoading] = useState<boolean>(false)
  const [isPortalLoading, setIsPortalLoading] = useState<boolean>(false)
  // const [isTemplateLoading, setIsTemplateLoading] = useState</boolean>(false)
  // const [previewEmail, setPreviewEmail] = useState<any | null>()
  const isReadOnlyAccess = userPermissionOfReadOnly('Manage Users')
  const actionDropDown = useMemo(() => {
    return getActionDropdown()
  }, [])

  // useEffect(() => {
  //   const getAllTemplates = async () => {
  //     setIsTemplateLoading(true)
  //     const _result = await templateService.getAllTemplates()
  //     setIsTemplateLoading(false)
  //     if (!_result.is_error) {
  //       if (_result.data) {
  //         const data = _result.data
  //           ?.filter((item: any) => {
  //             return item.status === 'Publish'
  //           })
  //           .map((item: any) => ({
  //             value: item.key,
  //             label: item.name
  //           })) as unknown as OptionProps[]
  //         setTemplateData(data)
  //       }
  //       setTemplates(_result.data ?? [])
  //     }
  //   }
  //   getAllTemplates()
  // }, [])

  useEffect(() => {
    const getAllWorkgroup = async () => {
      if (isWorkgroupLoading) {
        return
      }
      setIsWorkgroupLoading(true)
      const _workgroup = await workgroupService.getAllWorkgroup('restricted')
      if (!_workgroup.is_error && _workgroup.data) {
        let _dropdownData = sortArray(
          _workgroup?.data ?? [],
          'formatted_workgroup_name'
        )

        _dropdownData = _dropdownData?.map((item: WorkgroupType) => {
          return {
            value: item.id,
            label: item.formatted_workgroup_name
          }
        })
        if (activeAction?.includes('Workgroup')) {
          setDropdownData([...(_dropdownData as unknown as OptionProps[])])
        }
      }
      setIsWorkgroupLoading(false)
    }
    const getAllRoles = async () => {
      if (isRoleLoading) {
        return
      }
      setIsRoleLoading(true)
      const _roles = await roleService.getAllRole('restricted')
      if (!_roles.is_error) {
        let _dropdownData = sortArray(_roles?.data ?? [], 'formatted_role_name')

        _dropdownData = _dropdownData?.map((item) => {
          return {
            value: item.id,
            label: item.formatted_role_name
          }
        })
        if (activeAction?.includes('Role')) {
          setDropdownData([...(_dropdownData as unknown as OptionProps[])])
        }
      }
      setIsRoleLoading(false)
    }

    const getAllPortals = async () => {
      if (isPortalLoading) {
        return
      }
      setIsPortalLoading(true)
      const _portals = await portalService.getAllPortals('restricted')
      if (!_portals.is_error) {
        let _dropdownData = sortArray(_portals?.data ?? [], 'portal_name')

        _dropdownData = _dropdownData?.map((item) => {
          return {
            value: item.id,
            label: item.portal_name
          }
        })
        if (activeAction?.includes('Portal')) {
          setDropdownData([...(_dropdownData as unknown as OptionProps[])])
        }
      }
      setIsPortalLoading(false)
    }

    if (activeAction) {
      switch (activeAction) {
        case Actions.Assign_Workgroup:
        case Actions.Remove_Workgroup:
          getAllWorkgroup()
          break

        case Actions.Assign_Role:
        case Actions.Remove_Role:
          getAllRoles()
          break

        case Actions.Assign_Portal:
        case Actions.Remove_Portal:
          getAllPortals()
          break

        default:
          break
      }
    }
  }, [activeAction])

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const handleBulkAction = useCallback(
    async (values: IFormValue, { resetForm }: any) => {
      const _values = {
        resource: values.resource.toLowerCase(),
        userList: values.userList
          .split(',')
          .map((email) => email.trim())
          .filter((email) => email.length > 0),
        action: activeActionType,
        value: values.value
        // notify: values.notify
        // ...(values.notify && { templateKey: values.templateKey })
      }
      const result: any = await bulkService.factory(_values as BulkFactoryType)
      if (!result.is_error) {
        result.data?.message &&
          toast.success(capitalizeFirstLetter(result.data.message))
        resetForm()
        setActiveAction(null)
        setDropdownData([])
        getResponseData(result.data?.userList, result.data?.exceptionList)
      } else {
        toast.error(result.message ?? 'Form Submission Error')
      }
      setActiveActionType(null)
    },
    [setDropdownData, activeAction]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema as any,
    enableReinitialize: true,
    onSubmit: handleBulkAction
  })

  const handleCSV = useCallback(() => {
    if (dockLayoutRef) {
      handleBulkSelection()
      addFloatBox(
        dockLayoutRef,
        'upload_csv',
        getTab(
          'upload_csv',
          'Bulk Action - Upload via CSV',
          dockLayoutRef,
          () => (
            <CSVUpload
              actionType={activeActionType ?? ''}
              resource={formik?.values.resource}
              value={
                Array.isArray(dropdownData) &&
                dropdownData.length > 0 &&
                formik.values.value
                  ? dropdownData?.find(
                      (item: any) => item.value === formik.values.value
                    )
                  : null
              }
              handleReset={handleReset}
            />
          ),
          600,
          200,
          600,
          0
        )
      )
    }
  }, [
    dockLayoutRef,
    activeActionType,
    formik?.values.resource,
    formik?.values.value
  ])

  const handleCancel = () => {
    formik.resetForm()
    setDropdownData([])
    setActiveActionType(null)
    setActiveAction(null)
  }
  const handleReset = (
    userList: IFactoryResponseUserListType[],
    exceptionList: IFactoryResponseUserListType[]
  ) => {
    handleCancel()
    dockLayoutRef && removeRCTab(dockLayoutRef, 'upload_csv')
    getResponseData(userList, exceptionList)
  }

  useEffect(() => {
    if (dockLayoutRef && dockLayoutRef.current) {
      updateRcTab(
        dockLayoutRef,
        'upload_csv',
        getTab(
          'upload_csv',
          'Bulk Action - Upload via CSV',
          dockLayoutRef,
          () => (
            <CSVUpload
              actionType={activeActionType ?? ''}
              resource={formik?.values.resource}
              value={
                Array.isArray(dropdownData) &&
                dropdownData.length > 0 &&
                formik.values.value
                  ? dropdownData?.find(
                      (item: any) => item.value === formik.values.value
                    )
                  : null
              }
              handleReset={handleReset}
            />
          ),
          600,
          200,
          600,
          0
        )
      )
    }
  }, [
    dockLayoutRef,
    activeActionType,
    formik?.values.resource,
    formik?.values.value
  ])
  const handleAction = (value: string) => {
    if (activeAction !== value) {
      formik.setFieldValue('value', '')
      setDropdownData({
        label: `Select ${activeAction}`,
        value: ''
      } as unknown as OptionProps[])
      setActiveAction(value)
      const action = value.split(' ')[0].toLowerCase()
      setActiveActionType(value === Actions.None ? 'assign' : action)
    }
    formik.setFieldTouched('resource', true)
    formik.setFieldValue(
      'resource',
      value === Actions.None ? Actions.None : value.split(' ')[1]
    )
  }

  const handleValue = (value: string) => {
    formik.setFieldTouched('value', true)
    formik.setFieldValue('value', value)
  }

  // const handleTemplate = (value: string) => {
  //   const preview = templates.find((template: any) => template.key === value)
  //   removeRCTab(dockLayoutRef, 'preview_email')
  //   setPreviewEmail(preview)
  //   formik.setFieldValue('templateKey', value)
  // }

  // const handlePreviewEmail = () => {
  //   if (dockLayoutRef && previewEmail) {
  //     addFloatBox(
  //       dockLayoutRef,
  //       'preview_email',
  //       getTab(
  //         'preview_email',
  //         previewEmail.name,
  //         () => (
  //           <div className='preview-mode'>
  //             <GrapesjsReact
  //               id='grapesjs-react'
  //               plugins={[gjspresetwebpage]}
  //               onInit={(editor) => {
  //                 editor?.setComponents(previewEmail.template, {})
  //                 editor?.runCommand('preview', {})
  //                 editor?.onReady(() => {
  //                   editor?.runCommand('preview', {})
  //                 })
  //               }}
  //               showToolbar={false}
  //               storageManager={false}
  //               height='600px'
  //             />
  //           </div>
  //         ),
  //         600, // Heigth
  //         450,
  //         800, // Width
  //         50
  //       )
  //     )
  //   }
  // }
  // const handlePreviewEmail = () => {
  //   if (dockLayoutRef && previewEmail) {
  //     addFloatBox(
  //       dockLayoutRef,
  //       'preview_email',
  //       getTab(
  //         'preview_email',
  //         previewEmail.name,
  //         dockLayoutRef,
  //         () => (
  //           <div className='preview-mode'>
  //             <GrapesjsReact
  //               id='grapesjs-react'
  //               plugins={[gjspresetwebpage]}
  //               onInit={(editor) => {
  //                 editor?.setComponents(previewEmail.template, {})
  //                 editor?.runCommand('preview', {})
  //                 editor?.onReady(() => {
  //                   editor?.runCommand('preview', {})
  //                 })
  //               }}
  //               showToolbar={false}
  //               storageManager={false}
  //               height='100%'
  //               width='100%'
  //             />
  //           </div>
  //         ),
  //         600,
  //         450,
  //         700,
  //         50
  //       )
  //     )
  //   }
  // }

  return (
    <div className={`overflow-auto  h-100 ${style.container}`}>
      <form onSubmit={formik.handleSubmit}>
        <div className={style.selectContainer}>
          <span className={style.action}>Select Action</span>
          <SelectDropdown
            options={actionDropDown}
            name='resource'
            isDisabled={isReadOnlyAccess}
            placeholder='Select Action'
            onChange={(value: any) => handleAction(value.value)}
            value={
              formik.values.resource
                ? actionDropDown.find(
                    (item: any) =>
                      item.value.toLowerCase() ===
                      `${activeActionType} ${formik.values.resource}`.toLowerCase()
                  )
                : null
            }
          />
          {formik.errors.resource && formik.touched.resource && (
            <p className={style.errorText}>{formik.errors.resource}</p>
          )}
        </div>
        <div className={style.selectContainer}>
          <span className={style.action}>
            Select
            {formik.values.resource === '' ||
            formik.values.resource === Actions.None
              ? ' Value'
              : ` ${formik.values.resource}`}
          </span>
          <SelectDropdown
            options={dropdownData}
            isDisabled={
              formik.values.resource === Actions.None ||
              isReadOnlyAccess ||
              isWorkgroupLoading ||
              isRoleLoading
            }
            name='value'
            placeholder={`Select ${
              formik.values.resource === '' ||
              formik.values.resource === Actions.None
                ? 'Value'
                : formik.values.resource
            }`}
            onChange={(value: any) => handleValue(value.value)}
            isLoading={isWorkgroupLoading || isRoleLoading}
            value={
              Array.isArray(dropdownData) &&
              dropdownData.length > 0 &&
              formik.values.value
                ? dropdownData?.find(
                    (item: any) => item.value === formik.values.value
                  )
                : null
            }
          />
          {formik.errors.value && formik.touched.value && (
            <p className={style.errorText}>{formik.errors.value}</p>
          )}
        </div>

        <div className={style.selectContainer}>
          <span className={style.action}>Enter email addresses of users</span>
          <br />
          <span className={style.Upload}>
            You can also paste in comma separated list{' '}
            <span onClick={isReadOnlyAccess ? undefined : handleCSV}>
              (Upload via CSV File)
            </span>
          </span>

          <div className={style.emailContainer}>
            <div className={style.inputContainer}>
              <textarea
                className={
                  formik.touched.userList && formik.errors.userList
                    ? style.error
                    : ''
                }
                name='userList'
                disabled={isReadOnlyAccess}
                rows={5}
                placeholder='Enter email'
                value={formik.values.userList}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.errors.userList && formik.touched.userList && (
              <p style={{ marginBottom: '0px' }} className={style.errorText}>
                {formik.errors.userList}
              </p>
            )}
          </div>
          {/* {getEnv() !== 'prod' && (
            <div className={`flex items-center gap-2 ${style.checkbox}`}>
              <input
                name='notify'
                type='checkbox'
                checked={formik.values.notify}
                onChange={formik.handleChange}
                disabled={
                  activeActionType === 'remove' || activeAction === Actions.None
                }
              />
              <span>Notify via email</span>
            </div>
          )} */}
        </div>

        {/* {formik.values.notify && (
          <div className={style.selectContainer}>
            <span className={style.action}>Select Email Template</span>
            <SelectDropdown
              name='templateKey'
              options={templateData}
              placeholder='Select Email Template'
              isLoading={isTemplateLoading}
              value={
                Array.isArray(templateData) &&
                templateData.length > 0 &&
                formik.values.templateKey
                  ? templateData.find(
                      (item: any) => item.value === formik.values.templateKey
                    )
                  : null
              }
              onChange={(value: any) => handleTemplate(value.value)}
            />
            {formik.errors.templateKey && formik.touched.templateKey && (
              <p className={style.errorText}>{formik.errors.templateKey}</p>
            )}
          </div>
        )} */}

        <Row className='mb-2'>
          <Col className='d-flex gap-2'>
            <Button
              type='submit'
              color='delete'
              className='btn-sm'
              disabled={isReadOnlyAccess}
            >
              Submit
              {formik.isSubmitting && <Spinner className={style.spinner} />}
            </Button>
            <Button
              type='button'
              color='edit'
              className='btn-sm'
              disabled={isReadOnlyAccess}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {/* <Button
              type='button'
              color='primary'
              className='btn-sm'
              onClick={handlePreviewEmail}
              disabled={formik.values.templateKey === ''}
            >
              Preview Email
            </Button> */}
          </Col>
        </Row>
      </form>
    </div>
  )
}

export default ActionSelection
