import {
  AuthService,
  WorkgroupService,
  WorkgroupType,
  UserType
} from '@digitalworkflow/dwloginclient'
import { LocalSettings } from './LocalSettings'

const authService = AuthService.instance()
WorkgroupService.setAuthServiceInstance(authService)
const workgroupService = new WorkgroupService()

const admins = [
  'DigitalWorkflow :: Admin',
  'DigitalWorkflow :: LoginPortal :: Admin',
  'DigitalWorkflow :: BuilderPortal :: Admin'
]

export const checkLoginPortalAdmin = (): boolean => {
  const userInfoString = LocalSettings.getUser()
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString)
    return userInfo.work_groups.some(
      (workgroup: WorkgroupType) =>
        admins[1] === workgroup.formatted_workgroup_name
    )
  } else {
    return false
  }
}

export const checkSuperAdmin = (): boolean => {
  const userInfoString = LocalSettings.getUser()
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString)
    return userInfo.work_groups.some((workgroup: WorkgroupType) =>
      admins.includes(workgroup.formatted_workgroup_name || '')
    )
  } else {
    return false
  }
}

export const checkSuperAdminByWGId = async (
  userInfo: UserType | null
): Promise<boolean> => {
  if (userInfo) {
    const user_workgroups_ids: string[] = userInfo.work_groups
    const res = await workgroupService.getAllWorkgroup()

    if (!res.is_error) {
      const user_workgroups = res.data?.filter(
        (workgroup) =>
          workgroup.id && user_workgroups_ids.includes(workgroup.id)
      )

      for (const workgroup of user_workgroups || []) {
        if (
          workgroup.formatted_workgroup_name &&
          admins.includes(workgroup.formatted_workgroup_name)
        ) {
          return true
        }
      }
    }
  }
  return false
}

export const checkBGISAdmin = (): boolean => {
  const userInfoString = LocalSettings.getUser()
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString)
    return userInfo.work_groups.some((workgroup: WorkgroupType) => {
      const compareText1 = 'BGISAdmin'
      const compareTexts =
        workgroup.formatted_workgroup_name?.split(' :: ') ?? []
      const compareText2 =
        compareTexts[0] + compareTexts[compareTexts?.length - 1]
      return compareText1 === compareText2
    })
  } else {
    return false
  }
}

export const checkManageSystemAccess = (): boolean => {
  const userInfoString = LocalSettings.getUser()
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString)
    return userInfo.work_groups.some(
      (item: WorkgroupType) =>
        item.formatted_workgroup_name === 'BGIS :: Manage System Messages'
    )
  }
  return false
}
