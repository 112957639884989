export const roleSelectStyle = {
  container: () => ({
    position: 'relative',
    boxSizing: 'border-box',
    height: '100%'
  }),
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    border: '1px solid hsl(0, 0%, 80%)',
    backgroundColor: '#FFFFFF',
    height: '100%'
  }),
  singleValue: (provided: any) => ({
    ...provided,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    textAlign: 'center',
    fontSize: '0.75em',
    minWidth: '90px'
  })
}
