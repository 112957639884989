import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'

interface IBadge {
  status: string
  tooltipText?: string
}
const Badge = ({ status, tooltipText }: IBadge) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  return (
    <div>
      {status && (
        <span
          id='tooltip'
          className={`tag ${
            status?.toLowerCase() === 'success' ? 'success' : 'failed'
          }`}
        >
          {status}
        </span>
      )}
      {tooltipText && (
        <Tooltip isOpen={tooltipOpen} target='tooltip' toggle={toggle}>
          {tooltipText}
        </Tooltip>
      )}
    </div>
  )
}
export default Badge
