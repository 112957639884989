import React, { useCallback, useState } from 'react'
import style from './manageUser.module.scss'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import ImportData from './ImportData'
import UploadCSVData from './UploadCSVData'
import PreviewEdit from './PreviewEdit'
import Papa from 'papaparse'
import { IFactoryResponseUserListType } from './types'

const tabs = [
  {
    id: 1,
    title: 'Upload'
  },
  {
    id: 2,
    title: 'Preview & Edit'
  },
  {
    id: 3,
    title: 'Import'
  }
]

interface ICSVUpload {
  actionType?: string
  resource?: string
  value?: any
  handleReset: (
    userList: IFactoryResponseUserListType[],
    exceptionList: IFactoryResponseUserListType[]
  ) => void
}

const CSVUpload = ({
  actionType,
  resource,
  value,
  handleReset
}: ICSVUpload) => {
  const [activeTab, setActiveTab] = useState<number>(1)
  const [importedData, setImportedData] = useState<any[]>([])
  // const [isNotify, setIsNotify] = useState<boolean>(false)
  const [templateValue, setTemplateValue] = useState<string>('')

  const uploadCSVFile = useCallback(async (file: File) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: any) {
        setImportedData(
          results.data.map((item: any, index: number) => {
            return {
              id: index + 1,
              Email: item.Email,
              'First Name': item['First Name'],
              'Last Name': item['Last Name'],
              Phone: item.Phone,
              'Job Title': item['Job Title']
            }
          })
        )
      }
    })
  }, [])

  const handleSubmitFile = useCallback(
    (file: File, templateValue: string) => {
      setActiveTab((prev) => ++prev)
      uploadCSVFile(file)
      // setIsNotify(isNotify)
      setTemplateValue(templateValue)
    },
    [setActiveTab, setTemplateValue]
  )

  const handleUsersList = useCallback(
    (userList: any[]) => {
      setActiveTab((prev) => ++prev)
      setImportedData([...userList])
    },
    [setActiveTab]
  )

  return (
    <div className={`p-2 ${style.csvContainer}`}>
      <Nav tabs className={style.tabContainer}>
        {tabs.map((item, index) => (
          <NavItem
            className={`${style.tab} ${
              item.id === activeTab ? style.activeTab : ''
            }`}
            key={index}
          >
            <NavLink>{item.title}</NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <UploadCSVData
            handleSubmitFile={handleSubmitFile}
            actionType={actionType ?? ''}
            resource={resource ?? ''}
            value={value?.label ?? ''}
          />
        </TabPane>
        <TabPane tabId={2}>
          <PreviewEdit
            usersDetails={importedData}
            handleUsersList={handleUsersList}
            hanldePreviousStep={() => setActiveTab((prev) => --prev)}
          />
        </TabPane>
        <TabPane tabId={3}>
          {activeTab === 3 && (
            <ImportData
              userList={importedData}
              actionType={actionType}
              resource={resource}
              // isNotify={isNotify}
              templateValue={templateValue}
              value={value?.value}
              handleReset={handleReset}
            />
          )}
        </TabPane>
      </TabContent>
    </div>
  )
}
export default CSVUpload
