import React, { useCallback, useEffect, useState } from 'react'
import style from './manageUser.module.scss'
import { AuthService, UserService } from '@digitalworkflow/dwloginclient'
import { toast } from 'react-toastify'
import { Button, Spinner } from 'reactstrap'
import { IFactoryResponseUserListType } from './types'

const authService = AuthService.instance()

UserService.setAuthServiceInstance(authService)

const userService = new UserService()

const defaultResponseMessage =
  'Records have been successfully imported from file. Click on Close to view the results.'
interface IImportData {
  userList: any[]
  actionType?: string
  resource?: string
  value?: string
  templateValue?: string
  // isNotify?: boolean
  handleReset: (
    userList: IFactoryResponseUserListType[],
    exceptionList: IFactoryResponseUserListType[]
  ) => void
}

const ImportData = ({
  userList,
  actionType,
  // isNotify = false,
  templateValue = '',
  resource,
  value,
  handleReset
}: IImportData) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>(defaultResponseMessage)
  const [resultUserList, setResultUserList] = useState<
    IFactoryResponseUserListType[]
  >([])
  const [resultExceptionList, setResultExceptionList] = useState<
    IFactoryResponseUserListType[]
  >([])

  useEffect(() => {
    if (userList && actionType) {
      addImportBulkAction()
    }
  }, [userList, actionType, resource, value])

  const addImportBulkAction = useCallback(async () => {
    setLoading(true)
    const _userList = [...userList]
    _userList.forEach(function (item) {
      delete item.id
    })
    const _result = await userService.Import(
      resource?.toLowerCase() as 'role' | 'workgroup' | 'portal',
      actionType?.toLowerCase() as 'assign' | 'remove',
      value as string,
      userList,
      false,
      templateValue
    )
    setLoading(false)
    setIsError(_result.is_error ?? false)

    if (_result.is_error) {
      toast.error(_result.message)
      setMessage(_result.message ?? '')
    } else {
      setMessage(_result.data.message ?? defaultResponseMessage)
      setResultUserList(_result.data?.userList ?? [])
      setResultExceptionList(_result.data?.exceptionList ?? [])
    }
  }, [userList, actionType, resource, value, templateValue])

  return (
    <div>
      <div className={style.progressBar}>
        {loading && (
          <>
            <div className={style.container}>
              <Spinner className={style.spinner} />
            </div>
            <p className={style.description}>
              Importing records from file... Please wait
            </p>
          </>
        )}
        {!loading && (
          <>
            {!isError ? (
              <i className={`fa-solid fa-circle-check ${style.icon} mt-4`} />
            ) : (
              <i
                className={`fa-solid fa-circle-exclamation mt-4 ${style.errorIcon}`}
              />
            )}
            <p className={style.description}>{message}</p>
            <Button
              className='btn-sm'
              type='button'
              color='info'
              onClick={() => handleReset(resultUserList, resultExceptionList)}
            >
              Close
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default ImportData
