import React, { useCallback, useMemo, useRef, useState } from 'react'
import polygon from '../../assets/images/polygon.svg'
import { Spinner, Tooltip } from 'reactstrap'
import style from './Role.module.scss'
import { useIsOverflow } from '../../hooks/userIsOverflow'
import { WorkgroupService } from '@digitalworkflow/dwloginclient'

interface IRole {
  title: string
  groups?: number
  workgroupRoleId: string | undefined
  activeWorkgroupRoleId?: string
  handleActiveRole: (workgroupRoleId: string | undefined) => void
}
const Role = ({
  title,
  groups,
  workgroupRoleId,
  activeWorkgroupRoleId,
  handleActiveRole
}: IRole) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const [totalMembers, setTotalMembers] = useState<number>()
  const toggle = () => setTooltipOpen(!tooltipOpen)

  const workgroupService = new WorkgroupService()

  const parentRef = useRef(null)
  const childRef = useRef(null)

  const getWorkgroupContent = useCallback(async () => {
    try {
      toggle()

      if (workgroupRoleId && totalMembers === undefined) {
        const workgroupRes = await workgroupService.GetWorkgroupById(
          workgroupRoleId
        )

        const totalMembers = (workgroupRes.data as any)?.total_members

        setTotalMembers(totalMembers)
      }
    } catch (error) {
      // TODO: Error handling required?
    }
  }, [workgroupRoleId, totalMembers])

  const isOverflow = useIsOverflow(parentRef, childRef)
  const toolTipContent = useMemo(() => {
    if (isOverflow) {
      return (
        <div>
          <b>Name: </b> {title} <br />
          {totalMembers !== undefined && (
            <span>
              <b>Total Members: </b>
              {totalMembers}
            </span>
          )}
        </div>
      )
    } else {
      if (totalMembers !== undefined) {
        return (
          <div>
            <span>
              <b>Total Members: </b>
              {totalMembers}
            </span>
          </div>
        )
      } else {
        return <Spinner size='sm' />
      }
    }
  }, [isOverflow, totalMembers, title])

  const handleMouseEnter = () => {
    getWorkgroupContent()
  }

  return (
    <div className='flex'>
      <div
        className={`${style.roleWorkgroup} ${
          activeWorkgroupRoleId === workgroupRoleId ? style.activeRole : ''
        } flex justify-between items-center`}
        onClick={() => handleActiveRole(workgroupRoleId)}
      >
        <div
          ref={parentRef}
          className={style.titleContainer}
          style={{
            width: groups !== undefined ? '55%' : '100%'
          }}
        >
          <span
            className={`${
              activeWorkgroupRoleId === workgroupRoleId ? style.activeTitle : ''
            }`}
            id={'titleTooltip' + workgroupRoleId}
            ref={childRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={toggle}
          >
            {title}
          </span>
        </div>
        {toolTipContent && (
          <Tooltip
            isOpen={tooltipOpen}
            target={'titleTooltip' + workgroupRoleId}
            toggle={toggle}
          >
            {toolTipContent}
          </Tooltip>
        )}
        {groups !== undefined && (
          <span className={style.group}>{`${groups} ${
            groups > 1 ? 'Workgroups' : 'Workgroup'
          }`}</span>
        )}
      </div>
      <div className={style.polygon}>
        {activeWorkgroupRoleId === workgroupRoleId && <img src={polygon} />}
      </div>
    </div>
  )
}

export default Role
