import React, { useState, useMemo } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { getSentEmailBody } from '../../utils/getSentEmails'
import { getFailedEmailBody } from '../../utils/getFailedEmails'

const TableCellHTML: React.FC<{
  id: string
  type: string
  bodyPreview?: string
}> = ({ id, type, bodyPreview }) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [emailBody, setEmailBody] = useState<string>('')
  const modifyLinks = (htmlContent: string): string => {
    // Use a regular expression to find all <a> tags and add target="_blank" if not already present
    return htmlContent.replace(/<a([^>]+)>/gi, (match, content) => {
      // Check if the target attribute is already present
      if (!/target=["']_blank["']/.test(content)) {
        return `<a${content} target="_blank">`
      }
      return match
    })
  }

  const fetchEmailBody = async () => {
    let res
    try {
      if (type === 'sent_emails') {
        res = await getSentEmailBody({ _id: id })
      } else if (type === 'failed_emails') {
        res = await getFailedEmailBody({ _id: id })
      } else {
        setEmailBody(modifyLinks(bodyPreview || ''))
        return
      }
      setEmailBody(modifyLinks(res.data.data))
    } catch (error) {
      console.error('Error fetching email body:', error)
      setEmailBody('Failed to load content.')
    }
  }

  const handleViewClick = async () => {
    await fetchEmailBody()
    setOpen(true)
  }

  const emailTemplate = useMemo(() => {
    const blob = new Blob([emailBody], { type: 'text/html' })
    return URL.createObjectURL(blob)
  }, [emailBody])

  return (
    <div>
      <Button
        type='button'
        color='add'
        className='btn-sm'
        onClick={handleViewClick}
      >
        View
      </Button>
      <div>
        <Modal size='xl' centered isOpen={isOpen} toggle={() => setOpen(false)}>
          <ModalHeader toggle={() => setOpen(false)}>
            {type === 'blocked_emails' ? 'Reason' : 'Email Body'}
          </ModalHeader>
          <ModalBody>
            {type === 'blocked_emails' ? (
              <div
                style={{
                  backgroundColor: '#eeeeee',
                  padding: '15px'
                }}
              >
                {emailBody}
              </div>
            ) : (
              <iframe
                style={{
                  height: 'calc(100vh - 500px)',
                  width: '100%'
                }}
                src={emailTemplate}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    </div>
  )
}

export default TableCellHTML
