import { LocalSettings } from './LocalSettings'

export enum Actions {
  Assign_Workgroup = 'Assign Workgroup',
  Assign_Role = 'Assign Role',
  Assign_Portal = 'Assign Portal',
  Remove_Workgroup = 'Remove Workgroup',
  Remove_Role = 'Remove Role',
  Remove_Portal = 'Remove Portal',
  None = 'None'
}

export const getActionDropdown = () => {
  const userData = JSON.parse(LocalSettings.getUser() ?? '')

  return [
    ...(userData.features_enabled.some(
      (item: any) => item.feature_name === 'Manage Workgroups'
    )
      ? [
          {
            value: Actions.Assign_Workgroup,
            label: Actions.Assign_Workgroup
          },
          {
            value: Actions.Remove_Workgroup,
            label: Actions.Remove_Workgroup
          }
        ]
      : []),

    // ...(userData.features_enabled.some(
    //   (item: any) => item.feature_name === 'Manage Roles'
    // )
    //   ? [
    //       {
    //         value: Actions.Assign_Role,
    //         label: Actions.Assign_Role
    //       },
    //       {
    //         value: Actions.Remove_Role,
    //         label: Actions.Remove_Role
    //       }
    //     ]
    //   : []),
    // ...(userData.features_enabled.some(
    //   (item: any) => item.feature_name === 'Manage Portals'
    // )
    //   ? [
    //       {
    //         value: Actions.Assign_Portal,
    //         label: Actions.Assign_Portal
    //       },

    //       {
    //         value: Actions.Remove_Portal,
    //         label: Actions.Remove_Portal
    //       }
    //     ]
    //   : []),

    {
      value: Actions.None,
      label: Actions.None
    }
  ]
}
