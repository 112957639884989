/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Spinner, Button, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import Select from '../../components/select/select'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  AuthService,
  RoleService,
  RoleType
} from '@digitalworkflow/dwloginclient'
import {
  errorStyle,
  manageSelectStyle
} from '../../components/select/manageUser.styles'
import style from './EditRole.module.scss'
import { sortArray } from '../../utils/sortArray'
import OverlayLoader from '../../components/OverlayLoader/overlayLoader'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { IDropdown } from '../AddRole/AddWorkgroup'
import { DockLayout, TabData } from '@digitalworkflow/dwreactcommon'

const isNotEmpty = (value: string) => value.trim().length > 0
const isWithinMaxLength = (value: string, maxLength: number) =>
  value.length <= maxLength

const validationSchema = Yup.object().shape({
  role_name: Yup.string()
    .required('Role name is required')
    .test(
      'no-only-spaces',
      'Role name cannot consist of only spaces',
      isNotEmpty
    )
    .test(
      'max-characters',
      'Role name must be at most 100 characters',
      (value) => isWithinMaxLength(value, 100)
    )
    .test(
      'no-leading-or-trailing-spaces',
      'Role name cannot have leading or trailing spaces',
      (value) => value.trim() === value
    ),
  approver_group_name_id: Yup.string(),
  parent_role_id: Yup.string(),
  description: Yup.string()
    .required('Description is required')
    .test(
      'no-only-spaces',
      'Description cannot consist of only spaces',
      isNotEmpty
    )
    .test(
      'max-characters',
      'Description must be at most 200 characters',
      (value) => isWithinMaxLength(value, 200)
    )
})

interface FormValue {
  role_name: string
  approver_group_name_id: string
  parent_role_id: string
  description: string
}

interface Props {
  activeRole: RoleType | null
  handleNewRole: () => void
  dockLayoutRef: DockLayout
  id?: string | 'editRole'
}

const authInstance = AuthService.instance()
RoleService.setAuthServiceInstance(authInstance)
const roleService = new RoleService()

const EditRole = ({ activeRole, handleNewRole, dockLayoutRef, id }: Props) => {
  const [dropdownData, setDropdownData] = useState<IDropdown[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    getRoles()
  }, [])

  const handleFormattedRoleName = useCallback((role: RoleType | null) => {
    if (role) {
      const extractedString = role.formatted_role_name.substring(
        0,
        role.formatted_role_name.lastIndexOf('::')
      )
      return extractedString ?? ''
    }

    return ''
  }, [])

  const formatedRole = useMemo(() => {
    return handleFormattedRoleName(activeRole)
  }, [activeRole])

  const initialValues = useMemo((): FormValue => {
    return {
      role_name: activeRole?.role_name ?? '',
      parent_role_id: activeRole?.parent_role_id ?? '',
      approver_group_name_id: activeRole?.approver_group_name_id ?? '',
      description: activeRole?.description ?? ''
    }
  }, [activeRole])

  const getRoles = useCallback(async () => {
    setIsLoading(true)
    const roles: ApiResultType<RoleType[]> = await roleService.getAllRole(
      'restricted'
    )
    if (!roles.is_error && roles.data) {
      const _roleDropdownData: RoleType[] = sortArray(
        roles.data,
        'approver_group_name_id'
      )
      const _dropdownData = _roleDropdownData.map((item) => {
        return {
          label: item.formatted_role_name,
          value: item.id
        }
      })
      setDropdownData([...(_dropdownData as IDropdown[])])
    }
    setIsLoading(false)
  }, [setIsLoading, setDropdownData])

  const updateRoleDetails = useCallback(async (values: FormValue) => {
    // const formattedRoleName = handleFormattedRoleName()
    // values.formatted_role_name = `${formattedRoleName} :: ${values.role_name}`
    try {
      const result = await roleService.updateRoleById(
        activeRole?.id || '',
        values
      )
      if (result.is_error) {
        toast.error(result.message ?? 'Form Submission Error')
      } else {
        handleNewRole()
        toast.success('Role information is updated successfully')

        // close the tab
        const dockRef = dockLayoutRef as any

        if (dockRef) {
          const tab = dockRef.find(id) as TabData
          dockRef.dockMove(tab, null, 'remove')
        }
      }
    } catch (e) {
      toast.error('Form Submission Error')
    }
    return 0
  }, [])
  return (
    <>
      <div className={`p-2 ${style.formContainer}`}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={updateRoleDetails}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            resetForm,
            handleSubmit,
            isSubmitting,
            dirty
          }) => {
            const handleActionValue = (fieldName: string, value: string) => {
              setFieldTouched(fieldName, true)
              setFieldValue(fieldName, value)
            }
            const handleCancel = () => {
              resetForm()
            }
            return (
              <>
                {isLoading && <OverlayLoader />}
                <form onSubmit={handleSubmit}>
                  <div className={style.selectContainer}>
                    <span className={style.action}>Role Name</span>
                    <div className={style.inputContainer}>
                      <input
                        className={
                          touched.role_name && errors.role_name
                            ? style.error
                            : ''
                        }
                        name='role_name'
                        placeholder='Enter Role Name'
                        value={values.role_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.role_name && touched.role_name && (
                        <p className={style.errorText}>{errors.role_name}</p>
                      )}
                      {formatedRole && (
                        <p className={style.formatRoleText}>
                          {formatedRole + ' :: ' + values.role_name}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={style.selectContainer}>
                    <span className={style.action}>Parent Role Name</span>

                    <Select
                      style={{
                        ...manageSelectStyle,
                        ...(touched.parent_role_id &&
                          errors.parent_role_id &&
                          errorStyle)
                      }}
                      value={{
                        ...dropdownData.find(
                          (item: any) => item.value === values.parent_role_id
                        )
                      }}
                      onChange={(value: any) =>
                        handleActionValue('parent_role_id', value.value)
                      }
                      options={dropdownData}
                    />
                    {errors.parent_role_id && touched.parent_role_id && (
                      <p className={style.errorText}>{errors.parent_role_id}</p>
                    )}
                  </div>

                  <div className={style.selectContainer}>
                    <span className={style.action}>Approver Role Name</span>

                    <Select
                      style={{
                        ...manageSelectStyle,
                        ...(touched.approver_group_name_id &&
                          errors.approver_group_name_id &&
                          errorStyle)
                      }}
                      value={{
                        ...dropdownData.find(
                          (item: any) =>
                            item.value === values.approver_group_name_id
                        )
                      }}
                      onChange={(value: any) =>
                        handleActionValue('approver_group_name_id', value.value)
                      }
                      options={dropdownData}
                    />
                    {errors.approver_group_name_id &&
                      touched.approver_group_name_id && (
                        <p className={style.errorText}>
                          {errors.approver_group_name_id}
                        </p>
                      )}
                  </div>
                  <div className={style.selectContainer}>
                    <span className={style.action}>Description</span>
                    <div className={style.inputContainer}>
                      <textarea
                        className={
                          touched.description && errors.description
                            ? style.error
                            : ''
                        }
                        name='description'
                        rows={5}
                        placeholder='Enter Description'
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description && (
                        <p className={style.errorText}>{errors.description}</p>
                      )}
                    </div>
                  </div>

                  <Row className='mb-2'>
                    <Col className='d-flex gap-2'>
                      <Button
                        type='submit'
                        color='edit'
                        className='btn-sm'
                        disabled={!dirty}
                      >
                        <i className='fal fa-edit me-2' />
                        Update
                        {isSubmitting && <Spinner className={style.spinner} />}
                      </Button>
                      <Button
                        type='button'
                        color='cancel'
                        className='btn-sm'
                        disabled={!dirty}
                        onClick={handleCancel}
                      >
                        <i className='fa fa-times me-2' />
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default EditRole
