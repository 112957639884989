import React, { useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { SearchDropdown } from '@digitalworkflow/dwreactcommon'
import Configurations from './Configurations'

const SearchDropdownPage = () => {
  const [values, setValues] = useState<(string | number)[]>([455444, 455448])
  const [value1, setValue1] = useState<(string | number)[]>([])
  const [customFilter, setCustomFilter] = useState<string>('')
  const [clientIds, setClientIds] = useState<string>('')
  const [tableName, setTableName] = useState<string>('labour_warrantor')
  const [returnFields, setReturnFields] = useState<string>('')

  const onConfigChange = (type: string, val: any = null) => {
    switch (type) {
      case 'tableName':
        setTableName(val)
        break
      case 'clientIds':
        setClientIds(val)
        break

      case 'returnFields':
        setReturnFields(val)
        break
      case 'customFilter':
        setCustomFilter(val)
        break
      default:
        break
    }
  }
  const onConfigApply = () => {
    console.log('config apply')
  }
  const onConfigCancel = () => {
    console.log('config cancel')
  }

  const onChangeValue = (items: string[]) => {
    setValues(items)
  }
  const onChangeValue1 = (items: string[]) => {
    setValue1(items)
  }

  const customComponent = (data: any) => {
    return (
      <div className=' d-flex align-items-start flex-column p-1'>
        <label className='mb-0'>{data.VALUE}</label>
        <label className='mb-0'>{data.DETAIL}</label>
      </div>
    )
  }

  return (
    <Row>
      <Col sm={12} lg={6}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle>
                  <h6>DW Search Multi Selection Dropdown</h6>
                </CardTitle>

                <SearchDropdown
                  key={`${tableName}-${customFilter}-${clientIds}-${returnFields}`}
                  formatOptionLabel={customComponent}
                  optionValueField='ID'
                  onChangeValue={onChangeValue}
                  tableName={tableName}
                  clientIds={clientIds}
                  customFilter={customFilter}
                  returnFields={returnFields}
                  isMulti
                  values={values}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle>
                  <h6>DW Search Single Selection Dropdown</h6>
                </CardTitle>

                <SearchDropdown
                  key={`${tableName}-${customFilter}-${clientIds}-${returnFields}`}
                  formatOptionLabel={customComponent}
                  optionValueField='ID'
                  onChangeValue={onChangeValue1}
                  tableName={tableName}
                  clientIds={clientIds}
                  customFilter={customFilter}
                  returnFields={returnFields}
                  values={value1}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col lg={6}>
        <Card>
          <CardBody>
            <CardTitle>
              <h4>Search Tool Configurations</h4>
            </CardTitle>
            <Configurations
              tableName={tableName}
              clientIds={clientIds}
              returnFields={returnFields}
              customFilter={customFilter}
              onChange={onConfigChange}
              onApply={onConfigApply}
              onCancel={onConfigCancel}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default SearchDropdownPage
