import React from 'react'
import { AdminPortalLayout } from '@digitalworkflow/dwreactcommon'
// import { useNotif } from '../context/NotifContext'

interface IAdminPortalLayoutProp {
  menuItems: any[]
  profileMenuItems: any[]
  logoImage: string
  logoSmImage: string
  username: string
  showWorldClock: boolean
  versionNumber: string
  env: string
}

const AdminPortalLayoutWrapper: React.FC<IAdminPortalLayoutProp> = ({
  menuItems,
  profileMenuItems,
  logoImage,
  logoSmImage,
  username,
  showWorldClock,
  versionNumber,
  env
}) => {
  // const notif = useNotif()

  // const handleNotification = () => {
  //   window.location.href = '/hub/access'
  // }

  return React.createElement(AdminPortalLayout, {
    menuItems,
    profileMenuItems,
    logoImage,
    logoSmImage,
    username,
    showWorldClock,
    versionNumber,
    env
    // ...(notif.notifCount && {
    //   showNotification: true,
    //   notifCount: notif.notifCount,
    //   handleNotification: handleNotification
    // })
  })
}

export default AdminPortalLayoutWrapper
