export class LocalSettings {
  static readonly portalData = 'portalData'
  static readonly loginPortalData = 'loginPortalData'
  static readonly dwhub_auth_token = 'dwhub_auth_token'
  static readonly dwhub_template_ = 'dwhub_template_'
  static readonly dwhub_user = 'dwhub_user'
  static readonly multi_auth_token = 'multi_auth_token'
  static readonly dwtheme = 'dwtheme'
  static readonly dwhub_redirect_url = 'dwhub_redirect_url'
  static readonly project_id = 'project_id'

  static getItem(key: string) {
    return localStorage.getItem(key)
  }

  static setItem(key: string, value: string) {
    localStorage.setItem(key, value)
  }

  static removeItem(key: string) {
    localStorage.removeItem(key)
  }

  static getLoginPortalData() {
    return this.getItem(this.loginPortalData)
  }

  static setPortalData(value: string) {
    this.setItem(this.portalData, value)
  }

  static setLoginPortalData(value: string) {
    this.setItem(this.loginPortalData, value)
  }

  static getRedirectUrl() {
    return this.getItem(this.dwhub_redirect_url)
  }

  static setRedirectUrl(value: string) {
    this.setItem(this.dwhub_redirect_url, value)
  }

  static getAuthToken() {
    return this.getItem(this.dwhub_auth_token)
  }

  static setAuthToken(value: string) {
    this.setItem(this.dwhub_auth_token, value)
  }

  static removeAuthToken() {
    this.removeItem(this.dwhub_auth_token)
  }

  static getHubTemplate(id: string) {
    return this.getItem(`${this.dwhub_template_}${id}`)
  }

  static setHubTemplate(id: string, value: string) {
    this.setItem(`${this.dwhub_template_}${id}`, value)
  }

  static getUser() {
    return this.getItem(this.dwhub_user)
  }

  static setUser(value: string) {
    this.setItem(this.dwhub_user, value)
  }

  static removeUser() {
    this.removeItem(this.dwhub_user)
  }

  static getMultiAuthToken() {
    return this.getItem(this.multi_auth_token)
  }

  static setMultiAuthToken(value: string) {
    this.setItem(this.multi_auth_token, value)
  }

  static removeMultiAuthToken() {
    this.removeItem(this.multi_auth_token)
  }

  static getTheme() {
    return this.getItem(this.dwtheme)
  }

  static setTheme(value: string) {
    localStorage.setItem(this.dwtheme, value)
  }

  static setProjectId(value: string) {
    localStorage.setItem(this.project_id, value)
  }
}
