import { SelectDropdown } from '@digitalworkflow/dwreactcommon'
import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

const options = [
  {
    value: 'option1',
    label: 'Option 1'
  },
  {
    value: 'option2',
    label: 'Option 2'
  },
  {
    value: 'option3',
    label: 'Option 3'
  }
]

const SelectDropdownPage = () => {
  return (
    <Row>
      <Col sm={12} lg={6}>
        <Card>
          <CardBody>
            <CardTitle>
              <h6>Dropdown with Search</h6>
            </CardTitle>

            <SelectDropdown options={options} isSearchable />
          </CardBody>
        </Card>
      </Col>

      <Col sm={12} lg={6}>
        <Card>
          <CardBody>
            <CardTitle>
              <h6>Dropdown without Search</h6>
            </CardTitle>

            <SelectDropdown
              options={options}
              placeholder='Select search option'
              onChange={(value: any) => console.log('search', value)}
              isSearchable={false}
            />
          </CardBody>
        </Card>
      </Col>
      <Col sm={12} lg={6}>
        <Card>
          <CardBody>
            <CardTitle>
              <h6>Dropdown with multi-selection</h6>
            </CardTitle>

            <SelectDropdown
              options={options}
              placeholder='Select multiple options'
              onChange={(value: any) => console.log('search', value)}
              isMulti
              isSearchable={false}
            />
          </CardBody>
        </Card>
      </Col>
      <Col sm={12} lg={6}>
        <Card>
          <CardBody>
            <CardTitle>
              <h6>Dropdown with custom label</h6>
            </CardTitle>

            <SelectDropdown
              options={options}
              placeholder='Select multiple options'
              isSearchable={false}
              formatOptionLabel={(data: any) => {
                return (
                  <div className='d-flex gap-2 align-items-center'>
                    <i className='fa-solid fa-option'></i>
                    <span style={{ color: 'red' }}>{data.value}</span>
                  </div>
                )
              }}
              onChange={(value: any) => console.log('search', value)}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
export default SelectDropdownPage
