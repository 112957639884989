import React from 'react'
import { useField, FieldAttributes } from 'formik'
import { FormikFormControl } from '../components/Formik/FormikFormControl'

export interface WithFormikFieldProps {
  name: string
  onChange?: (value: any) => void
  onBlur?: (e?: any) => void
  value?: any
  fullValueOnChange?: boolean
  updateOnBlur?: boolean
  placeholder?: string
  label?: string
  type?: string
  className?: string
}

export function withFormikField<P extends WithFormikFieldProps>(
  defaultName?: string
) {
  return function (Component: React.ComponentType<P>) {
    type WithFormikProps = Omit<P, keyof WithFormikFieldProps> &
      WithFormikFieldProps

    const ComponentWithFormik = React.forwardRef<unknown, WithFormikProps>(
      (props, ref) => {
        const name = (props as WithFormikFieldProps).name || defaultName || ''
        const [field] = useField({
          name
        } as FieldAttributes<any>)

        const componentProps = {
          ...props,
          name,
          value: field.value,
          onChange: field.onChange,
          onBlur: field.onBlur
        }

        return (
          <FormikFormControl name={name}>
            <Component {...(componentProps as unknown as P)} ref={ref} />
          </FormikFormControl>
        )
      }
    )

    ComponentWithFormik.displayName = `WithFormikField(${
      Component.displayName || Component.name || 'Component'
    })`

    return ComponentWithFormik as unknown as React.ForwardRefExoticComponent<
      P & React.RefAttributes<unknown>
    >
  }
}
