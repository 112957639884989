import React from 'react'
import BlockedEmailReportsList from '../../components/EmailReportsList/BlockedEmailReportsList'

const BlockedEmailReports = () => {
  return (
    <div className='p-2'>
      <div className='d-flex justify-content-between align-items-center'>
        <h6>Blocked Emails</h6>
      </div>

      <BlockedEmailReportsList />
    </div>
  )
}
export default BlockedEmailReports
