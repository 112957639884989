import React, { useEffect } from 'react'
import { portalName } from '../../constants'

const ClearStorage = () => {
  useEffect(() => {
    localStorage.clear()
    window.location.href = `/login/${portalName}`
  }, [])
  return <></>
}

export default ClearStorage
