import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { RCDockLayout, AgGrid } from '@digitalworkflow/dwreactcommon'

export const colDef = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    minWidth: 140,
    floatingFilter: true
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    unSortIcon: true,
    minWidth: 145,
    filter: 'agTextColumnFilter',
    floatingFilter: true
  },
  {
    field: 'age',
    headerName: 'Age',
    sortable: true,
    unSortIcon: true,
    minWidth: 145,
    filter: 'agTextColumnFilter',
    type: 'rightAligned',
    floatingFilter: true
  }
]

export const gridData = [
  { name: 'John', email: 'john@gmail.com', age: 30 },
  { name: 'John1', email: 'john@gmail.com', age: 30 }
]

/**
 * RcDockLayout Page component.
 *
 * @remarks
 * page where we showcase all RC Dock Layout
 *
 * @component RcDockLayoutPage
 * @category Page
 */
const RcDockLayoutPage = () => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>RCDock Layout</h4>
              </CardTitle>
              <div style={{ height: 400 }}>
                <RCDockLayout
                  defaultLayout={{
                    dockbox: {
                      mode: 'horizontal',
                      children: [
                        {
                          tabs: [
                            {
                              id: '1',
                              title: 'Grid 1',
                              content: (
                                <div style={{ height: '300px' }}>
                                  <AgGrid
                                    rowData={gridData}
                                    columnDefs={colDef}
                                  />
                                </div>
                              )
                            },
                            {
                              id: '2',
                              title: 'Grid 2',
                              content: (
                                <div style={{ height: '400px' }}>
                                  <h3>Grid 2</h3>
                                </div>
                              )
                            }
                          ]
                        }
                      ]
                    }
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default RcDockLayoutPage
