/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Spinner, Button, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  AuthService,
  BulkFactoryType,
  BulkService,
  PortalService,
  RoleService,
  WorkgroupService,
  WorkgroupType
} from '@digitalworkflow/dwloginclient'
import style from './addUser.module.scss'
import { sortArray } from '../../utils/sortArray'
import {
  DockLayout,
  SelectDropdown,
  TabData
} from '@digitalworkflow/dwreactcommon'
import { Actions, getActionDropdown } from '../../utils/bulkActions'
import { OptionProps } from 'react-select'

const authInstance = AuthService.instance()
const workgroupService = new WorkgroupService()
const portalService = new PortalService()
const roleService = new RoleService()
const bulkService = new BulkService()

WorkgroupService.setAuthServiceInstance(authInstance)
PortalService.setAuthServiceInstance(authInstance)
RoleService.setAuthServiceInstance(authInstance)
BulkService.setAuthServiceInstance(authInstance)

interface IFormValue {
  first_name: string
  last_name: string
  resource: string
  userList: string
  value: string
}

interface Props {
  dockLayoutRef: DockLayout
  id?: string | 'addUser'
  handleUpdateUser: () => void
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string().test(
    'first-name-required',
    'First Name is required',
    function (value) {
      const { last_name } = this.parent
      return !last_name || !!(value && value.trim())
    }
  ),

  last_name: Yup.string().test(
    'last-name-required',
    'Last Name is required',
    function (value) {
      const { first_name } = this.parent
      return !first_name || !!(value && value.trim())
    }
  ),
  resource: Yup.string()
    .required('Resource  is required')
    .min(1, 'Resource must not be empty'),
  value: Yup.string().when('resource', ([param], schema) => {
    return param !== Actions.None
      ? schema.required('Value is required')
      : schema.notRequired()
  }),

  userList: Yup.string()
    .trim()
    .lowercase()
    .required('Email is required')
    .matches(
      /^[A-Za-z0-9'._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
      'Enter a valid email address'
    )
})

const initialValues: IFormValue = {
  first_name: '',
  last_name: '',
  resource: '',
  userList: '',
  value: ''
}

const AddUser = ({ dockLayoutRef, id, handleUpdateUser }: Props) => {
  const [activeAction, setActiveAction] = useState<string | null>(null)
  const [activeActionType, setActiveActionType] = useState<string | null>(null)
  const [dropdownData, setDropdownData] = useState<OptionProps[]>([])
  const [isWorkgroupLoading, setIsWorkgroupLoading] = useState<boolean>(false)
  const [isRoleLoading, setIsRoleLoading] = useState<boolean>(false)
  const [isPortalLoading, setIsPortalLoading] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const actionDropDown = useMemo(() => {
    return getActionDropdown()
  }, [])

  useEffect(() => {
    const getAllWorkgroup = async () => {
      if (isWorkgroupLoading) {
        return
      }
      setIsWorkgroupLoading(true)
      const _workgroup = await workgroupService.getAllWorkgroup('restricted')
      if (!_workgroup.is_error && _workgroup.data) {
        let _dropdownData = sortArray(
          _workgroup?.data ?? [],
          'formatted_workgroup_name'
        )

        _dropdownData = _dropdownData?.map((item: WorkgroupType) => {
          return {
            value: item.id,
            label: item.formatted_workgroup_name
          }
        })
        if (activeAction?.includes('Workgroup')) {
          setDropdownData([...(_dropdownData as unknown as OptionProps[])])
        }
      }
      setIsWorkgroupLoading(false)
    }
    const getAllRoles = async () => {
      if (isRoleLoading) {
        return
      }
      setIsRoleLoading(true)
      const _roles = await roleService.getAllRole('restricted')
      if (!_roles.is_error) {
        let _dropdownData = sortArray(_roles?.data ?? [], 'formatted_role_name')

        _dropdownData = _dropdownData?.map((item) => {
          return {
            value: item.id,
            label: item.formatted_role_name
          }
        })
        if (activeAction?.includes('Role')) {
          setDropdownData([...(_dropdownData as unknown as OptionProps[])])
        }
      }
      setIsRoleLoading(false)
    }

    const getAllPortals = async () => {
      if (isPortalLoading) {
        return
      }
      setIsPortalLoading(true)
      const _portals = await portalService.getAllPortals('restricted')
      if (!_portals.is_error) {
        let _dropdownData = sortArray(_portals?.data ?? [], 'portal_name')

        _dropdownData = _dropdownData?.map((item) => {
          return {
            value: item.id,
            label: item.portal_name
          }
        })
        if (activeAction?.includes('Portal')) {
          setDropdownData([...(_dropdownData as unknown as OptionProps[])])
        }
      }
      setIsPortalLoading(false)
    }

    if (activeAction) {
      switch (activeAction) {
        case Actions.Assign_Workgroup:
        case Actions.Remove_Workgroup:
          getAllWorkgroup()
          break

        case Actions.Assign_Role:
        case Actions.Remove_Role:
          getAllRoles()
          break

        case Actions.Assign_Portal:
        case Actions.Remove_Portal:
          getAllPortals()
          break

        default:
          break
      }
    }
  }, [activeAction])

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  const handleBulkAction = useCallback(
    async (values: IFormValue, { resetForm }: any) => {
      const _values = {
        ...(values.first_name && { firstName: values.first_name }),
        ...(values.last_name && { lastName: values.last_name }),
        resource: values.resource.toLowerCase(),
        userList: [values.userList],
        action: activeActionType,
        value: values.value
      }
      const result: any = await bulkService.factory(_values as BulkFactoryType)
      if (!result.is_error) {
        if (result.data.userList[0].status === 'success') {
          toast.success(capitalizeFirstLetter(result.data.userList[0].message))
        } else if (result.data.userList[0].status === 'already exists') {
          toast.error(result.data.userList[0].message)
        } else if (result.data.userList[0].status === 'does not exist') {
          toast.error(result.data.userList[0].message)
        }
        // Remove floatBox on Success
        const dockRef = dockLayoutRef as any

        if (dockRef) {
          const tab = dockRef.find(id) as TabData
          dockRef.dockMove(tab, null, 'remove')
        }
        handleUpdateUser()
        resetForm()
        setActiveAction(null)
        setDropdownData([])
      } else {
        toast.error(result.message ?? 'Form Submission Error')
      }
      setActiveActionType(null)
    },
    [setDropdownData, activeAction]
  )
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema as any,
    enableReinitialize: true,
    onSubmit: handleBulkAction
  })

  const handleCancel = () => {
    formik.resetForm()
    setDropdownData([])
    setActiveActionType(null)
    setActiveAction(null)
  }

  const handleAction = (value: string) => {
    if (activeAction !== value) {
      formik.setFieldValue('value', '')
      setDropdownData({
        label: `Select ${activeAction}`,
        value: ''
      } as unknown as OptionProps[])
      setActiveAction(value)
      const action = value.split(' ')[0].toLowerCase()
      setActiveActionType(value === Actions.None ? 'assign' : action)
    }
    formik.setFieldTouched('resource', true)
    formik.setFieldValue(
      'resource',
      value === Actions.None ? Actions.None : value.split(' ')[1]
    )
  }

  const handleValue = (value: string) => {
    formik.setFieldTouched('value', true)
    formik.setFieldValue('value', value)
  }
  return (
    <div
      ref={containerRef}
      className={`overflow-auto  h-100 ${style.container}`}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className={style.selectContainer}>
          <span className={style.action}>
            First Name{formik.values.last_name ? '*' : ''}
          </span>
          <div className={style.inputContainer}>
            <input
              name='first_name'
              placeholder='Enter First Name'
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.first_name && formik.touched.first_name && (
              <p style={{ marginBottom: '0px' }} className={style.errorText}>
                {formik.errors.first_name}
              </p>
            )}
          </div>
        </div>
        <div className={style.selectContainer}>
          <span className={style.action}>
            Last Name{formik.values.first_name ? '*' : ''}
          </span>
          <div className={style.inputContainer}>
            <input
              name='last_name'
              placeholder='Enter Last Name'
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.last_name && formik.touched.last_name && (
              <p className={style.errorText}>{formik.errors.last_name}</p>
            )}
          </div>
        </div>
        <div className={style.selectContainer}>
          <span className={style.action}>Email*</span>
          <div className={style.inputContainer}>
            <input
              name='userList'
              placeholder='Enter Email'
              value={formik.values.userList}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.userList && formik.touched.userList && (
              <p style={{ marginBottom: '0px' }} className={style.errorText}>
                {formik.errors.userList}
              </p>
            )}
          </div>
        </div>
        <div className={style.selectContainer}>
          <span className={style.action}>Select Action*</span>
          <SelectDropdown
            options={actionDropDown}
            name='resource'
            placeholder='Select Action'
            onChange={(value: any) => handleAction(value.value)}
            value={
              formik.values.resource
                ? actionDropDown.find(
                    (item: any) =>
                      item.value.toLowerCase() ===
                      `${activeActionType} ${formik.values.resource}`.toLowerCase()
                  )
                : null
            }
          />
          {formik.errors.resource && formik.touched.resource && (
            <p className={style.errorText}>{formik.errors.resource}</p>
          )}
        </div>
        <div className={style.selectContainer}>
          <span className={style.action}>
            Select
            {formik.values.resource === '' ||
            formik.values.resource === Actions.None
              ? ' Value*'
              : ` ${formik.values.resource}*`}
          </span>
          <SelectDropdown
            options={dropdownData}
            isDisabled={
              formik.values.resource === Actions.None ||
              isWorkgroupLoading ||
              isRoleLoading
            }
            name='value'
            placeholder={`Select ${
              formik.values.resource === '' ||
              formik.values.resource === Actions.None
                ? 'Value'
                : formik.values.resource
            }`}
            onChange={(value: any) => handleValue(value.value)}
            isLoading={isWorkgroupLoading || isRoleLoading}
            value={
              Array.isArray(dropdownData) &&
              dropdownData.length > 0 &&
              formik.values.value
                ? dropdownData?.find(
                    (item: any) => item.value === formik.values.value
                  )
                : null
            }
            onMenuOpen={() => {
              if (containerRef.current) {
                // Delay the scrollTo call to ensure the dropdown is rendered
                setTimeout(() => {
                  if (containerRef.current) {
                    containerRef.current.scrollTo({
                      top: containerRef.current.scrollHeight,
                      behavior: 'smooth'
                    })
                  }
                }, 100)
              }
            }}
          />
          {formik.errors.value && formik.touched.value && (
            <p className={style.errorText}>{formik.errors.value}</p>
          )}
        </div>
        <span className={style.mandatoryText}>
          Fields marked with * are mandatory
        </span>
        <Row className='mb-2'>
          <Col className='d-flex gap-2'>
            <Button type='submit' color='delete' className='btn-sm'>
              <i className='fal fa-edit me-2' />
              Add
              {formik.isSubmitting && <Spinner className={style.spinner} />}
            </Button>
            <Button
              type='button'
              color='edit'
              className='btn-sm'
              onClick={handleCancel}
            >
              <i className='fa fa-times me-2' />
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  )
}

export default AddUser
