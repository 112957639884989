import React, { forwardRef, memo } from 'react'
import ToggleButton, { IToggleButton } from '../ToggleButton/ToggleButton'
import { useField } from 'formik'

const FormikToggleButton = forwardRef<HTMLInputElement, IToggleButton>(
  ({ name, ...props }, ref) => {
    const [field, _meta, _helpers] = useField(name)
    return (
      <ToggleButton
        ref={ref}
        {...props}
        checked={field.value}
        name={name}
        onChange={field.onChange}
        onBlur={field.onBlur}
      />
    )
  }
)
export default memo(FormikToggleButton)
