import React, { RefObject } from 'react'
import { IFactoryResponseUserListType } from './types'
import Badge from '../../components/Badge/Badge'
import LoginPortalGrid from '../../components/LoginPortalGrid/LoginPortalGrid'
import { UserType } from '@digitalworkflow/dwloginclient'
import { DockLayout } from '@digitalworkflow/dwreactcommon'
import { addFloatBox, getTab } from '../../lib/rcDock-lib'
import ManageProfile from '../../components/ManageProfile/ManageProfile'
import TabClose from '../../components/TabClose'
import placeholderImage from '../../assets/images/placeholder.png'

interface IFromTheList {
  userList: IFactoryResponseUserListType[]
  layoutRef: RefObject<DockLayout | null>
  userListRef: RefObject<any>
}

const FromTheList = ({ userList, layoutRef, userListRef }: IFromTheList) => {
  const handleUpdateUser = async (_profile: UserType | undefined) => {
    if (userListRef && userListRef.current) {
      await userListRef.current.getAllUsers()
    }
  }

  const getTabData = (profile: UserType) => {
    return {
      id: 'tabs' + profile.id,
      title: (
        <>
          <div className='user-tab-header'>
            <img src={placeholderImage} />
            <span>
              {`${
                profile?.first_name
                  ? `${profile.first_name} ${profile?.last_name ?? ''}`
                  : profile.email
              }`}
            </span>
          </div>
          <TabClose dockLayoutRef={layoutRef as any} id={'tabs' + profile.id} />
        </>
      ),
      content: (
        <ManageProfile
          id={profile.id ?? ''}
          handleUpdateUser={handleUpdateUser}
        />
      ),
      group: 'close-all'
    }
  }

  const handleFloatEdit = (profile: UserType) => {
    if (layoutRef.current?.find('tabs' + profile.id)) {
      return
    }
    if (layoutRef.current?.find('float_manage_profile')) {
      layoutRef.current.dockMove(
        getTabData(profile),
        'float_manage_profile',
        'middle'
      )
      return
    }
    addFloatBox(
      layoutRef,
      'float_manage_profile',
      getTab(
        'float_manage_profile',
        `<div class='user-tab-header'>
          <img src=${placeholderImage} />
          <span>
            ${
              profile?.first_name
                ? `${profile.first_name} ${profile?.last_name ?? ''}`
                : profile.email
            }
          </span>
        </div>`,
        layoutRef,
        () => (
          <ManageProfile id={profile.id ?? ''} handleUpdateUser={() => {}} />
        ),
        700,
        200,
        800,
        50,
        'tabs' + profile.id
      )
    )
  }

  const columnDefs = [
    {
      headerName: 'Name',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      flex: 2,
      floatingFilter: true,
      valueGetter: (params: any) =>
        params.data.first_name + ' ' + params.data.last_name,
      comparator: (a: any, b: any) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      flex: 3,
      floatingFilter: true,
      comparator: (a: any, b: any) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      flex: 1,
      floatingFilter: true,
      cellRenderer: (params: any) =>
        params?.data?.status ? (
          <Badge
            tooltipText={params.data?.message ?? ''}
            status={params?.data?.status ?? ''}
          />
        ) : (
          <></>
        )
    },
    {
      field: 'action',
      headerName: 'Action',
      resizable: true,
      flex: 1,
      cellRenderer: (params: any) => (
        <span
          className='action-text'
          onClick={() => handleFloatEdit(params.data)}
        >
          Manage Profile
        </span>
      )
    }
  ]
  return (
    <div style={{ height: '100%' }}>
      <LoginPortalGrid columnDefs={columnDefs} rowData={userList} />
    </div>
  )
}
export default FromTheList
