import React, { useEffect, useState } from 'react'

enum Filters {
  lessThan = 'Less Than',
  greaterThan = 'Greater Than',
  equals = 'Equals'
}

// eslint-disable-next-line
const DateHeader = (props: any) => {
  const { displayName, api, fieldName } = props
  const [filterLabel, setFilterLabel] = useState('')

  const updateFilterLabel = () => {
    const _label = api.getFilterModel()?.[fieldName]?.type
    setFilterLabel(Filters[_label as keyof typeof Filters])
  }

  useEffect(() => {
    updateFilterLabel()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    api.addEventListener('filterChanged', updateFilterLabel)
    return () => {
      api.removeEventListener('filterChanged', updateFilterLabel)
    }
    // eslint-disable-next-line
  }, [api.getFilterModel()?.[fieldName]?.type])

  return (
    <div>
      <div>{displayName} </div>
      <div className='text-xs font-semibold text-cta-blue'>
        {filterLabel ? `Filtered: ${filterLabel}` : ''}
      </div>
    </div>
  )
}

export default DateHeader
