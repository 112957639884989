import { SelectDropdown } from '@digitalworkflow/dwreactcommon'
import { useField } from 'formik'
import React, { useMemo } from 'react'

interface IFormikSelect {
  name: string
  options: any[]
  placeholder?: string
  label?: string
  isLoading?: boolean
  onChange?: (value: any) => void
  isDisabled?: boolean
}

const FormikSelect = ({
  name,
  options,
  placeholder,
  label,
  onChange,
  isLoading,
  isDisabled = false
}: IFormikSelect) => {
  const [field, _meta, _helpers] = useField(name)

  const value = useMemo(() => {
    let _value = null
    if (field.value) {
      _value = options.find((item) => item.value === field.value)
    }
    return _value
  }, [field.value])

  return (
    <div>
      <span className='form-label'>{label}</span>
      <SelectDropdown
        name='name'
        options={options}
        placeholder={placeholder}
        isDisabled={isDisabled}
        onChange={
          onChange ??
          ((value: any) => {
            _helpers.setTouched(true)
            _helpers.setValue(value.value)
          })
        }
        value={value}
        isLoading={isLoading}
      />
      {_meta.error && _meta.touched && (
        <p className='is-invalid'>{_meta.error}</p>
      )}
    </div>
  )
}
export default FormikSelect
