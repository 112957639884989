import React, { useEffect, useRef, useState } from 'react'
import { Button, Tooltip } from 'reactstrap'
import style from './imagePicker.module.scss'
import { useIsOverflow } from '../../hooks/userIsOverflow'
import { PSL } from '@digitalworkflow/psl_web'

interface IImagePicker {
  label?: string
  value: string
  image?: string
  className?: string
  resetFormImage?: boolean
  onChange?: (file: FileList) => void
}

const ImagePicker = ({
  label,
  className,
  value,
  onChange,
  resetFormImage,
  image
}: IImagePicker) => {
  const [apiBaseURL, setApiBaseURL] = React.useState('')

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const [tooltipOpen1, setTooltipOpen1] = useState<boolean>(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1)
  const fileInput = useRef<HTMLInputElement>(null)

  const [name, setName] = useState<string>('')
  const [selectedImage, setSelectedImage] = useState<string | null>(null)

  const parentRef = useRef(null)
  const childRef = useRef(null)

  const isOverflow = useIsOverflow(parentRef, childRef)

  useEffect(() => {
    const getApiBaseURL = async () => {
      let baseURL = (await PSL.GetSetting('host-login', '')) ?? ''
      baseURL = baseURL.replace('api/v1', '')
      setApiBaseURL(baseURL)
    }
    getApiBaseURL()
  }, [])
  useEffect(() => {
    if (resetFormImage) {
      setSelectedImage(null)
    }
  }, [resetFormImage])
  useEffect(() => {
    setName(value)
  }, [value])

  const selectFile = () => {
    if (fileInput && fileInput.current) {
      fileInput.current.value = ''
      fileInput.current.click()
    }
  }
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0]
      onChange && onChange(e.target?.files)

      // Read the selected image file and set it to the state
      const reader = new FileReader()
      reader.onload = (event) => {
        const imageDataUrl = event.target?.result as string
        setSelectedImage(imageDataUrl)
      }
      reader.readAsDataURL(selectedFile)
    }
  }

  return (
    <div className={'flex gap-3 align-items-center ' + className}>
      {label && (
        <span className='form-label' style={{ flexShrink: 0 }}>
          {label}
        </span>
      )}
      {(selectedImage || image) && (
        <img
          src={
            image
              ? selectedImage
                ? selectedImage ?? ''
                : `${apiBaseURL}api/v1/portals/logo/${image}`
              : selectedImage ?? ''
          }
          alt='Selected'
          height='50px'
          width='50px'
        />
      )}
      <div className='flex align-items-center'>
        <input
          type='file'
          accept='*/image'
          style={{ display: 'none' }}
          ref={fileInput}
          multiple={false}
          onChange={handleFileChange}
        />
        <div className='flex align-items-center gap-1'>
          <Button
            type='button'
            className='btn-sm'
            color='add'
            onClick={selectFile}
          >
            Upload
          </Button>
          <div
            className={`cursor-pointer ${style.infoTooltipIcon}`}
            id={image ? 'infoTooltip' + image.split('.')[0] : 'infoTooltip'}
          >
            <i className='fa-solid fa-circle-info '></i>
          </div>
          <div className={style.nameContainer} ref={parentRef}>
            <p
              className={`${style.fileName} truncate`}
              ref={childRef}
              id='imagePickerId'
            >
              {name}
            </p>
          </div>
        </div>
      </div>
      {isOverflow && (
        <Tooltip isOpen={tooltipOpen} target='imagePickerId' toggle={toggle}>
          {name}
        </Tooltip>
      )}
      <Tooltip
        isOpen={tooltipOpen1}
        target={image ? 'infoTooltip' + image.split('.')[0] : 'infoTooltip'}
        toggle={toggle1}
      >
        Supported file types are JPG, PNG, SVG, BMP, GIF only.
      </Tooltip>
    </div>
  )
}

export default ImagePicker
