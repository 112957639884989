import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import {
  MillerColumns,
  SelectedColumnProps,
  ColumnProps
} from '@digitalworkflow/dwreactcommon'

export const millerColumnData: ColumnProps[] = [
  {
    id: '1',
    title: 'Root',
    checked: false,
    isShow: false,
    items: [
      {
        id: '3',
        title: 'Folder A',
        checked: false,
        isShow: false,
        items: [
          {
            id: '9',
            title: 'Folder C',
            checked: false,
            isShow: false,
            items: []
          }
        ]
      },
      { id: '4', title: 'Folder B', checked: false, isShow: false, items: [] }
    ]
  },
  {
    id: '2',
    title: 'manager',
    checked: false,
    isShow: false,
    items: []
  }
]

/**
 * MillerColumnsPage Page component.
 *
 * @remarks
 * page where we showcase all miller columns
 *
 * @component MillerColumnsPage
 * @category Page
 */
const MillerColumnsPage = () => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>MillerColumns</h4>
              </CardTitle>
              <div className='d-flex flex-wrap gap-2'>
                <MillerColumns
                  columnData={millerColumnData}
                  getSelectedColoumn={(selectedColumn: SelectedColumnProps[]) =>
                    console.log('columns', selectedColumn)
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default MillerColumnsPage
