import React, { useState, useEffect, useCallback } from 'react'
import './CustomDateFilter.scss'

interface CustomDateFilterProps {
  maxValidDate: string
  api: any
  fieldName: string
  parentFilterInstance: (callback: (instance: any) => void) => void
}

const CustomDateFilter: React.FC<CustomDateFilterProps> = ({
  maxValidDate,
  api,
  fieldName,
  parentFilterInstance
}) => {
  const [date, setDate] = useState<string>('')

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value
    const adjustedDate =
      selectedDate > maxValidDate ? maxValidDate : selectedDate
    setDate(adjustedDate)
    parentFilterInstance((instance: any) => {
      instance.onFloatingFilterChanged('equals', new Date(adjustedDate))
    })
  }

  const updateFilterLabel = useCallback(() => {
    if (api && fieldName) {
      const filterModel = api.getFilterModel()?.[fieldName]
      if (filterModel && filterModel.dateFrom) {
        setDate(filterModel.dateFrom)
      } else {
        setDate('')
      }
    }
  }, [api, fieldName])

  useEffect(() => {
    updateFilterLabel()
  }, [updateFilterLabel])

  useEffect(() => {
    if (api && fieldName) {
      const handleFilterChanged = () => updateFilterLabel()
      api.addEventListener('filterChanged', handleFilterChanged)
      return () => {
        api.removeEventListener('filterChanged', handleFilterChanged)
      }
    } else {
      // Return a no-op function to ensure all code paths return a value
      return () => {}
    }
  }, [api, fieldName, updateFilterLabel])

  return (
    <div className={`custom-date-filter ${date ? 'has-value' : ''}`}>
      <input
        type='date'
        value={date}
        onChange={handleDateChange}
        max={maxValidDate}
        className='date-input'
      />
      <div className='placeholder'>dd----yyyy</div>
    </div>
  )
}

export default CustomDateFilter
