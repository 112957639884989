export const getSize = (oneLineSize: number, twoLineSize: number) => {
  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight
  const factor = 1000

  if (windowWidth >= oneLineSize) {
    return (20 / windowHeight) * factor
  }
  if (windowWidth >= twoLineSize) {
    return (28 / windowHeight) * factor
  }
  return (40 / windowHeight) * factor
}
