import { AgGrid, GridProps } from '@digitalworkflow/dwreactcommon'
import React, { RefObject } from 'react'
import { ColDef, FirstDataRenderedEvent } from 'ag-grid-community'

interface ILoginPortalGrid extends GridProps {
  columnDefs: ColDef[]
  rowData?: any[] | null
  defaulColDef?: ColDef
  gridRef?: RefObject<any>
  onCellValueChanged?: any
  onRowDataUpdated?: any
  onFilterChanged?: any
  onSortChanged?: any
  pagination?: boolean
  handleScroll?: () => void
  pageSize?: number
  overlayLoadingTemplate?: string
}

const LoginPortalGrid = ({
  rowData,
  columnDefs,
  defaulColDef,
  gridRef,
  onCellValueChanged,
  onFilterChanged = null,
  onSortChanged = null,
  onRowDataUpdated,
  pagination,
  handleScroll,
  pageSize = 50,
  overlayLoadingTemplate,
  rowModelType,
  ...rest
}: ILoginPortalGrid) => {
  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    params.api.sizeColumnsToFit()
  }
  return (
    <AgGrid
      gridRef={gridRef}
      rowData={rowData}
      defaultColDef={defaulColDef}
      rowModelType={rowModelType}
      columnDefs={columnDefs}
      overlayLoadingTemplate={overlayLoadingTemplate ?? 'Loading...'}
      overlayNoRowsTemplate='No data found'
      onFirstDataRendered={onFirstDataRendered}
      onCellValueChanged={onCellValueChanged}
      onRowDataUpdated={onRowDataUpdated}
      onSortChanged={onSortChanged}
      onFilterChanged={(e) => {
        if (onFilterChanged) {
          onFilterChanged(e)
        }
        const filteredRowCount = e.api.getDisplayedRowCount()
        if (filteredRowCount === 0) {
          e.api.showNoRowsOverlay()
        } else {
          e.api.hideOverlay()
        }
      }}
      onBodyScrollEnd={(e) => {
        const rowCount = e.api.getDisplayedRowCount()
        const rowHeight = e.api.getDisplayedRowAtIndex(0)?.rowHeight ?? 0
        const currentBottom = e.api.getVerticalPixelRange().bottom

        if (
          rowCount >= pageSize &&
          currentBottom + 300 > rowCount * rowHeight
        ) {
          handleScroll && handleScroll()
        }
      }}
      pagination={pagination ?? true}
      {...rest}
    />
  )
}

export default LoginPortalGrid
