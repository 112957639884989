import React from 'react'
import { Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap'

/**
 * Buttons Page component.
 *
 * @remarks
 * page where we showcase all buttons
 *
 * @component Buttons
 * @category Page
 */
const Buttons = () => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>Default Buttons With Colors Applied</h4>
              </CardTitle>
              <div className='d-flex flex-wrap gap-2'>
                <Button color='primary' className='btn'>
                  Primary
                </Button>
                <Button color='secondary' className='btn'>
                  Secondary
                </Button>
                <Button color='success' className='btn'>
                  Success
                </Button>
                <Button color='info' className='btn'>
                  Info
                </Button>
                <Button color='warning' className='btn'>
                  Warning
                </Button>
                <Button color='danger' className='btn'>
                  Danger
                </Button>
                <Button color='dark' className='btn'>
                  Dark
                </Button>
                <Button color='link' className='btn'>
                  Link
                </Button>
                <Button color='light' className='btn'>
                  Light
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>Project Specific Buttons With Colors Applied</h4>
              </CardTitle>
              <div className='d-flex flex-wrap gap-2'>
                <Button color='add' className='btn'>
                  Add
                </Button>
                <Button color='edit' className='btn'>
                  Edit
                </Button>
                <Button color='delete' className='btn'>
                  Delete
                </Button>
                <Button color='cancel' className='btn'>
                  Cancel
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>Outline buttons</h4>
              </CardTitle>
              <div className='d-flex flex-wrap gap-2'>
                <Button color='primary' outline>
                  Primary
                </Button>
                <Button color='secondary' outline>
                  Secondary
                </Button>
                <Button color='success' outline>
                  Success
                </Button>
                <Button color='info' outline>
                  Info
                </Button>
                <Button color='warning' outline>
                  Warning
                </Button>
                <Button color='danger' outline>
                  Danger
                </Button>
                <Button color='dark' outline>
                  Dark
                </Button>
                <Button color='light' outline>
                  Light
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>Buttons With Icons</h4>
              </CardTitle>
              <div className='d-flex flex-wrap gap-2'>
                <Button color='primary' className='btn'>
                  <i className='fas fa-smile me-2' />
                  Primary
                </Button>
                <Button color='success' className='btn'>
                  <i className='fas fa-check-double me-2' />
                  Success
                </Button>
                <Button color='warning' className='btn'>
                  <i className='fa-regular fa-triangle-exclamation me-2' />
                  Warning
                </Button>
                <Button color='danger' className='btn'>
                  <i className='fa-solid fa-ban me-2' />
                  Danger
                </Button>
                <Button color='dark' className='btn'>
                  <i className='fa-solid fa-spinner me-2' />
                  Loading
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Buttons
