import React, { useCallback, useState } from 'react'
import style from './manageUser.module.scss'
// import { SelectDropdown } from '@digitalworkflow/dwreactcommon'
import { AuthService, TemplateService } from '@digitalworkflow/dwloginclient'
import { Button } from 'reactstrap'
import Dropzone from 'react-dropzone'
import { exportData } from '../../utils/exportData'
// import { OptionProps } from 'react-select'

const authInstance = AuthService.instance()
// const templateService = new TemplateService()

TemplateService.setAuthServiceInstance(authInstance)
interface IUploadCSVData {
  handleSubmitFile: (file: File, templateValue: string) => void
  actionType: string
  resource: string
  value: string
}

const UploadCSVData = ({
  handleSubmitFile,
  actionType,
  resource,
  value
}: IUploadCSVData) => {
  const [file, setFile] = useState<File | null>(null)
  const [fileError, setFileError] = useState<boolean>(false)
  // const [isNotify, setIsNotify] = useState<boolean>(false)
  // const [templateData, setTemplateData] = useState<OptionProps[]>()
  const [templateValue] = useState<string>('')
  // const [isTemplateLoading, setIsTemplateLoading] = useState<boolean>(false)

  // useEffect(() => {
  //   const getAllTemplates = async () => {
  //     // setIsTemplateLoading(true)
  //     const _result = await templateService.getAllTemplates()
  //     // setIsTemplateLoading(false)
  //     if (!_result.is_error) {
  //       if (_result.data) {
  //         const data = _result.data
  //           ?.filter((item: any) => {
  //             return item.status === 'Publish'
  //           })
  //           .map((item: any) => {
  //             return {
  //               value: item.key,
  //               label: item.name
  //             }
  //           }) as unknown as OptionProps[]
  //         setTemplateData(data)
  //       }
  //     }
  //   }
  //   getAllTemplates()
  // }, [])
  const handleFile = useCallback(
    (fileInput: File[]) => {
      if (fileInput.length > 0) {
        const selectedFile = fileInput[0]
        setFile(selectedFile)
        const extension = selectedFile.name
          ? selectedFile.name.split('.').pop()?.toLowerCase()
          : ''
        if (extension !== 'csv') {
          setFileError(true)
          return
        }
        setFileError(false)
      }
    },
    [file]
  )
  const handleSubmit = useCallback(() => {
    if (!file) {
      setFileError(true)
      return
    }
    if (!actionType && resource !== 'none') {
      return
    }
    if (!fileError) {
      setFileError(false)
      handleSubmitFile(file, templateValue)
    }
  }, [setFileError, fileError, file, templateValue])

  return (
    <div className={`mt-4 ${style.uploadContainer}`}>
      <div className={`'d-flex gap-2 ${style.uplaodContent}`}>
        <span className={style.title}>Select Action: </span>
        <span className={style.value}>
          {' '}
          {resource === 'None' ? 'None' : actionType}{' '}
        </span>
      </div>
      {!actionType && (
        <p className={`is-invalid ${style.errorMessage}`}>
          Please select action from bulk action form
        </p>
      )}
      <>
        <div className={`d-flex gap-2 ${style.uplaodContent}`}>
          <span className={style.title}>
            Select {resource !== 'None' ? resource : 'Value'}:{' '}
          </span>
          <span className={style.value}>{value}</span>
        </div>
        {!value && resource !== 'None' && (
          <p className={`is-invalid ${style.errorMessage}`}>
            {' '}
            Please select value from bulk action form {value} {resource}
          </p>
        )}
      </>

      <div className='d-flex justify-content-center align-items-center mt-3 gap-3'>
        <span className={style.title}>Don't have the template?</span>
        <Button
          color='add'
          className='btn-sm'
          type='button'
          onClick={() => exportData('users/importTemplate', 'templates')}
        >
          Download Template
        </Button>
      </div>

      <Dropzone onDrop={(acceptedFiles) => handleFile(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className={style.dropzone}>
              <input {...getInputProps()} accept='.csv' />
              <i className={`fa-duotone fa-cloud-arrow-up ${style.icon}`}></i>
              <p>
                {file
                  ? file.name
                  : 'Drag file here or browse for file upload (Accepts CSV format only)'}
              </p>
            </div>
            {fileError &&
              (file ? (
                <p className='is-invalid'>File must be in CSV format.</p>
              ) : (
                <p className='is-invalid'>Please upload a file to proceed.</p>
              ))}
          </section>
        )}
      </Dropzone>
      {/* {getEnv() !== 'prod' && (
        <div className={`flex items-center gap-2 mt-3 ${style.checkbox}`}>
          <input
            name='notify'
            type='checkbox'
            checked={isNotify}
            onChange={() => setIsNotify((prev) => !prev)}
            disabled={actionType === 'remove' || resource === 'None'}
          />
          <span>Notify via email</span>
        </div>
      )} */}
      {/* {isNotify && (
        <div className={`mt-3 w-75 ${style.selectContainer}`}>
          <span className={style.action}>Select Email Template</span>
          <SelectDropdown
            name='template'
            options={templateData}
            placeholder='Select Email Template'
            isLoading={isTemplateLoading}
            value={
              Array.isArray(templateData) &&
              templateData.length > 0 &&
              templateValue
                ? templateData.find((item: any) => item.value === templateValue)
                : null
            }
            onChange={(value: any) => setTemplateValue(value.value)}
          />
        </div>
      )} */}

      <div className='d-flex justify-content-end mt-4'>
        <Button
          color='add'
          type='button'
          className='btn-sm'
          onClick={handleSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default UploadCSVData
