/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { DockLayout, RCDockLayout } from '@digitalworkflow/dwreactcommon'
import style from '../ManageProfile/ManageProfile.module.scss'
import {
  AuthService,
  PortalService,
  PortalType
} from '@digitalworkflow/dwloginclient'
import { Spinner } from 'reactstrap'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { RcGroup } from '../RcGroup'
import AddEditPortal from '../PortalsList/AddEditPortal'
import ComingSoon from '../../pages/ComingSoon'
import PortalMembers from './PortalMembers'

const authService = AuthService.instance()

PortalService.setAuthServiceInstance(authService)
const portalService = new PortalService()
interface IPortalDetails {
  id: string
  portal_name: string
  handleUpdatePortal: (portal: PortalType) => void
}

const PortalDetails = ({
  id,
  portal_name,
  handleUpdatePortal
}: IPortalDetails) => {
  const [portal, setPortal] = useState<PortalType | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const dockLayoutRef = useRef<DockLayout | null>(null)
  useEffect(() => {
    if (id) {
      getPortal()
    }
  }, [id, portal_name])

  useEffect(() => {
    if (dockLayoutRef && dockLayoutRef.current && portal) {
      dockLayoutRef.current.loadLayout(dockLayoutRef.current.getLayout())
    }
  }, [portal, dockLayoutRef])

  const getPortal = useCallback(async () => {
    setLoading(true)
    const _result: ApiResultType<PortalType> =
      await portalService.GetPortalById(id)
    setLoading(false)

    if (!_result.is_error) {
      setPortal(_result.data ?? null)
    }
  }, [portal, loading])

  const defaultLayout: any = {
    dockbox: {
      mode: 'horizontal',
      children: [
        {
          children: [
            {
              tabs: [
                {
                  id: 'portal_details',
                  title: 'Portal Details',
                  content: (
                    <AddEditPortal
                      portal={portal}
                      handleUpdatePortal={handleUpdatePortal}
                      getPortal={getPortal}
                    />
                  ),
                  group: 'close-all'
                },
                {
                  id: 'activity_log',
                  title: 'Activity Log',
                  content: <ComingSoon />,
                  group: 'close-all'
                },
                {
                  id: 'portal_members',
                  title: 'Portal Members',
                  content: (
                    <PortalMembers
                      portal_id={portal ? (portal.id as string) : ''}
                    />
                  ),
                  group: 'close-all'
                }
              ],
              group: 'close-all'
            }
          ]
        }
      ]
    }
  }

  return (
    <div className={`${style.container} ${style.bgStyle}`}>
      {loading && (
        <div className='d-flex justify-center'>
          <Spinner />
        </div>
      )}
      <div
        className={`d-flex justify-between flex-wrap gap-3 ${style.profileDetail}`}
      >
        <div className={`d-flex flex-wrap ${style.detail} gap-3`}>
          {/* <img src={placeholderImage} /> */}
          <div className='flex-1'>
            <h5>{portal?.portal_name}</h5>
            <div className='d-flex '>
              <div className='d-flex flex-wrap gap-3'>
                <div className='d-flex flex-col gap-1'>
                  <div className='d-flex gap-1 items-center'>
                    <i
                      className={`fa-light fa-arrow-right-to-bracket  ${style.icon}`}
                    ></i>
                    <span>Login Portal ID : {portal?.login_portal_id}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.layout}>
        <RCDockLayout
          defaultLayout={defaultLayout}
          dockLayoutRef={dockLayoutRef}
          groups={RcGroup}
        />
      </div>
    </div>
  )
}
export default PortalDetails
