import React from 'react'
import CronJobHistoryList from '../../components/CronJobHistoryList/CronJobHistoryList'

const CronJobHistory = () => {
  return (
    <div className='p-2'>
      <div className='d-flex justify-content-between align-items-center'>
        <h6>Cronjob History</h6>
      </div>

      <CronJobHistoryList />
    </div>
  )
}
export default CronJobHistory
