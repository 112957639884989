import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { JsonView } from 'react-json-view-lite'
import 'react-json-view-lite/dist/index.css'

const TableCellJson = ({ value }: { value: any }) => {
  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <div>
      <Button
        type='button'
        color='add'
        className='btn-sm'
        onClick={() => setOpen(true)}
      >
        Show Output
      </Button>
      <div>
        <Modal isOpen={isOpen} toggle={() => setOpen(false)}>
          <ModalHeader toggle={() => setOpen(false)}>Output</ModalHeader>
          <ModalBody>
            <JsonView data={value} />
          </ModalBody>
        </Modal>
      </div>
    </div>
  )
}

export default TableCellJson
