import React, { useEffect, useState } from 'react'
import { AuthService, AuthResultType } from '@digitalworkflow/dwloginclient'
import { portalName } from '../constants'
import OverlayLoader from '../components/OverlayLoader/overlayLoader'
import { LocalSettings } from '../utils/LocalSettings'

const authService = AuthService.instance()
AuthService.setPortalLoginId(portalName)

export interface IAuth {
  username: string
  email: string
  roles?: Array<any>
  firstName?: string
  lastName?: string
}

type IAuthContext = {
  isAuthenticated: boolean
  setIsAuthenticated: (val: boolean) => void
  setFeatureAccessSidebarUpdated: React.Dispatch<React.SetStateAction<number>>
  featureAccessSidebarUpdated: number
  user: IAuth
  setUser: (val: IAuth) => void
  logout: () => void
  defaultRoute: string
  setDefaultRoute: (val: string) => void
  loading: boolean // Add loading state to context
}

export const AuthContext: React.Context<IAuthContext> = React.createContext(
  {} as IAuthContext
)

export const AuthProvider = ({
  children
}: {
  children: React.ReactNode
}): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [featureAccessSidebarUpdated, setFeatureAccessSidebarUpdated] =
    useState<number>(0)
  const [user, setUser] = useState<IAuth>({
    username: '',
    email: '',
    roles: [],
    firstName: '',
    lastName: ''
  })
  const [defaultRoute, setDefaultRoute] = useState('')
  const [loading, setLoading] = useState(true) // Initialize loading state

  const logout = () => {
    LocalSettings.removeAuthToken()
    LocalSettings.removeUser()
    LocalSettings.removeMultiAuthToken()
    LocalSettings.removeItem(LocalSettings.dwhub_redirect_url)
    setIsAuthenticated(false)
  }

  useEffect(() => {
    const token = LocalSettings.getAuthToken()
    const initAuth = async () => {
      if (token) {
        try {
          const res: AuthResultType = await authService.authUserFromToken(token)
          if (res && res.is_error === false) {
            setIsAuthenticated(true)
            const userObj = JSON.parse(LocalSettings.getUser() || '{}')
            setUser({
              username: userObj.email,
              email: userObj.email,
              roles: userObj.roles,
              firstName: userObj.first_name,
              lastName: userObj.last_name
            })
          } else {
            logout() // Call logout to clear tokens and reset state
          }
        } catch (error) {
          console.error('Authentication error:', error)
          logout() // Ensure logout in case of error
        }
      } else {
        logout() // No token found, ensure logged out state
      }
      setLoading(false) // Set loading to false after auth check
    }

    initAuth()
  }, [])

  // Provide loading state in context
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        logout,
        defaultRoute,
        setDefaultRoute,
        setFeatureAccessSidebarUpdated,
        featureAccessSidebarUpdated,
        loading // Provide loading state to context consumers
      }}
    >
      {loading ? <OverlayLoader /> : children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): IAuthContext => React.useContext(AuthContext)
