/* eslint-disable */

export const manageSelectStyle = {
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    border: '1px solid hsl(0, 0%, 80%)',
    backgroundColor: '#FFFFFF',
    minHeight: 32,
    minWidth: 120
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    color: '#273E61',
    paddingLeft: 12,
    paddingRight: 16,
    paddingTop: 5,
    paddingBottom: 5,
    cursor: 'pointer',
    borderBottom: '1px solid #E5E7EB',
    backgroundColor: state.isSelected
      ? '#E5E7EB'
      : state.isFocused
      ? '#F9FAFB'
      : '#ffffff',
    '&:active': {
      backgroundColor: 'transparent'
    }
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: 12
  }),
  input: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  singleValue: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: 12,
    fontWeight: '500'
  }),
  valueContainer: (defaultStyle: any) => ({
    ...defaultStyle,
    maxHeight: '80px',
    overflowY: 'auto'
  }),
  multiValueLabel: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: 12,
    fontWeight: '500',
    textOverflow: 'unset',
    whiteSpace: 'unset'
  }),
  indicatorSeparator: (defaultStyle: any) => ({
    ...defaultStyle,
    backgroundColor: 'transparent'
  })
}

export const errorStyle = {
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    border: '1px solid red !important',
    backgroundColor: '#FFFFFF',
    minHeight: 32,
    minWidth: 120
  })
}

export const selectStylesForUserDetails = {
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0.25rem',
    border: '1px solid #ced4da',
    backgroundColor: '#fff',
    minHeight: 32,
    minWidth: 120,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 3.5,
    paddingBottom: 3.5
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '0.8125rem',
    color: '#495057',
    paddingLeft: 12,
    paddingRight: 16,
    paddingTop: 5,
    paddingBottom: 5,
    cursor: 'pointer',
    borderBottom: '1px solid #E5E7EB',
    backgroundColor: state.isSelected
      ? '#E5E7EB'
      : state.isFocused
      ? '#F9FAFB'
      : '#ffffff',
    '&:active': {
      backgroundColor: 'transparent'
    }
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: '0.8125rem'
  }),
  input: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: '0.8125rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  singleValue: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: '0.8125rem',
    fontWeight: '400'
  }),
  multiValue: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: '0.8125rem',
    fontWeight: '400'
  }),
  indicatorSeparator: (defaultStyle: any) => ({
    ...defaultStyle,
    backgroundColor: 'transparent'
  })
}
export const userSelectStyle = {
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    border: '1px solid hsl(0, 0%, 80%)',
    backgroundColor: '#FFFFFF',
    minHeight: 32,
    minWidth: 120,
    width: '100%'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    color: '#273E61',
    paddingLeft: 12,
    paddingRight: 16,
    paddingTop: 5,
    paddingBottom: 5,
    cursor: 'pointer',
    borderBottom: '1px solid #E5E7EB',
    backgroundColor: state.isSelected
      ? '#E5E7EB'
      : state.isFocused
      ? '#F9FAFB'
      : '#ffffff',
    '&:active': {
      backgroundColor: 'transparent'
    },
    wordWrap: 'break-word'
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 300
  })
}

export const claimMappingSelectStyle = {
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    border: '1px solid hsl(0, 0%, 80%)',
    backgroundColor: '#FFFFFF',
    minHeight: 32,
    minWidth: 120,
    width: 150
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    color: '#273E61',
    paddingLeft: 12,
    paddingRight: 16,
    paddingTop: 5,
    width: 150,
    paddingBottom: 5,
    cursor: 'pointer',
    borderBottom: '1px solid #E5E7EB',
    backgroundColor: state.isSelected
      ? '#E5E7EB'
      : state.isFocused
      ? '#F9FAFB'
      : '#ffffff',
    '&:active': {
      backgroundColor: 'transparent'
    },
    wordWrap: 'break-word'
  })
}
