import React, { forwardRef } from 'react'
import searchIcon from '../../assets/images/search.png'
import style from './SearchInput.module.scss'

interface ISearchInput {
  placeholder: string
  onChange?: (value: string) => void
}

const SearchInput = forwardRef<HTMLInputElement, ISearchInput>(
  ({ placeholder, onChange }, ref) => {
    return (
      <div className={style.searchContainer}>
        <div className={style.searchInput}>
          <img src={searchIcon} />
          <input
            ref={ref}
            placeholder={placeholder}
            onChange={(e) => onChange?.(e.target.value)}
          />
        </div>
      </div>
    )
  }
)

export default SearchInput
