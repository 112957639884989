import { useState, useLayoutEffect } from 'react'

export const useIsOverflow = (superRef: any, childRef: any) => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false)

  useLayoutEffect(() => {
    const trigger = () => {
      if (superRef.current && childRef.current) {
        const hasOverflow =
          superRef.current.scrollWidth < childRef.current.scrollWidth
        setIsOverflow(hasOverflow)
      }
    }

    if (superRef.current && childRef.current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(superRef.current)
        new ResizeObserver(trigger).observe(childRef.current)
      }
      trigger()
    }
  }, [superRef, childRef])

  return isOverflow
}
