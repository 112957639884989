/* eslint-disable camelcase */
import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import {
  AuthService,
  FeatureService,
  FeatureType,
  PermissionService,
  UserService,
  UserType
} from '@digitalworkflow/dwloginclient'
import ToggleButton from '../ToggleButton/ToggleButton'
import { Button, Spinner } from 'reactstrap'
import style from './ManageProfile.module.scss'
import '../../style/roleContianer.scss'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
// import { compareArrays } from '../../utils/compareArray'
import { sortComparator } from '../../utils/sortArray'
import { floatingButton } from '../../utils/floatingButton'
import { checkSuperAdmin } from '../../utils/checkAdmin'
import { LocalSettings } from '../../utils/LocalSettings'
import { useAuth } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'

interface Props {
  userInfo: UserType | null
  getUser: () => void
  userProfile?: boolean
  isReadOnlyAccess: boolean
  isSuperAdmin: boolean | null
}

enum AccessLevel {
  READ = 'read',
  WRITE = 'write'
}
interface UserPermission {
  feature_id: string
  permission: AccessLevel
}

const authService = AuthService.instance()
FeatureService.setAuthServiceInstance(authService)
PermissionService.setAuthServiceInstance(authService)
UserService.setAuthServiceInstance(authService)

const featureService = new FeatureService()
const permissionService = new PermissionService()
const userService = new UserService()

const adminAccessFeatures = [
  'Manage Cronjob History',
  'Manage Portals',
  'Manage Claims Mapping',
  // 'Manage Email Templates',
  'Manage System Messages'
]
const userFeatureList = ['Manage Users', 'Manage Roles', 'Manage Workgroups']

const FeatureAccess = ({
  userInfo,
  getUser,
  userProfile = false,
  isReadOnlyAccess,
  isSuperAdmin
}: Props) => {
  const { setFeatureAccessSidebarUpdated } = useAuth()
  const navigate = useNavigate()

  const [featureAccessList, setFeatureAccessList] = useState<FeatureType[]>([])
  const [userAccessFeature, setUserAccessFeature] = useState<string[]>([])
  const [userAccessPermisison, setUserAccessPermission] = useState<
    UserPermission[]
  >([])
  const [getDefault, setGetDefault] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const gridRef = useRef<any>(null)

  useEffect(() => {
    if (isSuperAdmin === null) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isSuperAdmin])

  useEffect(() => {
    getAllFeatures()
  }, [])

  useEffect(() => {
    const fetchPermissions = async () => {
      if (userInfo) {
        setUserAccessFeature([
          ...(userInfo?.features_enabled ?? ([] as string[]))
        ])
        const userAssignedPermissionDetails = await getAllPerrmissions(
          userInfo.id ?? ''
        )
        setUserAccessPermission([
          ...(userAssignedPermissionDetails?.map((permission) => ({
            feature_id: permission.feature_id,
            permission:
              permission.access_level === 'read'
                ? AccessLevel.READ
                : AccessLevel.WRITE
          })) || [])
        ])
      }
    }

    fetchPermissions()
  }, [
    userInfo?.features_enabled,
    userInfo?.permissions,
    setUserAccessPermission,
    getDefault
  ])

  useEffect(() => {
    if (userAccessFeature.length === featureAccessList.length) {
      floatingButton('floatingAccessToggleButton', true)
    } else {
      floatingButton('floatingAccessToggleButton', false)
    }
  }, [userAccessFeature, featureAccessList])

  // const isDisabled = useMemo(() => {
  //   const userData = JSON.parse(LocalSettings.getUser() ?? '')

  //   if (!userInfo || !userData || !Array.isArray(userData.permissions)) {
  //     return true
  //   }
  //   if (
  //     userData.permissions.length === 0 &&
  //     userAccessPermisison.length === 0
  //   ) {
  //     return true
  //   }
  //   const hasPermissionMismatch = userData.permissions.some(
  //     (permission: PermissionType) =>
  //       userAccessPermisison.some(
  //         (userPermission) =>
  //           permission.feature_id === userPermission.feature_id &&
  //           permission.access_level !== userPermission.permission
  //       )
  //   )

  //   const featuresMatch = compareArrays(
  //     userInfo?.features_enabled ?? [],
  //     userAccessFeature
  //   )
  //   return featuresMatch && !hasPermissionMismatch
  // }, [userInfo, userAccessFeature, userAccessPermisison])

  const isToggleButtonDisabled = useMemo(() => {
    return userProfile ? !checkSuperAdmin() : false
  }, [userProfile, checkSuperAdmin])

  const getAllFeatures = useCallback(async () => {
    setIsLoading(true)
    const result = await featureService.getAllFeatures()
    setIsLoading(false)
    const data = result.data?.sort((a, b) => {
      if (a.feature_name === 'Manage Users') {
        return -1
      } else if (b.feature_name === 'Manage Users') {
        return 1
      } else {
        return 0
      }
    })
    const userData = JSON.parse(LocalSettings.getUser() ?? '')
    const filterData =
      checkSuperAdmin() || userProfile
        ? data
        : data?.filter((item) =>
            userData.features_enabled.some(
              (feature: any) => feature.feature_name === item.feature_name
            )
          )
    userFeatureList.forEach((element) => {
      if (!filterData?.some((item) => item.feature_name === element)) {
        const featureFound = data?.find(
          (feature) => feature.feature_name === element
        )
        featureFound && filterData?.push(featureFound)
      }
    })
    setFeatureAccessList(filterData ?? [])
  }, [setFeatureAccessList, setIsLoading])

  const getAllPerrmissions = useCallback(
    async (userId: string) => {
      setIsLoading(true)
      const result = await permissionService.getAllPermissions()
      setIsLoading(false)
      const userAssignedPermissionDetails = result.data?.filter(
        (permission) => userId === permission.user_id
      )
      return userAssignedPermissionDetails ?? []
    },
    [setIsLoading, permissionService]
  )
  const handleBulkActionToggle = (value: boolean) => {
    if (gridRef && gridRef.current && gridRef.current.api) {
      const filteredRows = gridRef.current.api.getModel().rowsToDisplay
      const _allAccessFeaturesIds: string[] = filteredRows.map(
        (rowData: any) => rowData.data.id
      )
      if (value) {
        setUserAccessFeature(_allAccessFeaturesIds)
      } else {
        setUserAccessFeature([])
      }
    }
  }

  const handleToggleActive = useCallback(
    (data: any, isActive: boolean) => {
      if (
        userProfile &&
        !checkSuperAdmin() &&
        adminAccessFeatures.some((item) => item === data.feature_name)
      ) {
        toast.error('You are not allowed to modify this Feature Access')
        setUserAccessFeature((items) => [...items])
        return
      }
      if (isActive) {
        setUserAccessFeature((items) => [...items, data.id])
      } else {
        setUserAccessFeature((userFeature) =>
          userFeature.filter((r) => r !== data.id)
        )
      }
    },
    [userAccessFeature]
  )
  const handleAccess = useCallback(
    (data: { id: string }, isActive: boolean) => {
      setUserAccessPermission((items) => {
        const filteredItems = items.filter((r) => r.feature_id !== data.id)
        const newPermission: UserPermission = {
          feature_id: data.id,
          permission: isActive ? AccessLevel.READ : AccessLevel.WRITE
        }
        return [...filteredItems, newPermission]
      })
    },
    [userAccessPermisison]
  )
  const columnDefs = useMemo(() => {
    const columns: any = [
      {
        field: 'feature_name',
        headerName: 'Feature Name',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        flex: 3,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        comparator: sortComparator(null)
      },
      {
        field: 'id',
        headerName: 'Action',
        filter: 'agNumberColumnFilter',
        resizable: true,
        maxWidth: 200,
        width: 120,
        floatingFilter: !userProfile,
        ...(!userProfile && {
          floatingFilterComponent: ToggleButton,
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            disabled: isReadOnlyAccess,
            id: 'floatingAccessToggleButton',
            onChange: (e: any) => handleBulkActionToggle(e.target.checked)
          }
        }),
        cellRenderer: (params: any) => (
          <ToggleButton
            name='feature_access'
            isChecked={userAccessFeature.includes(params.data.id)}
            disabled={isToggleButtonDisabled || isReadOnlyAccess}
            onChange={(event: any) => {
              handleToggleActive(params.data, event.target.checked)
            }}
          />
        )
      }
    ]

    // Conditionally add the "Read Only" column if not a super admin
    if (!isSuperAdmin) {
      columns.push({
        field: 'id',
        headerName: 'Read Only',
        resizable: true,
        maxWidth: 200,
        width: 120,
        cellRenderer: (params: any) =>
          params.data.feature_name === 'Manage Users' && (
            <input
              disabled={
                isReadOnlyAccess || !userAccessFeature.includes(params.data.id)
              }
              name='access_checkbox'
              type='checkbox'
              checked={userAccessPermisison.some(
                (val) =>
                  val.feature_id === params.data.id && val.permission === 'read'
              )}
              onChange={(event: any) => {
                handleAccess(params.data, event.target.checked)
              }}
            />
          )
      })
    }

    return columns
  }, [userAccessFeature, userAccessPermisison, isReadOnlyAccess, isSuperAdmin])

  const handleUpdate = useCallback(async () => {
    if (userInfo?.id) {
      setIsSubmitting(true)
      try {
        const _result: ApiResultType<UserType> =
          await userService.updateUserById(userInfo?.id, {
            features_enabled: userAccessFeature,
            permissionDetails: userAccessPermisison
          })
        const userData = JSON.parse(LocalSettings.getUser() ?? '')
        if (userData.id === userInfo.id && checkSuperAdmin()) {
          userData.features_enabled = featureAccessList.filter((item) =>
            userAccessFeature.includes(item?.id ?? '')
          )
          userData.permissions = await getAllPerrmissions(userInfo.id ?? '')
          LocalSettings.setUser(JSON.stringify(userData))
          // update sidebar
          setFeatureAccessSidebarUpdated((prev) => ++prev)
          const manageUserId =
            featureAccessList.find(
              (item) => item.feature_name === 'Manage Users'
            )?.id ?? ''
          if (manageUserId) {
            if (!userAccessFeature.some((item) => item === manageUserId)) {
              navigate('/hub/profile')
            }
          }
        }
        if (!_result.is_error) {
          toast.success('Feature Access have been successfully updated.')
        } else {
          toast.error(_result.message ?? 'Feature Access Update Error')
        }
      } catch (e) {
        console.log(e)
        toast.error('Feature Access Update Error')
      }

      getUser()
      setIsSubmitting(false)
    }
  }, [userAccessFeature, featureAccessList, userAccessPermisison])

  return (
    <div className={style.innerContainerWithButtons}>
      {isLoading ? (
        <div className='flex justify-center'>
          <Spinner />
        </div>
      ) : (
        <div className={style.gridPortalContainer}>
          <div className='mb-2 mt-2 d-flex justify-content-end'>
            {!isToggleButtonDisabled && (
              <div className='d-flex gap-2'>
                <Button
                  type='submit'
                  color='edit'
                  className='btn-sm'
                  disabled={isReadOnlyAccess}
                  onClick={handleUpdate}
                >
                  <i className='fal fa-edit me-2' />
                  Update
                  {isSubmitting && <Spinner className={style.spinner} />}
                </Button>
                <Button
                  type='button'
                  color='cancel'
                  className='btn-sm'
                  disabled={isReadOnlyAccess}
                  onClick={() => {
                    setGetDefault(!getDefault)
                  }}
                >
                  <i className='fa fa-times me-2' />
                  Cancel
                </Button>
              </div>
            )}
          </div>
          <LoginPortalGrid
            columnDefs={columnDefs}
            gridRef={gridRef}
            rowData={featureAccessList}
            overlayLoadingTemplate='Processing...'
          />
        </div>
      )}
    </div>
  )
}
export default FeatureAccess
