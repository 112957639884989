import React, { useState, useEffect, useRef } from 'react'
import style from './AutoComplete.module.scss'
import { useField } from 'formik'

interface Props {
  options: string[]
  isLoading: boolean
  placeholder: string
  label: string
  name: string
  onChange(value: string): void
}

const AutoComplete = ({
  options,
  isLoading,
  placeholder,
  label,
  name,
  onChange
}: Props) => {
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [userFound, setUserFound] = useState(false)
  const autocompleteRef = useRef<HTMLDivElement>(null)
  const [_field, _meta, _helpers] = useField(name)

  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false)
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    if (_meta.error === undefined && _meta.value !== '') {
      _helpers.setError('Username not found in system.')
    }
  }, [_meta.error])

  useEffect(() => {
    if (options.length > 0 && _field.value !== '') {
      setShowSuggestions(true)
      setUserFound(true)
      // _helpers.setError(undefined) // Clear any previous error
    } else {
      setUserFound(false)
      _helpers.setError('Username not found in system.')
      _helpers.setValue(_meta.value)
    }
  }, [options])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    _helpers.setTouched(true)
    _helpers.setValue(inputValue)
    onChange(inputValue)
    if (inputValue.trim() !== '') {
      setShowSuggestions(true)
    } else {
      setUserFound(false)
      setShowSuggestions(false)
      _helpers.setValue(inputValue)
      _helpers.setError('Username not found in system.')
    }
  }

  const handleSuggestionClick = (suggestion: string) => {
    _helpers.setTouched(true)
    _helpers.setValue(suggestion)
    setShowSuggestions(false)
    setUserFound(true)
  }

  const handleFocus = () => {
    if (_field.value.trim() !== '') {
      setShowSuggestions(true)
    }
  }

  return (
    <>
      {label && (
        <label htmlFor={name} className={`${style.label}`}>
          {label}
        </label>
      )}

      <div className={style.autocomplete} ref={autocompleteRef}>
        <input
          value={_field.value}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          name={name}
        />
        {showSuggestions && (
          <ul className={style.suggestions}>
            {isLoading ? (
              <li>Loading...</li>
            ) : (
              options.map((option, index) => (
                <li onClick={() => handleSuggestionClick(option)} key={index}>
                  {option}
                </li>
              ))
            )}
          </ul>
        )}
      </div>
      {!isLoading && (
        <>
          {_meta.error && _meta.touched && !userFound && (
            <p className={`is-invalid ${style.errorMessage}`}>{_meta.error}</p>
          )}
          {_meta.touched && userFound && (
            <p className={style.successMessage}>User found in system.</p>
          )}
        </>
      )}
    </>
  )
}

export default AutoComplete
