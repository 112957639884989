import React from 'react'
import { FormSelection } from '@digitalworkflow/dwreactcommon'

const FormSelectionPage = () => {
  return (
    <div>
      <FormSelection
        data={[
          {
            url: 'https://source.unsplash.com/user/c_v_r/100x100',
            text: 'text1',
            value: 'value, centered value, next value'
          },
          {
            url: 'https://source.unsplash.com/user/c_v_r/100x100',
            text: 'text2',
            value: 'value, centered value, next value'
          },
          {
            url: 'https://source.unsplash.com/user/c_v_r/100x100',
            text: 'text3',
            value: 'value, centered value, next value'
          },
          {
            url: 'https://source.unsplash.com/user/c_v_r/100x100',
            text: 'text4',
            value: 'value, centered value, next value'
          },
          {
            url: 'https://source.unsplash.com/user/c_v_r/100x100',
            text: 'text5',
            value: 'value, centered value, next value'
          },
          {
            url: 'https://source.unsplash.com/user/c_v_r/100x100',
            text: 'text6',
            value: 'value, centered value, next value'
          },
          {
            url: 'https://source.unsplash.com/user/c_v_r/100x100',
            text: 'text7',
            value: 'value, centered value, next value'
          },
          {
            url: 'https://source.unsplash.com/user/c_v_r/100x100',
            text: 'text8',
            value: 'value, centered value, next value'
          },
          {
            url: 'https://source.unsplash.com/user/c_v_r/100x100',
            text: 'text9',
            value: 'value, centered value, next value'
          },
          {
            url: 'https://source.unsplash.com/user/c_v_r/100x100',
            text: 'text10',
            value: 'value, centered value, next value'
          },
          {
            url: 'https://source.unsplash.com/user/c_v_r/100x100',
            text: 'text11',
            value: 'value, centered value, next value'
          }
        ]}
      />
    </div>
  )
}

export default FormSelectionPage
