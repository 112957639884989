import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import {
  AuthResultType,
  AuthService,
  PortalType
} from '@digitalworkflow/dwloginclient'

// import './Login.scss'

import { toast } from 'react-toastify'
import FormikInput from '../Formik/FormikInput/formikInput'

const authService = AuthService.instance()

interface EmailLoginProps {
  portal: PortalType
  handleGoBackToList: () => void
}

/**
 * EmailLogin component.
 *
 * @remarks
 * component where users can log in via email/password
 *
 * @component EmailLogin
 * @category Component
 */
const MagicLogin = ({ portal, handleGoBackToList }: EmailLoginProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { id } = useParams()

  const handleValidSubmit = async (values: any) => {
    setIsSubmitting(true)
    try {
      const res: AuthResultType = await authService.authenticateByKey(
        values.magic_key
      )
      if (res) {
        // eslint-disable-next-line camelcase
        if (res?.is_error === false) {
          const enabledExist = res.data.user.portals_enabled.findIndex(
            (p: any) => p.login_portal_id === portal.login_portal_id
          )
          if (enabledExist === -1) {
            toast.error(
              'Sorry! you are not allowed to access this page. Request BGIS Workflow Admin to allow you an access.'
            )
          } else {
            toast.success('Login Success')
            setTimeout(() => {
              if (window.location.hostname?.includes('localhost')) {
                // Login Hub local development
                window.location.href =
                  '/hub/dashboard' + `?token=${res.data.authToken}`
              } else if (window.location.href.includes('return=local')) {
                // Portal/Builder local development
                window.location.href = `http://localhost:3000?token=${res.data.authToken}`
              } else {
                window.location.href =
                  portal.redirection_location_after_success +
                  `?token=${res.data.authToken}`
              }
            }, 2000)
          }
        } else {
          toast.error((res as any)?.message ?? 'Login Error')
        }
      }
    } catch (e) {
      console.log(e)
      toast.error('Login Error')
    }
    setIsSubmitting(false)
  }

  return (
    <>
      <div className='p-2 login-form'>
        <Formik
          enableReinitialize
          initialValues={{
            magic_key: ''
          }}
          validationSchema={Yup.object().shape({
            magic_key: Yup.string()
              .required('Enter Key')
              .matches(
                /^[a-zA-Z0-9]{12}$/,
                'Must be exactly 12 characters long and contain letters and numbers'
              )
          })}
          onSubmit={(values: any) => {
            handleValidSubmit(values)
          }}
        >
          {() => (
            <Form className='form-horizontal '>
              <div className='form-group'>
                <FormikInput
                  placeholder='Enter 12 digit key'
                  name='magic_key'
                  type='password'
                  label='Key'
                />
              </div>

              <div className='mt-3 d-grid'>
                <button
                  className='btn btn-login btn-block waves-effect waves-light themes-button'
                  type='submit'
                  disabled={isSubmitting}
                >
                  Log In
                  {isSubmitting && <Spinner className='spinner' />}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className='mt-4 text-center'>
          <Link
            to={`/forgot-password/${id}`}
            className='text-muted forgot-text'
          >
            <i className='fa-solid fa-lock-keyhole'></i> Forgot your password
          </Link>
        </div>
        <div className='mt-2 text-center'>
          <button
            onClick={handleGoBackToList}
            className='btn font-weight-medium text-darkblue back-btn'
          >
            Go back
          </button>
        </div>
      </div>
    </>
  )
}

export default MagicLogin
