/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Spinner, Button, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import Select from '../../components/select/select'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  AuthService,
  WorkgroupService,
  WorkgroupType
} from '@digitalworkflow/dwloginclient'
import {
  errorStyle,
  manageSelectStyle
} from '../../components/select/manageUser.styles'
import style from './EditRole.module.scss'
import { sortArray } from '../../utils/sortArray'
import OverlayLoader from '../../components/OverlayLoader/overlayLoader'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { IDropdown } from '../AddRole/AddWorkgroup'
import { DockLayout, TabData } from '@digitalworkflow/dwreactcommon'

const isNotEmpty = (value: string) => value.trim().length > 0
const isWithinMaxLength = (value: string, maxLength: number) =>
  value.length <= maxLength

const validationSchema = Yup.object().shape({
  work_group_name: Yup.string()
    .required('Role name is required')
    .test(
      'no-only-spaces',
      'Workgroup name cannot consist of only spaces',
      isNotEmpty
    )
    .test(
      'max-characters',
      'Workgroup name must be at most 100 characters',
      (value) => isWithinMaxLength(value, 100)
    )
    .test(
      'no-leading-or-trailing-spaces',
      'Workgroup name cannot have leading or trailing spaces',
      (value) => value.trim() === value
    ),
  approver_group_name: Yup.string(),
  parent_work_group: Yup.string(),
  description: Yup.string()
    .required('Description is required')
    .test(
      'no-only-spaces',
      'Description cannot consist of only spaces',
      isNotEmpty
    )
    .test(
      'max-characters',
      'Description must be at most 200 characters',
      (value) => isWithinMaxLength(value, 200)
    )
})
interface FormValue {
  work_group_name: string
  approver_group_name: string
  parent_work_group: string
  description: string
}

interface Props {
  activeWorkgroup: WorkgroupType | null
  handleNewWorkgroup: () => void
  dockLayoutRef: DockLayout
  id?: string | 'editWorkgroup'
}

const authInstance = AuthService.instance()
WorkgroupService.setAuthServiceInstance(authInstance)
const workgroupService = new WorkgroupService()

const EditWorkgroup = ({
  activeWorkgroup,
  handleNewWorkgroup,
  dockLayoutRef,
  id
}: Props) => {
  const [dropdownData, setDropdownData] = useState<IDropdown[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    getRoles()
  }, [])

  const handleFormattedRoleName = useCallback((role: WorkgroupType | null) => {
    if (role) {
      const extractedString = role?.formatted_workgroup_name?.substring(
        0,
        role?.formatted_workgroup_name.lastIndexOf('::')
      )
      return extractedString ?? ''
    }

    return ''
  }, [])

  const formatedWorkgroup = useMemo(() => {
    return handleFormattedRoleName(activeWorkgroup)
  }, [activeWorkgroup])

  const initialValues = useMemo((): FormValue => {
    return {
      work_group_name: activeWorkgroup?.work_group_name ?? '',
      parent_work_group: activeWorkgroup?.parent_work_group ?? '',
      approver_group_name: activeWorkgroup?.approver_group_name ?? '',
      description: activeWorkgroup?.description ?? ''
    }
  }, [activeWorkgroup])

  const getRoles = useCallback(async () => {
    setIsLoading(true)
    const roles: ApiResultType<WorkgroupType[]> =
      await workgroupService.getAllWorkgroup('restricted')
    if (!roles.is_error && roles.data) {
      const _roleDropdownData: WorkgroupType[] = sortArray(
        roles.data,
        'approver_group_name_id'
      )
      const _dropdownData = _roleDropdownData.map((item) => {
        return {
          label: item?.formatted_workgroup_name ?? '',
          value: item.id
        }
      })
      setDropdownData([...(_dropdownData as IDropdown[])])
    }
    setIsLoading(false)
  }, [setIsLoading, setDropdownData])

  const updateRoleDetails = useCallback(async (values: FormValue) => {
    // const id = 'editWorkgroup'
    try {
      const result = await workgroupService.updateWorkgroupById(
        activeWorkgroup?.id || '',
        values
      )
      if (result.is_error) {
        toast.error(result.message ?? 'Form Submission Error')
      } else {
        handleNewWorkgroup()
        toast.success('Workgroup information is updated successfully')

        // close the tab
        const dockRef = dockLayoutRef as any

        if (dockRef) {
          const tab = dockRef.find(id) as TabData
          dockRef.dockMove(tab, null, 'remove')
        }
      }
    } catch (e) {
      toast.error('Form Submission Error')
    }
    return 0
  }, [])
  return (
    <>
      <div className={`p-2 ${style.formContainer}`}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={updateRoleDetails}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            resetForm,
            handleSubmit,
            isSubmitting,
            dirty
          }) => {
            const handleActionValue = (fieldName: string, value: string) => {
              setFieldTouched(fieldName, true)
              setFieldValue(fieldName, value)
            }
            const handleCancel = () => {
              resetForm()
            }
            return (
              <>
                {isLoading && <OverlayLoader />}
                <form onSubmit={handleSubmit}>
                  <div className={style.selectContainer}>
                    <span className={style.action}>Workgroup Name</span>
                    <div className={style.inputContainer}>
                      <input
                        className={
                          touched.work_group_name && errors.work_group_name
                            ? style.error
                            : ''
                        }
                        name='work_group_name'
                        placeholder='Enter Workgroup Name'
                        value={values.work_group_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {formatedWorkgroup && (
                        <p className={style.formatRoleText}>
                          {formatedWorkgroup + ' :: ' + values.work_group_name}
                        </p>
                      )}
                      {errors.work_group_name && touched.work_group_name && (
                        <p className={style.errorText}>
                          {errors.work_group_name}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={style.selectContainer}>
                    <span className={style.action}>Parent Workgroup Name</span>

                    <Select
                      style={{
                        ...manageSelectStyle,
                        ...(touched.parent_work_group &&
                          errors.parent_work_group &&
                          errorStyle)
                      }}
                      value={{
                        ...dropdownData.find(
                          (item: any) => item.value === values.parent_work_group
                        )
                      }}
                      onChange={(value: any) =>
                        handleActionValue('parent_work_group', value.value)
                      }
                      options={dropdownData}
                    />
                    {errors.parent_work_group && touched.parent_work_group && (
                      <p className={style.errorText}>
                        {errors.parent_work_group}
                      </p>
                    )}
                  </div>

                  <div className={style.selectContainer}>
                    <span className={style.action}>
                      Approver Workgroup Name
                    </span>

                    <Select
                      style={{
                        ...manageSelectStyle,
                        ...(touched.approver_group_name &&
                          errors.approver_group_name &&
                          errorStyle)
                      }}
                      value={{
                        ...dropdownData.find(
                          (item: any) =>
                            item.value === values.approver_group_name
                        )
                      }}
                      onChange={(value: any) =>
                        handleActionValue('approver_group_name', value.value)
                      }
                      options={dropdownData}
                    />
                    {errors.approver_group_name &&
                      touched.approver_group_name && (
                        <p className={style.errorText}>
                          {errors.approver_group_name}
                        </p>
                      )}
                  </div>
                  <div className={style.selectContainer}>
                    <span className={style.action}>Description</span>
                    <div className={style.inputContainer}>
                      <textarea
                        className={
                          touched.description && errors.description
                            ? style.error
                            : ''
                        }
                        name='description'
                        rows={5}
                        placeholder='Enter Description'
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description && (
                        <p className={style.errorText}>{errors.description}</p>
                      )}
                    </div>
                  </div>

                  <Row className='mb-2'>
                    <Col className='d-flex gap-2'>
                      <Button
                        type='submit'
                        color='edit'
                        className='btn-sm'
                        disabled={!dirty}
                      >
                        <i className='fal fa-edit me-2' />
                        Update
                        {isSubmitting && <Spinner className={style.spinner} />}
                      </Button>
                      <Button
                        type='button'
                        color='cancel'
                        className='btn-sm'
                        disabled={!dirty}
                        onClick={handleCancel}
                      >
                        <i className='fa fa-times me-2' />
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default EditWorkgroup
