import React from 'react'
import { useField } from 'formik'
import cls from './formik-form-control.module.scss'

export interface FormikFormControlProps {
  name: string
  children?: React.ReactNode
  touched?: boolean
  showErrorMessage?: boolean
  disableHighlight?: boolean
  errorMessage?: string
  required?: boolean
  className?: string
  errorClass?: string
}

export const FormikFormControl = ({
  name,
  children = '',
  touched = false,
  showErrorMessage = true,
  disableHighlight = false,
  errorMessage = '',
  className = '',
  errorClass
}: FormikFormControlProps) => {
  const [_field, meta, _helpers] = useField(name as string)
  const isTouched = touched || meta.touched
  const isError = isTouched && meta.error
  return (
    <div>
      <div
        className={`${!disableHighlight && isError ? cls.highlight : ''} 
        ${className}`}
      >
        {children}
      </div>
      {showErrorMessage && isError && (
        <p className={`is-invalid ${errorClass}`}>
          {errorMessage || meta.error}
        </p>
      )}
    </div>
  )
}
