import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Card, CardBody, Col, Container, Label, Row, Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import {
  AuthService,
  AuthPageResponseType,
  PortalType
} from '@digitalworkflow/dwloginclient'

import { ApiResultType } from '@digitalworkflow/dwloginclient/src/Auth/ApiResultType'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import '../../assets/scss/login.scss'
import bgisLogo from '../../assets/images/bgis-logo.png'
import vector2 from '../../assets/images/vector2.png'
import { LocalSettings } from '../../utils/LocalSettings'
import { PSL } from '@digitalworkflow/psl_web'

const authService = AuthService.instance()

/**
 * pages/ForgotPassword component.
 *
 * @remarks
 * Page where users can log in
 *
 * @component ForgotPassword
 * @category Page
 */
const ForgotPassword = () => {
  const [username, setUsername] = useState<string>('')
  const [isSubmitting, SetIsSubmitting] = useState<boolean>(false)
  const [isNextStep, setIsNextStep] = useState<boolean>(false)
  const [apiBaseURL, setApiBaseURL] = React.useState('')
  const { id } = useParams()
  const navigate = useNavigate()
  const dwTheme = LocalSettings.getTheme()

  const [portal, setPortal] = useState<PortalType>({
    buttons: {
      login_with_SSO: false,
      login_with_office365: false,
      login_with_email: true,
      register: true,
      login_with_magic_key: false,
      sso: {}
    },
    login_portal_id: '',
    portal_logo: '',
    portal_logo_full: '',
    portal_name: '',
    portal_theme: '',
    redirection_location_after_success: '/hub/dashboard',
    action_buttons: [],
    client_id: '',
    client_secret: ''
  })

  async function handleValidSubmit(values: Record<string, any>) {
    SetIsSubmitting(true)
    let res1: ApiResultType<any>
    let res2: ApiResultType<any>
    if (!isNextStep) {
      res1 = await authService.authResetPasswordRequest(values.username)
      setUsername(values.username)
      if (res1?.is_error === false) {
        setIsNextStep(true)
        toast.success('A verification code has been sent to the email.')
      } else {
        toast.error(res1?.message ?? 'Username or email is not correct')
      }
    } else {
      if (values.password !== values.password_confirmation) {
        toast.error('Confirm Password does not match')
        SetIsSubmitting(false)
        return
      }
      res2 = await authService.authResetPasswordWithCode(
        username,
        values.verificationCode,
        values.password
      )
      // eslint-disable-next-line camelcase
      if (res2?.is_error === false) {
        toast.success('The password has been reset.')
        setTimeout(() => {
          navigate(`/login/${id}`)
        }, 1000)
      } else {
        toast.error(
          res2.message ?? 'There was something wrong with the reset process'
        )
      }
    }
    SetIsSubmitting(false)
  }

  const gotoPrevStep = () => {
    setUsername('')
    setIsNextStep(false)
  }

  useEffect(() => {
    const getApiBaseURL = async () => {
      let baseURL = (await PSL.GetSetting('host-login', '')) || ''
      baseURL = baseURL.replace('api/v1', '')
      setApiBaseURL(baseURL)
    }
    getApiBaseURL()
  }, [])
  useEffect(() => {
    if (id) {
      getPortalDetails(id)
    }
  }, [id])

  const getPortalDetails = async (id: string) => {
    /** Get Project specific login page information : logo, redirection url, buttons */
    AuthService.setPortalLoginId(id)
    try {
      const res: AuthPageResponseType | undefined =
        await authService.getLoginPage(id)
      if (res && res?.data) {
        const portalData = {
          ...portal,
          buttons: res.data?.project?.data?.buttons,
          // eslint-disable-next-line camelcase
          login_portal_id: res.data?.project?.data?.login_portal_id,
          // eslint-disable-next-line camelcase
          portal_logo: res.data?.project?.data?.portal_logo,
          // eslint-disable-next-line camelcase
          portal_logo_full: res.data?.project?.data?.portal_logo_full,
          // eslint-disable-next-line camelcase
          portal_name: res.data?.project?.data.portal_name,
          portal_theme: res.data?.project?.data.portal_theme,
          redirection_location_after_success:
            // eslint-disable-next-line camelcase
            res.data?.project?.data?.redirection_location_after_success
        }
        // console.log(res, project)
        setPortal(portalData)
        LocalSettings.setTheme(portalData?.portal_theme ?? '')
        console.log(portalData)
      } else {
        toast.error(
          'Something has gone wrong, our software developers have been notified. '
        )
        console.log(
          'Something has gone wrong, our software developers have been notified. '
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  const projectTheme = useMemo(() => {
    return portal.portal_theme ?? dwTheme
  }, [dwTheme, portal])

  return (
    <div className={`theme-${projectTheme}`}>
      <Helmet>
        {portal.portal_name !== '' && <title>{portal.portal_name}</title>}
      </Helmet>
      <div className='login-page pt-sm-3'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5} className='login-section'>
              <Card className='overflow-hidden theme-card'>
                <div className='login-header'>
                  <div className='title'>
                    <img
                      src={
                        portal
                          ? `${apiBaseURL + portal.portal_logo_full}`
                          : bgisLogo
                      }
                      alt=''
                      className='img-fluid'
                    />
                  </div>
                  <img src={vector2} alt='' className='img-fluid vector-img' />
                </div>
                <CardBody className='pt-4 theme-card-body'>
                  <div className='p-2'>
                    {!isNextStep ? (
                      <Formik
                        enableReinitialize
                        initialValues={{
                          username: ''
                        }}
                        validationSchema={Yup.object().shape({
                          username: Yup.string().required(
                            'Enter Username/Email'
                          )
                        })}
                        onSubmit={(values) => {
                          handleValidSubmit(values)
                        }}
                      >
                        {({ errors, touched }) => (
                          <Form className='form-horizontal login-form'>
                            <h3 className='text-center mb-5 project-name'>
                              Forgot Password?
                            </h3>
                            <div className='mb-3 title-description'>
                              Enter your username/email below and we will send
                              you password reset link
                            </div>
                            <div className='form-group'>
                              <Label for='username' className='form-label'>
                                Username/Email
                              </Label>
                              <Field
                                name='username'
                                id='username'
                                type='input'
                                placeholder='Enter Username/Email'
                                className={
                                  'form-control' +
                                  (errors.username && touched.username
                                    ? ' is-invalid'
                                    : '')
                                }
                              />
                              <ErrorMessage
                                name='username'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>

                            <Row className='form-group'>
                              <Col className='text-right d-grid'>
                                <button
                                  className='btn btn-login w-md waves-effect waves-light save-btn theme-button'
                                  type='submit'
                                  disabled={isSubmitting}
                                >
                                  Next
                                  {isSubmitting && (
                                    <Spinner className='spinner' />
                                  )}
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <Formik
                        enableReinitialize
                        initialValues={{
                          username: username,
                          verificationCode: '',
                          password: '',
                          password_confirmation: ''
                        }}
                        validationSchema={Yup.object().shape({
                          username: Yup.string().required(
                            'Enter Username/Email'
                          ),
                          verificationCode: Yup.string().required('Enter Code'),
                          password: Yup.string().required('Enter Password'),
                          password_confirmation: Yup.string().required(
                            'Enter Password Confirmation'
                          )
                        })}
                        onSubmit={(values) => {
                          handleValidSubmit(values)
                        }}
                      >
                        {({ errors, touched }) => (
                          <Form className='form-horizontal'>
                            <h3 className='text-center mb-5'>Set Password</h3>

                            <div className='form-group'>
                              <Label for='username' className='form-label'>
                                Username/Email
                              </Label>
                              <Field
                                name='username'
                                type='input'
                                required
                                disabled
                                placeholder='Enter Username/Email'
                                value={username}
                                className={
                                  'form-control' +
                                  (errors.username && touched.username
                                    ? ' is-invalid'
                                    : '')
                                }
                              />
                              <ErrorMessage
                                name='username'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>

                            <div className='form-group'>
                              <Label
                                for='verificationCode'
                                className='form-label'
                              >
                                Code
                              </Label>
                              <Field
                                name='verificationCode'
                                type='input'
                                required
                                placeholder='Enter Code'
                                className={
                                  'form-control' +
                                  (errors.verificationCode &&
                                  touched.verificationCode
                                    ? ' is-invalid'
                                    : '')
                                }
                              />
                              <ErrorMessage
                                name='verificationCode'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>

                            <div className='form-group'>
                              <Label for='password' className='form-label'>
                                New Password
                              </Label>
                              <Field
                                name='password'
                                type='password'
                                required
                                placeholder='Enter new password'
                                className={
                                  'form-control' +
                                  (errors.password && touched.password
                                    ? ' is-invalid'
                                    : '')
                                }
                              />
                              <ErrorMessage
                                name='password'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>

                            <div className='form-group'>
                              <Label
                                for='password_confirmation'
                                className='form-label'
                              >
                                Confirm Password
                              </Label>
                              <Field
                                name='password_confirmation'
                                type='password'
                                required
                                placeholder='Enter confirm password'
                                className={
                                  'form-control' +
                                  (errors.password_confirmation &&
                                  touched.password_confirmation
                                    ? ' is-invalid'
                                    : '')
                                }
                              />
                              <ErrorMessage
                                name='password_confirmation'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>

                            <Row className='form-group'>
                              <Col className='d-grid'>
                                <button
                                  className='btn btn-light-login w-md waves-effect waves-light save-btn mr-2'
                                  disabled={isSubmitting}
                                  onClick={gotoPrevStep}
                                  type='button'
                                >
                                  Back
                                </button>
                              </Col>
                              <Col className='d-grid'>
                                <button
                                  className='btn btn-login w-md waves-effect waves-light save-btn theme-button'
                                  disabled={isSubmitting}
                                  type='submit'
                                >
                                  Reset
                                  {isSubmitting && (
                                    <Spinner className='spinner' />
                                  )}
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className='mt-5 text-center'>
                <p className='forgot-text'>
                  Go back to&nbsp;
                  <Link
                    to={`/login/${id}`}
                    className='font-weight-medium text-darkblue back-btn '
                  >
                    Login
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default ForgotPassword
