import React from 'react'
import { Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap'

import { toast } from 'react-toastify'

/**
 * ToastMessages Page component.
 *
 * @remarks
 * page where we showcase all notifcations
 *
 * @component ToastMessages
 * @category Page
 */
const ToastMessages = () => {
  const showSuccessToast = () => {
    toast.success('This is success!')
  }

  const showDangerToast = () => {
    toast.error('This is error!')
  }

  const showWarningToast = () => {
    toast.warning('This is warning!')
  }

  const showInfoToast = () => {
    toast.info('This is info!')
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>Toastify</h4>
              </CardTitle>
              <div>
                <Button
                  color='success'
                  className='btn'
                  onClick={showSuccessToast}
                >
                  Show Success Toast
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Button color='danger' className='btn' onClick={showDangerToast}>
                Show Danger Toast
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Button
                color='warning'
                className='btn'
                onClick={showWarningToast}
              >
                Show Warning Toast
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Button color='info' className='btn' onClick={showInfoToast}>
                Show Info Toast
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ToastMessages
