import React, { useCallback, useEffect, useState } from 'react'
import {
  AuthService,
  AccessRequestService
} from '@digitalworkflow/dwloginclient'
import { portalName } from '../constants'
import OverlayLoader from '../components/OverlayLoader/overlayLoader'
import { checkSuperAdmin, checkBGISAdmin } from '../utils/checkAdmin'
import { useAuth } from './AuthContext'

const authService = AuthService.instance()
AuthService.setPortalLoginId(portalName)
AccessRequestService.setAuthServiceInstance(authService)
const accessRequestService = new AccessRequestService()

type INotifContext = {
  notifCount: number | undefined
  updateNotifCount: () => void
}

export const NotifContext: React.Context<INotifContext> = React.createContext(
  {} as INotifContext
)

export const NotifProvider = ({
  children
}: {
  children: React.ReactNode
}): JSX.Element => {
  const auth = useAuth()
  const [notifCount, setNotifCount] = useState<number | undefined>()
  const [isLoaded, setIsLoaded] = useState(true) // Initialize loading state

  useEffect(() => {
    if (checkBGISAdmin() || checkSuperAdmin()) {
      updateNotifCount()
    } else {
      setIsLoaded(false)
    }
  }, [auth])

  const updateNotifCount = useCallback(async () => {
    const result = await accessRequestService.getAll('pending', 'restricted')
    if (result && !result.is_error && result.data) {
      setNotifCount(result.data?.length < 99 ? result.data?.length : 99)
    }
    setIsLoaded(false)
  }, [])

  // Provide loading state in context
  return (
    <NotifContext.Provider
      value={{
        notifCount,
        updateNotifCount
      }}
    >
      {isLoaded ? <OverlayLoader /> : children}
    </NotifContext.Provider>
  )
}

export const useNotif = (): INotifContext => React.useContext(NotifContext)
