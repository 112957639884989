import React, { useCallback, useRef, useState } from 'react'
import {
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
  GridReadyEvent,
  IGetRowsParams
} from 'ag-grid-community'
import { Spinner } from 'reactstrap'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import { convertStrToLocalDate } from '../../utils/convertStrToLocalDate'
import TableCellHTML from './tableCellHTML'
import DateHeader from './dateHeader'
import { getFailedEmails } from '../../utils/getFailedEmails'
import AgGridTooltip from '../AgGrdiTooltip/AgGridTooltip'
import CustomDateFilter from '../Aggrid/CustomDateFilter'

const PageSize = 50

const today = new Date().toISOString().split('T')[0]

const columnDefs = [
  {
    field: 'subject',
    headerName: 'Subject',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      filterOptions: ['contains', 'equals']
    },
    floatingFilter: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    },
    minWidth: 500,
    cellRenderer: (params: ICellRendererParams) => {
      if (params.value !== undefined) {
        return <AgGridTooltip text={params.value} />
      } else {
        return <Spinner size='sm' />
      }
    }
  },
  {
    field: 'target',
    headerName: 'Target',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      filterOptions: ['contains', 'equals']
    },
    floatingFilter: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    },
    cellRenderer: (params: ICellRendererParams) => {
      return <AgGridTooltip text={params.value} />
    }
  },
  {
    field: 'cc',
    headerName: 'CC',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      filterOptions: ['contains', 'equals']
    },
    floatingFilter: true,
    valueGetter: (params: ValueGetterParams) => {
      if (params.data?.cc?.length) {
        return params.data?.cc?.join(', ')
      } else {
        return '-'
      }
    },
    cellRenderer: (params: ICellRendererParams) => {
      return <AgGridTooltip text={params.value} />
    },
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  {
    field: 'bcc',
    headerName: 'BCC',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      filterOptions: ['contains', 'equals']
    },
    floatingFilter: true,
    valueGetter: (params: ValueGetterParams) => {
      if (params.data?.bcc?.length) {
        return <AgGridTooltip text={params.data?.bcc?.join(', ')} />
      } else {
        return '-'
      }
    },
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },

  {
    field: 'email_body',
    headerName: 'Body',
    cellRenderer: (params: ICellRendererParams) => {
      if (params?.data?._id !== undefined) {
        return (
          <TableCellHTML
            id={params?.data?._id}
            type='failed_emails'
          ></TableCellHTML>
        )
      } else {
        return null
      }
    },
    minWidth: 130,
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  {
    field: 'timestamp',
    headerName: 'TimeStamp',
    sortable: true,
    unSortIcon: true,
    filter: 'agDateColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      filterOptions: ['equals', 'lessThan', 'greaterThan']
    },
    floatingFilter: true,
    floatingFilterComponent: CustomDateFilter,
    floatingFilterComponentParams: (props: any) => ({
      ...props,
      maxValidDate: today
    }),
    cellStyle: {
      display: 'flex',
      alignItems: 'center'
    },
    valueFormatter: (params: ValueFormatterParams) => {
      return convertStrToLocalDate(
        params?.data?.timestamp,
        new Date(params?.data?.timestamp).getTimezoneOffset()
      )
    },
    valueGetter: (params: ValueGetterParams) => {
      return convertStrToLocalDate(
        params?.data?.timestamp,
        new Date(params?.data?.timestamp).getTimezoneOffset()
      )
    },
    cellRenderer: (params: ICellRendererParams) => {
      return <AgGridTooltip text={params.value} />
    },
    headerComponent: (props: any) => (
      <DateHeader fieldName='timestamp' {...props} />
    )
  }
]

const defaultColDef = {
  flex: 1,
  minWidth: 90,
  resizable: true
}

function FailedEmailReportsList() {
  const gridRef = useRef<any>(null)
  const [currentCount, setCurrentCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const getRowsFailedEmails = useCallback(async (params: IGetRowsParams) => {
    const filter = {}
    if (params.filterModel) {
      Object.keys(params.filterModel).forEach((key) => {
        filter[key] =
          params.filterModel[key]?.filter || params.filterModel[key].dateFrom
        filter[`${key}_type`] = params.filterModel[key].type
      })
    }
    let sort, sortOrder
    if (params.sortModel.length > 0) {
      sort = params.sortModel[0].colId
      sortOrder = params.sortModel[0].sort
    }
    try {
      const page = Math.floor(params.startRow / PageSize) + 1
      const res = await getFailedEmails({
        page,
        limit: PageSize,
        filter,
        sort,
        sortOrder
      })
      const resData = res?.data || {}
      const rowsThisPage = resData?.data ?? []
      const lastRow = resData?.total !== undefined ? resData.total : -1

      setCurrentCount((prev) =>
        page === 1 ? rowsThisPage.length : prev + rowsThisPage.length
      )
      setTotalCount(resData.total)
      // If there are no more rows, set the rowCount to the actual number of rows
      if (rowsThisPage.length === 0) {
        gridRef.current!.api.showNoRowsOverlay()
        params.successCallback(rowsThisPage, params.startRow)
      } else {
        gridRef.current!.api.hideOverlay()
        params.successCallback(rowsThisPage, lastRow)
      }
    } catch (error) {
      console.log('Failed to fetch rows', error)
      params.failCallback()
    }
  }, [])

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource = {
        rowCount: undefined,
        getRows: getRowsFailedEmails
      }

      params.api.setDatasource(dataSource)
    },
    [getRowsFailedEmails]
  )

  return (
    <div style={{ height: 'calc(100vh - 200px)' }}>
      <div className='ag-theme-alpine' style={{ height: '100%' }}>
        <LoginPortalGrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          defaulColDef={defaultColDef}
          rowModelType='infinite'
          cacheBlockSize={PageSize}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={PageSize}
          maxBlocksInCache={10}
          onGridReady={onGridReady}
          pagination={false}
        />
      </div>
      <div
        style={{
          textAlign: 'right',
          marginTop: '10px',
          paddingRight: '2px',
          fontWeight: 'bold',
          fontSize: '0.7rem'
        }}
      >
        Showing {currentCount} out of {totalCount} total
      </div>
    </div>
  )
}

export default FailedEmailReportsList
