import React, { useRef } from 'react'
import { RCDockLayout, DockLayout } from '@digitalworkflow/dwreactcommon'
import ManageProfile from '../../components/ManageProfile/ManageProfile'
import placeholderImage from '../../assets/images/placeholder.png'
import { RcGroup } from '../../components/RcGroup'
import { AuthService, UserService } from '@digitalworkflow/dwloginclient'
import { LocalSettings } from '../../utils/LocalSettings'

const authService = AuthService.instance()
UserService.setAuthServiceInstance(authService)

const UserProfile = () => {
  const dockLayoutRef = useRef<DockLayout | null>(null)

  const getTabData = () => {
    const profile = JSON.parse(LocalSettings.getUser() ?? '')
    return {
      id: 'profile',
      close: false,
      title: (
        <>
          <div className='user-tab-header'>
            <img src={placeholderImage} />
            <span>
              {profile.first_name} {profile.last_name}
            </span>
          </div>
        </>
      ),
      content: (
        <ManageProfile
          id={profile.id ?? ''}
          handleUpdateUser={() => {}}
          myProfile
        />
      ),
      group: 'close-all'
    }
  }
  const profileTab = {
    id: 'usersSection',
    tabs: [getTabData()],
    group: 'close-all'
  }

  const userSections = [
    {
      mode: 'horizontal',
      children: [profileTab]
    }
  ]

  const defaultLayout: any = {
    dockbox: {
      mode: 'vertical',
      children: [
        {
          size: 84,
          mode: 'horizontal',
          children: userSections as any
        }
      ]
    },
    floatbox: {
      children: []
    }
  }

  return (
    <RCDockLayout
      dockLayoutRef={dockLayoutRef}
      defaultLayout={defaultLayout}
      groups={RcGroup}
    />
  )
}
export default UserProfile
