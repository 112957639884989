import {
  emailReportsMenu,
  featureAccessMenuItems,
  menuItems
} from '../constants'
import { LocalSettings } from './LocalSettings'

export const getFeatureAccessBasedNavbar = () => {
  const _menuItems = [...menuItems]
  let userData: any = LocalSettings.getUser()

  const _children: any[] = []

  if (userData) {
    userData = JSON.parse(userData ?? '')
    if (userData.email === 'admin@dwhub.com') {
      // Enable the menu items for admin, regardless of features_enabled
      _menuItems[0].children = featureAccessMenuItems
      _menuItems.splice(1, 0, emailReportsMenu)
    } else {
      const userFeaturesAccessItems =
        userData?.features_enabled?.filter(
          (item: any) => item.feature_name !== 'Manage Email Reports'
        ) ?? []

      userFeaturesAccessItems.forEach((element: any) => {
        //  Check user access the specific sidebar item
        const featureItem = featureAccessMenuItems.find(
          (item) => `Manage ${item.subtitle_en}` === element.feature_name
        )
        featureItem && _children.push(featureItem)
      })
      _menuItems[0].children = _children

      if (
        userData?.features_enabled?.some(
          (item: any) => item.feature_name === 'Manage Email Reports'
        )
      ) {
        _menuItems.splice(1, 0, emailReportsMenu)
      }
    }
  }

  return _menuItems
}
