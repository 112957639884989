import React, { useState, useRef } from 'react'
import { Tooltip as ReactstrapTooltip } from 'reactstrap'

interface AgGridTooltipProps {
  text: string
  placement?: any
}

const AgGridTooltip: React.FC<AgGridTooltipProps> = ({
  text,
  placement = 'top'
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const toggleRef = useRef<HTMLElement | null>(null)
  const toggle = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement
    const { offsetWidth, scrollWidth } = target
    if (offsetWidth < scrollWidth) {
      setTooltipOpen(!tooltipOpen)
    }
  }

  return (
    <>
      <span ref={toggleRef} className='truncate'>
        {text}
      </span>
      {toggleRef.current && (
        <ReactstrapTooltip
          isOpen={tooltipOpen}
          target={toggleRef.current}
          toggle={toggle}
          placement={placement}
        >
          {text}
        </ReactstrapTooltip>
      )}
    </>
  )
}

export default AgGridTooltip
