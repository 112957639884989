import React from 'react'
import { Spinner } from 'reactstrap'

import style from './overlayLoader.module.scss'

const OverlayLoader = () => {
  return (
    <div className={`flex justify-center items-start ${style.overlayLoader}`}>
      <Spinner />
    </div>
  )
}

export default OverlayLoader
