/* eslint-disable camelcase */
import React from 'react'
import { Button, Row, Col } from 'reactstrap'

import { Formik } from 'formik'
import * as Yup from 'yup'
import FormikInput from '../Formik/FormikInput/formikInput'
import { UserType } from '@digitalworkflow/dwloginclient'

const isNotEmpty = (value: string) => value.trim().length > 0

interface ChangePasswordProps {
  isManageProfile?: boolean
  profile: UserType | null
  changeUserPassword: (userId: string | null, values: FormValue) => void
}
interface FormValue {
  current_password: string
  new_password: string
  re_enter_password: string
}
const initialValues: FormValue = {
  current_password: '',
  new_password: '',
  re_enter_password: ''
}

const ChangePassword = ({
  isManageProfile = false,
  profile,
  changeUserPassword
}: ChangePasswordProps) => {
  const validationSchema = Yup.object().shape({
    current_password: isManageProfile
      ? Yup.string().notRequired()
      : Yup.string()
          .required('Current password is required')
          .test(
            'no-only-spaces',
            'Current password cannot consist of only spaces',
            isNotEmpty
          ),
    new_password: Yup.string()
      .required('New password is required')
      .test(
        'no-only-spaces',
        'New password cannot consist of only spaces',
        isNotEmpty
      ),
    re_enter_password: Yup.string()
      .required('Re-enter password is required')
      .test(
        'no-only-spaces',
        'Re-enter password cannot consist of only spaces',
        isNotEmpty
      )
      .test('passwords-match', 'Passwords do not match', function (value) {
        return value === this.parent.new_password
      })
  })

  return (
    <>
      <div className={`p-2 `}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values: FormValue) => {
            changeUserPassword(profile?.id ?? null, values)
          }}
        >
          {({ resetForm, handleSubmit, dirty }) => {
            const handleCancel = () => {
              resetForm()
            }
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Row className='mb-2'>
                    {!isManageProfile && (
                      <FormikInput
                        type='password'
                        name='current_password'
                        label='Current password'
                        placeholder='Enter current password'
                        className='mb-2'
                      />
                    )}
                    <FormikInput
                      name='new_password'
                      label='New password'
                      placeholder='Enter new password'
                      type='password'
                      className='mb-2'
                    />
                    <FormikInput
                      name='re_enter_password'
                      label='Re-Enter password'
                      placeholder='Re-enter new password'
                      type='password'
                      className='mb-2'
                    />
                    <Col className='d-flex gap-2'>
                      <Button
                        type='submit'
                        color='edit'
                        className='btn-sm'
                        disabled={!dirty}
                      >
                        <i className='fal fa-edit me-2' />
                        Update
                        {/* {isSubmitting && <Spinner className={style.spinner} />} */}
                      </Button>
                      <Button
                        type='button'
                        color='cancel'
                        className='btn-sm'
                        disabled={!dirty}
                        onClick={handleCancel}
                      >
                        <i className='fa fa-times me-2' />
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default ChangePassword
