/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react'
import { NodeProps, TreeView } from '@digitalworkflow/dwreactcommon'
import style from './Role.module.scss'
import {
  AuthService,
  WorkgroupService,
  WorkgroupType
} from '@digitalworkflow/dwloginclient'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import OverlayLoader from '../../components/OverlayLoader/overlayLoader'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'

interface IAddWorkgroup {
  handleRemoveWorkgroup: () => void
  handleNewWorkgroup: () => void
}
export interface IDropdown {
  value: string
  label: string
}

const AddWorkgroup = ({
  handleRemoveWorkgroup,
  handleNewWorkgroup
}: IAddWorkgroup) => {
  const authService = AuthService.instance()

  WorkgroupService.setAuthServiceInstance(authService)
  const workgroupService = new WorkgroupService()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [workgroups, setWorkgroups] = useState<any[]>([])
  const [dropdownData, setDropdownData] = useState<IDropdown[]>([])
  useEffect(() => {
    getWorkgroups()
  }, [])

  const getWorkgroups = useCallback(async () => {
    setIsLoading(true)
    const _workgroups: ApiResultType<WorkgroupType[]> =
      await workgroupService.getAllWorkgroup('restricted')
    if (!_workgroups.is_error) {
      const _workgroupList = transformData(_workgroups?.data ?? [])
      const _dropdownData = (_workgroups?.data ?? []).map((item) => {
        return {
          label: item.formatted_workgroup_name,
          value: item.id
        }
      })
      setDropdownData([...(_dropdownData as IDropdown[])])
      setWorkgroups([..._workgroupList])
    }
    setIsLoading(false)
  }, [setIsLoading, setWorkgroups, setDropdownData])

  const transformData = useCallback((inputData: WorkgroupType[]) => {
    const nodeMap: { [id: string]: any } = {}
    const existingIds = new Set<string>()

    const rootNodes: any[] = []

    // First pass: Create nodes and populate nodeMap
    inputData.forEach((item) => {
      const node: any = {
        id: item.id,
        key: item.id,
        title: item.work_group_name,
        description: item.description,
        isExpand: true,
        parentId: item.parent_work_group?.trim() || null, // Set parentId to null by default
        children: []
      }

      existingIds.add(node.id)
      nodeMap[node.id] = node

      if (!node.parentId) {
        rootNodes.push(node)
      }
    })

    // Second pass: Assign children to parent nodes
    inputData.forEach((item: any) => {
      const parent = nodeMap[item.parent_work_group]
      const node = nodeMap[item.id]

      if (parent && node) {
        parent.children.push(node)
      }
    })

    // Third pass: Check if parent IDs exist in the data, if not, promote children to root nodes
    Object.values(nodeMap).forEach((node: any) => {
      if (node.parentId && !existingIds.has(node.parentId)) {
        rootNodes.push(node)
        node.parentId = null
      }
    })

    return rootNodes
  }, [])

  const handleAddWorkgroup = useCallback(
    async (workgroup: NodeProps) => {
      setIsLoading(true)
      let _result: any = null
      // Need to create workgroups by deep nodes
      const createWorkGroup = async (wk: NodeProps) => {
        const _workgroups = {
          work_group_name: wk.title,
          parent_work_group: wk.parentId ?? '',
          approver_group_name: wk.approverId,
          work_group_type: 'SystemGroup',
          description: wk.description
        }

        _result = await workgroupService.createWorkgroup(_workgroups)

        if (_result.is_error) {
          toast.error(_result.message)
          return
        }
        for (let i = 0; i < wk?.children?.length; i++) {
          const element = wk?.children?.[i]
          await createWorkGroup({
            ...element,
            parentId: _result.data?.id
          })
        }
      }

      await createWorkGroup(workgroup)

      setIsLoading(false)
      if (_result && !_result.is_error) {
        toast.success('Workgroup is added successfully in this hierarchy')
      }
      handleNewWorkgroup()

      getWorkgroups()
    },
    [setIsLoading]
  )

  return (
    <div className={style.roleContainer}>
      <div className={`${style.role} flex justify-between`}>
        <span>Add New Workgroup</span>
        <Button color='edit' onClick={handleRemoveWorkgroup} className='btn-sm'>
          <i className={`fa-solid fa-arrow-left me-2 ${style.icon}`} />
          Go back to workgroup list
        </Button>
      </div>
      {isLoading && <OverlayLoader />}
      {workgroups.length === 0 ? (
        isLoading ? (
          <></>
        ) : (
          <TreeView
            height='93%'
            addButtonText='Add workgroup'
            titlePlaceholder='Type workgroup name'
            nameValidationMessage='Workgroup name is required.'
            data={[...workgroups]}
            duplicateErrorMessage='Workgroup name already taken, Please use a different name.'
            isShowDropdown
            dropdownData={dropdownData}
            dropdownPlaceholder='Select approver group name'
            onChange={handleAddWorkgroup}
          />
        )
      ) : (
        <TreeView
          height='93%'
          addButtonText='Add workgroup'
          titlePlaceholder='Type workgroup name'
          nameValidationMessage='Workgroup name is required.'
          data={[...workgroups]}
          duplicateErrorMessage='Workgroup name already taken, Please use a different name.'
          isShowDropdown
          dropdownData={dropdownData}
          dropdownPlaceholder='Select approver group name'
          onChange={handleAddWorkgroup}
        />
      )}
    </div>
  )
}
export default AddWorkgroup
