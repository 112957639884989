import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AuthService } from '@digitalworkflow/dwloginclient'
import { LocalSettings } from '../../utils/LocalSettings'
const authService = AuthService.instance()

const ForceLogout = () => {
  const { id } = useParams()

  useEffect(() => {
    authService.authLogout()
    LocalSettings.removeAuthToken()
    LocalSettings.removeUser()
    LocalSettings.removeMultiAuthToken()
    window.location.href = `/login/${id}${
      window.location.host.match(/local/) ? '?return=local' : ''
    }`
  }, [])

  return <div key='logout'>Logout Complete</div>
}

export default ForceLogout
