import React from 'react'
import { LookupManager } from '@digitalworkflow/dwreactcommon'

const LookupManagerPage = () => {
  return (
    <div>
      <LookupManager
        allowEdit
        allowDelete
        metaData={[
          { key: 'psif_orm', title: 'PSIF ORM' },
          { key: 'psif_io_groups_min', title: 'IO Group' },
          { key: 'psif_move_managers', title: 'Move Manager' }
        ]}
      />
    </div>
  )
}

export default LookupManagerPage
