import React, { useState } from 'react'
import { Row, Col, Form, Input, Button, Tooltip } from 'reactstrap'

type IConfiguration = {
  tableName: string
  clientIds: string
  returnFields: string
  customFilter?: string
  onChange: (type: string, val: any | null) => void
  onApply: () => void
  onCancel: () => void
}

const Info = (props: { id: string; content: string }) => {
  const { id, content } = props
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  return (
    <>
      <div className='d-inline-block'>
        <a id={`tooltip-${id}`}>
          <i className='fa-thin fa-circle-info'></i>
        </a>
        <Tooltip
          autohide
          flip
          isOpen={tooltipOpen}
          target={`tooltip-${id}`}
          toggle={toggle}
        >
          {content}
        </Tooltip>
      </div>
    </>
  )
}
const Configurations = (props: IConfiguration) => {
  const {
    tableName,
    clientIds,
    returnFields,
    customFilter,
    onChange,
    onApply,
    onCancel
  } = props

  return (
    <Form className='configurations'>
      <Row className='mt-1 header mb-3'>
        <Col>Configuration Name</Col>
        <Col>Action </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Table Name <Info id='1' content='Table Name.' />
        </Col>
        <Col>
          <Input
            value={tableName}
            onChange={(e) => {
              onChange('tableName', e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Client Ids{' '}
          <Info
            id='2'
            content='A list of client_id values to filter from the building list.'
          />
        </Col>
        <Col>
          <Input
            value={clientIds}
            onChange={(e) => {
              onChange('clientIds', e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Return Fields{' '}
          <Info
            id='3'
            content='A simple text template with field replacement to get selection item info.'
          />
        </Col>
        <Col>
          <Input
            value={returnFields}
            onChange={(e) => {
              onChange('returnFields', e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Custom Filter
          <Info
            id='4'
            content='Will apply custom filter when calling building search api.'
          />
        </Col>
        <Col>
          <Input
            value={customFilter}
            onChange={(e) => {
              onChange('customFilter', e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col className='text-end'>
          <Button color='primary' className='mx-1 px-4' onClick={onApply}>
            Apply
          </Button>
          <Button color='secondary' className='px-4' onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default Configurations
