import React from 'react'
import FailedEmailReportsList from '../../components/EmailReportsList/FailedEmailReportsList'

const FailedEmailReports = () => {
  return (
    <div className='p-2'>
      <div className='d-flex justify-content-between align-items-center'>
        <h6>Failed Emails</h6>
      </div>

      <FailedEmailReportsList />
    </div>
  )
}
export default FailedEmailReports
