import React, { memo } from 'react'
import ReactSelect, { Props, StylesConfig } from 'react-select'
export interface SelectProps extends Props {
  style?: StylesConfig<any, any, any>
}

const Select = React.forwardRef<any, SelectProps>((props: SelectProps, ref) => (
  <ReactSelect
    styles={{ ...(props?.style ?? {}) }}
    {...props}
    ref={ref}
    isSearchable
  />
))
export default memo(Select)
