import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import 'grapesjs/dist/css/grapes.min.css'
import { GrapesjsReact } from 'grapesjs-react'
import grapesjs from 'grapesjs'
import gjspresetwebpage from 'grapesjs-preset-webpage'
import gjsblockbasic from 'grapesjs-blocks-basic'
// import gjsblockform from 'grapesjs-plugin-forms'
import { Button } from 'reactstrap'
import {
  AuthService,
  TemplateService,
  UserService
} from '@digitalworkflow/dwloginclient'
import { NewTemplateType } from '../../types/Template'
import { toast } from 'react-toastify'
import { LocalSettings } from '../../utils/LocalSettings'
const authService = AuthService.instance()

const EmailTemplateEditor = () => {
  const param = useParams()
  const editorRef = useRef<grapesjs.Editor | null>(null)
  const templateString = LocalSettings.getHubTemplate(param.id ?? '')
  // Provide a default object structure in case the templateString is null or empty
  const defaultTemplate = {
    id: '',
    name: '',
    key: '',
    status: '',
    template: ''
  }
  const { id, name, key, status, template } = templateString
    ? JSON.parse(templateString)
    : defaultTemplate
  UserService.setAuthServiceInstance(authService)
  TemplateService.setAuthServiceInstance(authService)
  const templateService = TemplateService.instance()

  const handleSave = async (type: string) => {
    const fullHtmlCode = editorRef.current?.getHtml({})
    const cssCode = editorRef.current?.getCss()

    // extracting html body tag because sometimes body tag has id attribute and sometimes it doesn't
    const htmlBodyTag =
      fullHtmlCode?.substring(
        fullHtmlCode.indexOf('<body'),
        fullHtmlCode.indexOf('>') + 1
      ) ?? '<body>'

    const modifiedHtmlCode = fullHtmlCode?.replace(
      htmlBodyTag,
      `${htmlBodyTag}<style>${cssCode}</style>`
    )
    if (templateString) {
      const newTemplate: NewTemplateType = {
        name: name,
        key: key,
        status: type ?? status,
        template: modifiedHtmlCode ?? ''
      }

      let _result

      if (id) {
        _result = await templateService.updateTemplateById(id, newTemplate)
      } else {
        _result = await templateService.createTemplate(newTemplate)
      }

      if (_result.is_error) {
        toast.error('Template Save Failed.')
      } else {
        const channel = new BroadcastChannel('templateChannel')
        channel.postMessage({ templateAdded: true })
        channel.close()
        window.close()
      }
    }
  }

  return (
    <>
      <GrapesjsReact
        id='grapesjs-react'
        plugins={[gjspresetwebpage, gjsblockbasic]}
        onInit={(editor) => {
          if (template && editor) {
            editor.setComponents(template, {})
          }
          editorRef.current = editor ?? null
        }}
        storageManager={false}
      />
      <div className='flex justify-center m-1'>
        <Button
          type='button'
          color='cancel'
          className='btn-sm mb-1 mr-1 w-32'
          onClick={() => handleSave('Draft')}
        >
          Save As Draft
        </Button>
        <Button
          type='button'
          color='info'
          className='btn-sm mb-1 w-32'
          onClick={() => handleSave('Publish')}
        >
          Publish
        </Button>
      </div>
    </>
  )
}

export default EmailTemplateEditor
