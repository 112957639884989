import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthService, AuthResultType } from '@digitalworkflow/dwloginclient'
import { useAuth } from '../../context/AuthContext'
import './Dashboard.scss'
import { featureAccessMenuItems, portalName } from '../../constants'
import { LocalSettings } from '../../utils/LocalSettings'
const authService = AuthService.instance()

/**
 *
 * @remarks
 * Dashboard Page
 *
 * @component Dashboard
 * @category Page
 */
const Dashboard = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setIsAuthenticated, isAuthenticated, setUser } = useAuth()

  useEffect(() => {
    const init = async () => {
      const params = new URLSearchParams(location.search)
      const token = params.get('token')
      const redirectUrl = LocalSettings.getRedirectUrl()

      if (isAuthenticated) {
        return navigate(redirectUrl ?? `/hub/users`)
      }
      if (token) {
        LocalSettings.setAuthToken(token)

        AuthService.setPortalLoginId(portalName)
        const res: AuthResultType = await authService.authUserFromToken(token)
        console.log(res)
        if (res) {
          if (res?.is_error === false) {
            setIsAuthenticated(true)
            setUser({
              username: res.data.user.email,
              email: res.data.user.email
            })
            LocalSettings.setUser(JSON.stringify(res.data.user))

            // Retrieve the redirect URL from local storage

            if (redirectUrl) {
              return navigate(redirectUrl)
            } else {
              const userData = JSON.parse(LocalSettings.getUser() ?? '')
              if (userData.features_enabled.length) {
                const featureNames = userData.features_enabled.map(
                  (item: any) => item.feature_name.toLowerCase()
                )
                const firstItem = featureAccessMenuItems.filter((item: any) =>
                  featureNames.includes(item.tooltip_en.toLowerCase())
                )[0]
                if (firstItem?.route) {
                  const path = firstItem?.route
                  return navigate(path)
                }
              } else {
                return navigate('/hub/profile')
              }
            }
          }
        }
      }
      setIsAuthenticated(false)
      LocalSettings.removeAuthToken()
      LocalSettings.removeUser()
      LocalSettings.removeMultiAuthToken()
      window.location.href = `/login/${portalName}`
    }
    init()
  }, [])

  return <div className='dashboard-page'></div>
}

export default Dashboard
