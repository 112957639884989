import React from 'react'
import { SingleFileAttachment } from '@digitalworkflow/dwreactcommon'

const SingleFileAttachmentPage = () => {
  return (
    <div>
      <SingleFileAttachment
        onChange={(files: any) => console.log(files)}
        ref_id='task50'
        projectName='dwportal'
        hideDocumentUploadSection={false}
        extensions={['.jpg', '.jpeg', '.png', '.xls', '.ppt', '.pptx', '.mp4']}
      />
    </div>
  )
}

export default SingleFileAttachmentPage
