import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Row, Spinner } from 'reactstrap'
import {
  AuthService,
  BulkFactoryType,
  BulkService,
  UserService,
  UserType
} from '@digitalworkflow/dwloginclient'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import ToggleButton from '../ToggleButton/ToggleButton'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import style from './ManagePortals.module.scss'
import { toast } from 'react-toastify'

interface IPortalMembers {
  portal_id: string
}
type SortDirection = 'asc' | 'desc' | null

const PortalMembers = ({ portal_id }: IPortalMembers) => {
  const [users, setUsers] = useState<UserType[]>([])
  const [disabledUsersEmailList, setDisabledUsersEmailList] = useState<
    string[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const authService = AuthService.instance()
  const bulkService = new BulkService()

  BulkService.setAuthServiceInstance(authService)
  UserService.setAuthServiceInstance(authService)

  const userService = new UserService()
  useEffect(() => {
    getUsers(portal_id)
  }, [])
  const isDisabled = useMemo(() => {
    if (disabledUsersEmailList.length) {
      return false
    } else return true
  }, [disabledUsersEmailList, portal_id])
  const columnDefs = useMemo(() => {
    return [
      {
        headerName: 'Name',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        minWidth: 140,
        flex: 2,
        floatingFilter: true,
        valueGetter: (params: any) =>
          params.data.first_name + ' ' + params.data.last_name,
        comparator: (a: any, b: any) =>
          a.toLowerCase().localeCompare(b.toLowerCase())
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        minWidth: 140,
        flex: 2,
        floatingFilter: true,
        comparator: (a: any, b: any) =>
          a.toLowerCase().localeCompare(b.toLowerCase())
      },
      {
        field: 'job_title',
        headerName: 'Job Title',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        minWidth: 140,
        flex: 2,
        floatingFilter: true,
        comparator: (a: any, b: any) =>
          a.toLowerCase().localeCompare(b.toLowerCase())
      },
      {
        field: 'action',
        headerName: 'Action',
        filter: 'agNumberColumnFilter',
        minWidth: 140,
        flex: 1,
        resizable: true,
        floatingFilter: true,
        sort: 'desc' as SortDirection,
        valueGetter: (params: any) =>
          users.some((user) => user.id === params.data.id),
        cellRenderer: (params: any) => (
          <div
            className={`flex justify-between items-center ${style.actionContainer}`}
          >
            <ToggleButton
              name='portal'
              isChecked={users.some((user) => user.id === params.data.id)}
              onChange={(event: any) =>
                handleEnableDisable(params.data.email, event.target.checked)
              }
            />
          </div>
        )
      }
    ]
  }, [users])
  const getUsers = useCallback(
    async (portal_id: string) => {
      setIsLoading(true)
      const _result: ApiResultType<UserType[]> =
        await userService.getUsersByPortalId(portal_id)
      if (!_result.is_error) {
        const data = _result.data ?? []
        if (data.length > 0) {
          setUsers(_result.data ?? [])
        } else {
          setUsers([])
        }
      }
      setIsLoading(false)
    },
    [setUsers, setIsLoading]
  )
  const handleBulkAction = useCallback(async () => {
    const bulkData = {
      resource: 'portal',
      userList: disabledUsersEmailList,
      action: 'remove',
      value: portal_id,
      notify: false
    }
    const result: any = await bulkService.factory(bulkData as BulkFactoryType)
    if (!result.is_error) {
      toast.success('Portal members updated')
      setDisabledUsersEmailList([])
      getUsers(portal_id)
    } else {
      toast.error('Form Submission Error')
    }
  }, [disabledUsersEmailList])
  const handleCancel = () => {
    setDisabledUsersEmailList([])
    getUsers(portal_id)
  }
  const handleEnableDisable = useCallback(
    (email: string, isEnable: boolean) => {
      setDisabledUsersEmailList((prevDisabledUsersEmailList) => {
        if (!isEnable && !prevDisabledUsersEmailList.includes(email)) {
          return [...prevDisabledUsersEmailList, email]
        } else if (isEnable && prevDisabledUsersEmailList.includes(email)) {
          return prevDisabledUsersEmailList.filter(
            (disabledId) => disabledId !== email
          )
        }
        return prevDisabledUsersEmailList
      })
    },
    [setDisabledUsersEmailList]
  )
  return (
    <div className={style.portalContainer}>
      {isLoading ? (
        <div className='flex justify-center'>
          <Spinner />
        </div>
      ) : (
        <div className={style.grid}>
          <LoginPortalGrid rowData={users} columnDefs={columnDefs} />
          <Row className='mb-2 mt-2'>
            <Col className='d-flex gap-2 mb-2'>
              <Button
                type='submit'
                color='delete'
                className='btn-sm'
                onClick={handleBulkAction}
                disabled={isDisabled}
              >
                <i className='fal fa-edit me-2' />
                Update
                {isLoading && <Spinner className={style.spinner} />}
              </Button>
              <Button
                type='button'
                color='edit'
                className='btn-sm'
                disabled={isDisabled}
                onClick={handleCancel}
              >
                <i className='fa fa-times me-2' />
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}

export default PortalMembers
