import React, { useCallback, useMemo, useRef, useState } from 'react'
import './manageClaimsMapping.scss'
import { SelectDropdown } from '@digitalworkflow/dwreactcommon'
import {
  manageSelectStyle,
  claimMappingSelectStyle
} from '../../components/select/manageUser.styles'
import arrow from '../../assets/images/arrow.png'
import { useIsOverflow } from '../../hooks/userIsOverflow'
import { Tooltip } from 'reactstrap'
import { userPermissionOfReadOnly } from '../../utils/userPermissions'
import { LocalSettings } from '../../utils/LocalSettings'

const adGroups = ['BGIS Employees', 'RealSuite Admins', 'RealSuite Users']
const actionDropDown = [
  {
    value: '',
    label: 'Option 1'
  },
  {
    value: '',
    label: 'Option 2'
  },
  {
    value: '',
    label: 'Option 3'
  },
  {
    value: '',
    label: 'Option 4'
  }
]

const ManageClaimsMapping = () => {
  const parentRefs = adGroups.map(() => useRef(null))
  const childRefs = adGroups.map(() => useRef(null))
  const [tooltipOpen, setTooltipOpen] = useState(adGroups.map(() => false))

  const isReadOnlyAccess = useMemo(() => {
    return userPermissionOfReadOnly('Manage Claims Mapping')
  }, [LocalSettings.dwhub_user])

  const toggle = useCallback(
    (index: number) => {
      const newTooltipOpen = [...tooltipOpen]
      newTooltipOpen[index] = !newTooltipOpen[index]
      setTooltipOpen(newTooltipOpen)
    },
    [tooltipOpen]
  )

  const isOverflow = adGroups.map((_index, i) =>
    useIsOverflow(parentRefs[i], childRefs[i])
  )

  return (
    <div className='claimsMappingContainer'>
      <h6>Claims Mapping</h6>
      <div className='horizontalHeadingValues'>
        <div className='container'>
          <b>
            <span>AD Group</span>
          </b>
        </div>
        <div className='container'>
          <b>
            <span>Map to Workgroup</span>
          </b>
        </div>
        <div className='container'>
          <b>
            <span>Map to Role</span>
          </b>
        </div>
      </div>
      <div className='horizontalHeadingValues'>
        <div className='container'>
          {adGroups.map((group, index) => (
            <div key={index} className='adGroup'>
              <div ref={parentRefs[index]} className='titleContainer'>
                <span
                  ref={childRefs[index]}
                  className='adGroupValue'
                  id={'titleTooltip' + index}
                >
                  {group}
                </span>
              </div>
              <img src={arrow} width='100%' height='15px' alt='Arrow' />
              {isOverflow[index] && (
                <Tooltip
                  isOpen={tooltipOpen[index]}
                  target={'titleTooltip' + index}
                  toggle={() => toggle(index)}
                >
                  {group}
                </Tooltip>
              )}
            </div>
          ))}
        </div>
        <div className='container'>
          {adGroups.map((_, index) => (
            <div key={index} className='mapWorkgroup'>
              <div className='dropDownWidth display'>
                <SelectDropdown
                  isDisabled={isReadOnlyAccess}
                  style={{ ...manageSelectStyle, ...claimMappingSelectStyle }}
                  placeholder='Select'
                  options={actionDropDown}
                />
              </div>
              <img src={arrow} width='100%' height='15px' alt='Arrow' />
            </div>
          ))}
        </div>
        <div className='container'>
          {adGroups.map((_, index) => (
            <div key={index} className='dropDownWidth mapRole'>
              <SelectDropdown
                isDisabled={isReadOnlyAccess}
                style={{ ...manageSelectStyle, ...claimMappingSelectStyle }}
                options={actionDropDown}
                placeholder='Select'
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ManageClaimsMapping
