import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import {
  DescriptiveButton,
  DescriptiveButtonList,
  DescriptiveButtonProps
} from '@digitalworkflow/dwreactcommon'

export const descriptiveButtonList: DescriptiveButtonProps[] = [
  {
    variant: 'warning',
    title: 'Janitorial Services',
    description:
      'Changes to cleaning area, frequency, inspections or holidays.',
    classification: 'Service Level Change'
  },
  {
    variant: 'warning',
    title: 'Janitorial Services',
    description:
      'Changes to cleaning area, frequency, inspections or holidays.',
    classification: 'Service Level Change'
  },
  {
    variant: 'primary',
    title: 'Janitorial Services',
    description:
      'Changes to cleaning area, frequency, inspections or holidays.',
    classification: 'Service Level Change'
  },
  {
    variant: 'primary',
    title: 'Janitorial Services',
    description:
      'Changes to cleaning area, frequency, inspections or holidays.',
    classification: 'Service Level Change'
  },
  {
    variant: 'primary',
    title: 'Janitorial Services',
    description:
      'Changes to cleaning area, frequency, inspections or holidays.',
    classification: 'Service Level Change'
  },
  {
    variant: 'primary',
    title: 'Janitorial Services',
    description:
      'Changes to cleaning area, frequency, inspections or holidays.',
    classification: 'Service Level Change'
  },
  {
    variant: 'success',
    title: 'Janitorial Services',
    description:
      'Changes to cleaning area, frequency, inspections or holidays.',
    classification: 'Service Level Change'
  },
  {
    variant: 'success',
    title: 'Janitorial Services',
    description:
      'Changes to cleaning area, frequency, inspections or holidays.',
    classification: 'Service Level Change'
  },
  {
    variant: 'success',
    title: 'Janitorial Services',
    description:
      'Changes to cleaning area, frequency, inspections or holidays.',
    classification: 'Service Level Change'
  }
]
/**
 * DescriptiveButtons Page component.
 *
 * @remarks
 * page where we showcase all descriptive buttons
 *
 * @component DescriptiveButtons
 * @category Page
 */
const DescriptiveButtons = () => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>Descriptive Buttons Variants</h4>
              </CardTitle>
              <div className='dtb-group'>
                <DescriptiveButton
                  variant='primary'
                  title='Janitorial Services'
                  description='Changes to cleaning area, frequency, inspections or holidays.'
                  classification='Service Level Change'
                />
                <DescriptiveButton
                  variant='secondary'
                  title='Janitorial Services'
                  description='Changes to cleaning area, frequency, inspections or holidays.'
                  classification='Service Level Change'
                />
                <DescriptiveButton
                  variant='success'
                  title='Janitorial Services'
                  description='Changes to cleaning area, frequency, inspections or holidays.'
                  classification='Service Level Change'
                />
                <DescriptiveButton
                  variant='info'
                  title='Janitorial Services'
                  description='Changes to cleaning area, frequency, inspections or holidays.'
                  classification='Service Level Change'
                />
                <DescriptiveButton
                  variant='warning'
                  title='Janitorial Services'
                  description='Changes to cleaning area, frequency, inspections or holidays.'
                  classification='Service Level Change'
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>Descriptive Button List</h4>
              </CardTitle>
              <DescriptiveButtonList buttonList={descriptiveButtonList} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default DescriptiveButtons
