import React, { useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { BuildingSearch } from '@digitalworkflow/dwreactcommon'
import Configurations from './Configurations'

const BuildingSearchPage = () => {
  const [showFilterByPostalCode, setShowFilterByPostalCode] =
    useState<boolean>(true)
  const [showNearest, setShowNearest] = useState<boolean>(false)
  const [showDistance, setShowDistance] = useState<boolean>(true)
  const [clientIds, setClientIds] = useState<string>('')
  const [otherFilters, setOtherFilters] = useState<string>('')
  const [showFav, setShowFav] = useState<boolean>(false)
  const [minSelect, setMinSelect] = useState<number>(1)
  const [maxSelect, setMaxSelect] = useState<number>(10000)
  const [allowPaste, setAllowPaste] = useState<boolean>(true)
  const [templateLine1, setTemplateLine1] = useState<string>(
    '%ID% | %BUILDING_NAME%'
  )
  const [templateLine2, setTemplateLine2] = useState<string>('%ADDRESS%')

  const onConfigChange = (type: string, val: any = null) => {
    switch (type) {
      case 'showFilterByPostalCode':
        setShowFilterByPostalCode(!showFilterByPostalCode)
        break
      case 'showNearest':
        setShowNearest(!showNearest)
        break
      case 'showDistance':
        setShowDistance(!showDistance)
        break
      case 'clientIds':
        setClientIds(val)
        break
      case 'otherFilters':
        setOtherFilters(val)
        break
      case 'showFav':
        setShowFav(!showFav)
        break
      case 'minSelect':
        setMinSelect(val)
        break
      case 'maxSelect':
        setMaxSelect(val)
        break
      case 'allowPaste':
        setAllowPaste(!allowPaste)
        break
      case 'templateLine1':
        setTemplateLine1(val)
        break
      case 'templateLine2':
        setTemplateLine2(val)
        break
      default:
        break
    }
  }
  const onConfigApply = () => {}
  const onConfigCancel = () => {}

  return (
    <Row>
      <Col lg={6}>
        <Card>
          <CardBody>
            <CardTitle>
              <h4>Building Search Control</h4>
            </CardTitle>
            <BuildingSearch
              showFilterPostcode={showFilterByPostalCode}
              otherFilters={otherFilters}
              showFav={showFav}
              showNearest={showNearest}
              minSelect={minSelect}
              maxSelect={maxSelect}
              allowPaste={allowPaste}
              showDistance={showDistance}
              templateLine1={templateLine1}
              templateLine2={templateLine2}
              clientIds={clientIds}
              onChange={(items) => console.log('Building Selected: ', items)}
              value={['HYD00129']}
            />
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <CardBody>
            <CardTitle>
              <h4>Search Tool Configurations</h4>
            </CardTitle>
            <Configurations
              showFilterByPostalCode={showFilterByPostalCode}
              showNearest={showNearest}
              showFav={showFav}
              showDistance={showDistance}
              minSelect={minSelect}
              maxSelect={maxSelect}
              allowPaste={allowPaste}
              clientIds={clientIds}
              templateLine1={templateLine1}
              templateLine2={templateLine2}
              onChange={onConfigChange}
              onApply={onConfigApply}
              onCancel={onConfigCancel}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default BuildingSearchPage
