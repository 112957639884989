import React, { useEffect, useMemo, useRef, useState } from 'react'
import LoginPortalGrid from '../../components/LoginPortalGrid/LoginPortalGrid'
import ToggleButton from '../../components/ToggleButton/ToggleButton'
import style from './manageUser.module.scss'
import { Button, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import { emailRegex } from '../../constants'
interface IPreviewEdit {
  usersDetails: any
  handleUsersList: (users: any[]) => void
  hanldePreviousStep: () => void
}

const defaultDefCols = {
  sortable: true,
  unSortIcon: true,
  resizable: true,
  editable: true,
  minWidth: 140,

  floatingFilter: true
}

const PreviewEdit = ({
  usersDetails,
  handleUsersList,
  hanldePreviousStep
}: IPreviewEdit) => {
  const [users, setUsers] = useState<any[]>([])
  const [isToggle, setIsToggle] = useState<boolean>(false)

  const usersList = useMemo(() => {
    let _users = [...users]
    if (isToggle) {
      _users = _users.filter((item: any) => !emailRegex.test(item.Email))
    }
    return _users
  }, [users, isToggle])

  const handleDeteteRow = (id: string) => {
    const _users = [...users]
    const _index = _users.findIndex((item) => item.id === id)
    if (_index > -1) {
      toast.success('User removed successfully.')
      _users.splice(_index, 1)

      setUsers([..._users])
    }
  }

  useEffect(() => {
    setUsers([...usersDetails])
  }, [usersDetails])

  const gridRef = useRef<any>(null)
  const columnDefs = useMemo(
    () => [
      {
        field: 'First Name',
        headerName: 'First Name',
        flex: 2,
        filter: 'agTextColumnFilter'
      },
      {
        field: 'Last Name',
        headerName: 'Last Name',
        flex: 2,
        filter: 'agTextColumnFilter'
      },
      {
        field: 'Email',
        headerName: 'Email',
        filter: 'agTextColumnFilter',
        flex: 2,

        comparator: (a: any, b: any) =>
          a.toLowerCase().localeCompare(b.toLowerCase()),
        cellRenderer: (params: any) => {
          const isValid = emailRegex.test(params.data.Email)
          return (
            <div className={!isValid ? 'is-invalid' : ''}>{params.value}</div>
          )
        }
      },
      {
        field: 'Job Title',
        headerName: 'Job Title',

        flex: 2,
        comparator: (a: any, b: any) =>
          a.toLowerCase().localeCompare(b.toLowerCase())
      },
      {
        field: 'Phone',
        headerName: 'Phone',
        flex: 2
      },
      {
        field: 'action',
        headerName: 'Action',
        minWidth: 140,
        flex: 1,
        cellRenderer: (params: any) => (
          <div
            className='action-text'
            onClick={() => handleDeteteRow(params.data.id)}
          >
            <i className='fa fa-trash' color='red'></i>
          </div>
        )
      }
    ],
    [users]
  )

  const handleAddRow = () => {
    const _users = [...users]
    _users.push({
      id: _users.length + 1,
      Email: '',
      'First Name': '',
      'Last Name': '',
      Phone: '',
      'Job Title': ''
    })
    setUsers([..._users])
  }

  const validateRows = () => {
    if (users.length === 0) {
      toast.error('Please add atleast one row.')
      return
    }
    const isError = users.some((item) => !emailRegex.test(item.Email))
    if (isError) {
      toast.error('Please fix the rows with errors.')
    } else {
      handleUsersList(users)
    }
  }

  const onCellValueChanged = (e: any) => {
    if (e) {
      const _users = [...users]
      let _user = _users.find((item) => item.id === e.data.id)
      _user = e.data
      console.log(_user)
      setUsers([..._users])
    }
  }

  return (
    <div className={style.previewContainer}>
      <div className='d-flex align-items-center justify-content-end gap-2'>
        <ToggleButton
          name='show'
          isChecked={false}
          onChange={(event: any) => setIsToggle(event.target.checked)}
        />
        <span className={style.title}>Only show rows having errors</span>
      </div>
      <div className={style.grid}>
        <LoginPortalGrid
          rowData={usersList}
          columnDefs={columnDefs}
          defaulColDef={defaultDefCols}
          gridRef={gridRef}
          onCellValueChanged={onCellValueChanged}
        />
      </div>
      <Row className='mb-2 mt-2'>
        <Col className='d-flex'>
          <Button
            type='button'
            color='add'
            className='btn-sm'
            onClick={handleAddRow}
          >
            Add
          </Button>
        </Col>
        <Col className='d-flex flex-row-reverse gap-2'>
          <Button
            type='submit'
            color='add'
            className='btn-sm'
            onClick={validateRows}
          >
            Next
          </Button>
          <Button
            type='button'
            className='btn-sm'
            color='secondary'
            outline
            onClick={hanldePreviousStep}
          >
            Previous
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default PreviewEdit
