import { PSL } from '@digitalworkflow/psl_web'

export interface ISentEmailGetOptions {
  page?: number
  limit?: number
  filter?: {
    email?: string
    email_type?: string
    receivedDateTime?: string
    receivedDateTime_type?: string
    subject?: string
    subject_type?: string
  }
  sort?: string
  sortOrder?: string
}

/**
 * Get Blocked Emails Data from API
 *
 * @param param0
 * @returns
 */
export const getBlockedEmails = async ({
  page = 1,
  limit = 50,
  filter = {},
  sort,
  sortOrder
}: ISentEmailGetOptions = {}) => {
  const axios = await PSL.GetAxiosInstance('message')

  if (!axios) {
    throw new Error('Axios instance not found')
  }

  const params = new URLSearchParams({
    page: `${page}`,
    limit: `${limit}`,
    ...filter
  })

  if (sort) params.append('sort', sort)
  if (sortOrder) params.append('sortOrder', sortOrder)

  const url = `logs/block-emails?${params.toString()}`

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error('Error fetching blocked emails:', error)
    throw error
  }
}
