import React, { useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { LocalSettings } from '../utils/LocalSettings'

const FeatureAccessProtectedRoute = ({
  redirectPath = '/hub/profile',
  children
}: any) => {
  const location = useLocation()
  const pathName = useMemo(() => {
    return location.pathname.replace(/-/g, ' ')
  }, [location.pathname])

  const isAuthenticated = useMemo(() => {
    const userData = JSON.parse(LocalSettings.getUser() ?? '')
    return (
      userData?.features_enabled?.some((item: any) =>
        pathName.includes(
          item.feature_name.toLowerCase().replace('manage ', '')
        )
      ) ?? false
    )
  }, [LocalSettings.getUser()])

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} />
  }

  return children
}

export default FeatureAccessProtectedRoute
