import React, { useEffect, useState } from 'react'
import style from './agGridCustomFilter.module.scss'
import useDebounce from '../../hooks/useDebounce'
interface Props {
  initial_value: string
  setState: (value: string) => void
}

const agGridCustomFilter = ({ initial_value, setState }: Props) => {
  const [value, setValue] = useState(initial_value)
  const debouncedValue = useDebounce(value, 500)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (e.target.value === '') {
      setState('')
    }
  }

  useEffect(() => {
    if (debouncedValue !== '' && debouncedValue !== initial_value) {
      setState(debouncedValue)
    }
  }, [debouncedValue, setState])
  return (
    <>
      <input
        className={style.custom_filter_input}
        value={value}
        onChange={handleChange}
      />
    </>
  )
}

export default agGridCustomFilter
