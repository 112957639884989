import React, { useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { AuthService, AuthResultType } from '@digitalworkflow/dwloginclient'
import { portalName } from '../constants'
import { LocalSettings } from '../utils/LocalSettings'

const authService = AuthService.instance()

/**
 * IdleWatch component.
 *
 * @remarks
 * Log the user out if token does not exist or is invalid.
 *
 * @component IdleWatch
 * @category Component
 */
const IdleWatch = () => {
  const { logout } = useAuth()
  const location = useLocation()

  useEffect(() => {
    // Set the portal login ID on component mount
    AuthService.setPortalLoginId(portalName)
  }, [])

  const checkAuth = useCallback(async () => {
    try {
      const token = LocalSettings.getAuthToken()
      if (token) {
        const res: AuthResultType = await authService.authUserFromToken(token)
        if (res?.is_error) {
          logout()
        }
        // If no error, the user remains logged in
      } else {
        // If no token found, log the user out
        logout()
      }
    } catch (error) {
      console.error('Error checking auth:', error)
      logout()
    }
  }, [logout])

  useEffect(() => {
    // Check authentication on specific events
    checkAuth()
    const eventListener = () => checkAuth()
    document.addEventListener('click', eventListener)
    document.addEventListener('keydown', eventListener)

    return () => {
      // Clean up event listeners
      document.removeEventListener('click', eventListener)
      document.removeEventListener('keydown', eventListener)
    }
  }, [checkAuth, location.pathname])

  return <React.Fragment />
}

export default IdleWatch
