import React, { useEffect, useState } from 'react'

// eslint-disable-next-line
const CustomHeader = (props: any) => {
  const { displayName, api, fieldName } = props
  const [filterLabel, setFilterLabel] = useState('')

  const updateFilterLabel = () => {
    const _label = api.getFilterModel()?.[fieldName]?.type
    setFilterLabel(_label?.replace(/([A-Z])/g, ' $1') ?? '')
  }

  useEffect(() => {
    updateFilterLabel()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    api.addEventListener('filterChanged', updateFilterLabel)
    return () => {
      api.removeEventListener('filterChanged', updateFilterLabel)
    }
    // eslint-disable-next-line
  }, [api.getFilterModel()?.[fieldName]?.type])

  return (
    <div>
      <div>{displayName} </div>
      <div style={{ textTransform: 'capitalize' }}>
        {filterLabel ? `Filtered: ${filterLabel}` : ''}
      </div>
    </div>
  )
}

export default CustomHeader
