import { DockLayout, TabData } from '@digitalworkflow/dwreactcommon'
import React, { RefObject } from 'react'

interface TabCloseProps {
  dockLayoutRef: RefObject<DockLayout | null>
  id: string
}

const TabClose: React.FC<TabCloseProps> = ({ dockLayoutRef, id }) => {
  return (
    <div
      className='dock-tab-close-btn'
      onClick={() => {
        if (dockLayoutRef && dockLayoutRef.current) {
          const tab = dockLayoutRef.current.find(id) as TabData
          dockLayoutRef.current.dockMove(tab, null, 'remove')
        }
      }}
      title='Close'
    ></div>
  )
}

export default TabClose
