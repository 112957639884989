import React from 'react'
import { IEmail } from './Emails'
import { Button } from 'reactstrap'
import dwLogo from '../../assets/images/2.jpg'
import style from './ManageProfile.module.scss'
import moment from 'moment'

interface IEmailDetail {
  emailDetail: IEmail
  handleRemoveDetail: () => void
}

const EmailDetail = ({ emailDetail, handleRemoveDetail }: IEmailDetail) => {
  return (
    <div className='p-2'>
      <Button
        onClick={handleRemoveDetail}
        type='button'
        color='edit'
        className='btn-sm'
      >
        <i className='fa-solid fa-arrow-left me-2' />
        Back
      </Button>

      <div className='p-2 mt-2 border'>
        <h5 className='font-bold'>{emailDetail.subject}</h5>
        <div className='d-flex justify-content-between'>
          <div className='d-flex gap-2'>
            <img src={dwLogo} height={50} width={50} />
            <div className={style.emailSubject}>
              <p className={style.title}>
                Digital Workflow <i className='fa-regular fa-chevron-left' />
                dw@protovate.com
                <i className='fa-regular fa-chevron-right' />
              </p>
              <p className={style.sender}>
                {' '}
                <span className='font-semibold'>To: </span>
                {emailDetail.user_name}
              </p>
            </div>
          </div>
          <span>{moment(emailDetail.timestamp).format('LLL')}</span>
        </div>
        <div className='mt-2'>
          <div
            className={style.body}
            dangerouslySetInnerHTML={{ __html: emailDetail.body }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default EmailDetail
