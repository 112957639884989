/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

import FormikInput from '../Formik/FormikInput/formikInput'
import FormikSelect from '../Formik/formikSelect'

import style from './style.module.scss'
import FormikDateInput from '../Formik/FormikDateInput/FormikDateInput'
import MemoInput from '../MemoInput/MemoInput'
import {
  AuthService,
  SystemMessageService,
  SystemMessageType,
  UserService
} from '@digitalworkflow/dwloginclient'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { toast } from 'react-toastify'
import AutoComplete from '../AutoCompleteInput/AutoComplete'

const authService = AuthService.instance()
SystemMessageService.setAuthServiceInstance(authService)
UserService.setAuthServiceInstance(authService)
const systemMessageService = new SystemMessageService()
const userService = new UserService()

const isNotEmpty = (value: string) => value.trim().length > 0

const validationSchema = Yup.object().shape({
  message_title: Yup.string()
    .required('Message title is required')
    .test(
      'no-only-spaces',
      'Message title cannot consist of only spaces',
      isNotEmpty
    ),
  message_language: Yup.string().required('Message language is required'),
  username_match: Yup.string(),
  message_date: Yup.date().required('Message date is required'),
  message_text: Yup.string().required('Message text is required'),
  block_access: Yup.date().required('Block access date is required'),
  expires: Yup.date().required('Message expire date is required')
})

interface FormValue {
  message_title: string
  message_language: string
  username_match: string
  message_date: Date | null
  block_access: Date | null
  expires: Date | null
  message_text: string
}

const initialValues: FormValue = {
  message_title: '',
  message_language: '',
  username_match: '',
  message_date: null,
  block_access: null,
  expires: null,
  message_text: ''
}

const languageDropdown = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' }
]
interface Props {
  message?: SystemMessageType
  handleNewMessage: (isEdit: boolean) => void
}
const AddEditSystemMessage = ({ message, handleNewMessage }: Props) => {
  const [usersNames, setUsersNames] = useState<string[]>([])
  const [searchedUser, setSearchedUser] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchUsers = setTimeout(async () => {
      if (searchedUser) {
        setIsLoading(true)
        const result = await userService.getAllUser({ name: searchedUser })
        if (result && !result.is_error) {
          const options =
            result.data?.map(
              (user) => user.first_name + ' ' + user.last_name
            ) ?? []
          setUsersNames(options)
        }
        setIsLoading(false)
      } else {
        setUsersNames([])
      }
    }, 1000)

    return () => clearTimeout(fetchUsers)
  }, [searchedUser])

  const messageLanguage: string = useMemo(() => {
    return message?.message_title.en ? 'en' : 'fr'
  }, [message])
  const initValue = useMemo((): FormValue => {
    return message === undefined
      ? initialValues
      : {
          ...message,
          message_title: message.message_title[messageLanguage],
          message_language: messageLanguage,
          message_text: message.message_text[messageLanguage]
        }
  }, [message])

  const handleAddEditMessage = useCallback(
    async (_values: FormValue) => {
      const language = _values.message_language === 'en'

      const postObject = {
        message_title: language
          ? [{ language: 'en', value: _values.message_title }]
          : [{ language: 'fr', value: _values.message_title }],
        message_text: language
          ? [{ language: 'en', value: _values.message_text }]
          : [{ language: 'fr', value: _values.message_text }],
        username_match: _values.username_match,
        message_date:
          _values.message_date !== null ? _values.message_date : undefined,
        block_access:
          _values.block_access !== null ? _values.block_access : undefined,
        expires: _values.expires !== null ? _values.expires : undefined
      }

      let _result: ApiResultType<SystemMessageType>
      if (message) {
        _result = await systemMessageService.updateById(
          message.id ?? '',
          postObject
        )
      } else {
        _result = await systemMessageService.create(postObject)
      }

      if (!_result.is_error) {
        toast.success('Record added successfully!')
        handleNewMessage && handleNewMessage(message !== undefined)
      } else {
        toast.error(_result.message)
      }
    },
    [message]
  )
  return (
    <>
      <div className={`p-2 ${style.formContainer}`}>
        <Formik
          enableReinitialize
          initialValues={initValue}
          validationSchema={validationSchema}
          onSubmit={handleAddEditMessage}
        >
          {({
            resetForm,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldTouched,
            setFieldValue
          }) => {
            const handleCancel = () => {
              resetForm()
            }

            return (
              <>
                <form onSubmit={handleSubmit} className={style.form}>
                  <FormikInput
                    placeholder='Message Title'
                    label='Message Title'
                    name='message_title'
                  />
                  <FormikSelect
                    options={languageDropdown}
                    name='message_language'
                    placeholder='Select Language'
                    label='Message Language'
                  />
                  <div>
                    <MemoInput
                      value={values.message_text}
                      setValue={(value: string) => {
                        setFieldValue('message_text', value)
                      }}
                      onBlur={() => {
                        setFieldTouched('message_text', true)
                      }}
                      label='Message Text'
                    />
                    <div>
                      {errors.message_text && touched.message_text && (
                        <p className='is-invalid'>
                          {errors.message_text as string}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* <FormikInput
                    placeholder='Username Match'
                    label='Username Match'
                    name='username_match'
                  /> */}
                  <AutoComplete
                    options={usersNames}
                    isLoading={isLoading}
                    placeholder='Username Match'
                    label='Username Match'
                    name='username_match'
                    onChange={(value) => setSearchedUser(value)}
                  />
                  <FormikDateInput
                    name='message_date'
                    label='Message Date'
                    placeholder='Message Date'
                  />
                  <FormikDateInput
                    name='block_access'
                    label='Block Access Date'
                    placeholder='Block Access Date'
                    showTimeSelect
                    dateFormat='MMMM d, yyyy h:mm aa'
                  />
                  <FormikDateInput
                    name='expires'
                    label='Message Expires On'
                    placeholder='Message Expires On'
                    showTimeSelect
                    dateFormat='MMMM d, yyyy h:mm aa'
                  />
                  <Row className='mb-2 mt-1'>
                    <Col className='d-flex gap-2'>
                      <Button type='submit' className='btn-sm' color='add'>
                        Save
                      </Button>
                      <Button
                        type='button'
                        color='cancel'
                        className='btn-sm'
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default AddEditSystemMessage
