import React from 'react'
import { IFactoryResponseUserListType } from './types'
import LoginPortalGrid from '../../components/LoginPortalGrid/LoginPortalGrid'

const columnDefs = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    unSortIcon: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    minWidth: 140,
    flex: 2,
    floatingFilter: true,
    valueGetter: (params: any) =>
      params.data.first_name + ' ' + params.data.last_name,
    comparator: (a: any, b: any) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    unSortIcon: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    minWidth: 140,
    flex: 2,
    floatingFilter: true
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    unSortIcon: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    minWidth: 140,
    flex: 1,
    floatingFilter: true,
    cellRenderer: (params: any) =>
      params.data.status ? (
        <span
          className={`tag ${
            params.data?.status?.toLowerCase() === 'success'
              ? 'success'
              : 'failed'
          }`}
        >
          {params.data.status}
        </span>
      ) : (
        <></>
      )
  }
]
interface IExceptionList {
  exceptionList: IFactoryResponseUserListType[]
}
const ExceptionList = ({ exceptionList }: IExceptionList) => {
  return (
    <div style={{ height: '100%' }}>
      <LoginPortalGrid columnDefs={columnDefs} rowData={exceptionList} />
    </div>
  )
}
export default ExceptionList
