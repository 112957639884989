import { PSL } from '@digitalworkflow/psl_web'

export const getCronJobs = async (
  size: number,
  from: number,
  filter: string = '',
  sort: string = ''
) => {
  const axios = await PSL.GetAxiosInstance('search')

  if (!axios) {
    throw new Error('Axios instance not found')
  }
  const url = `cron-history/search`

  const data = {
    size: size,
    from: from,
    ...(filter ? { filter } : {}),
    ...(sort ? { sort } : {})
  }

  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error('Error fetching cron jobs:', error)
    throw error
  }
}
