import { FeatureType, PermissionType } from '@digitalworkflow/dwloginclient'
import { LocalSettings } from './LocalSettings'
import { checkSuperAdmin } from './checkAdmin'

export const userPermissionOfReadOnly = (featureName: string) => {
  const userData = JSON.parse(LocalSettings.getUser() ?? '')
  if (checkSuperAdmin()) {
    return false
  }
  if (
    !userData ||
    !Array.isArray(userData.features_enabled) ||
    !Array.isArray(userData.permissions)
  ) {
    return false
  }
  const userFeaturesIds: string[] = userData.features_enabled
    .filter((feature: FeatureType) => feature.feature_name === featureName)
    .map((feature: FeatureType) => feature.id)

  if (
    userData &&
    userData.permissions.some(
      (permission: PermissionType) =>
        userFeaturesIds.includes(permission.feature_id) &&
        permission.access_level === 'read'
    )
  ) {
    return true
  } else {
    return false
  }
}
