import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'

import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import style from './memoInput.module.scss'

/**
 * Converts editorState to HTML format.
 *
 * @param editorState - Draft content state
 * @returns Converted raw draft state to HTML format
 */
const getHtml = (editorState: any) =>
  draftToHtml(convertToRaw(editorState.getCurrentContent()))

/**
 * Check the text of the editor.
 *
 * @param editorState - Draft content state
 * @returns Text of the editor.
 */
const checkValue = (editorState: any) =>
  convertToRaw(editorState.getCurrentContent()).blocks[0].text.trim()

/**
 * MemoInput component
 *
 * @component MemoInput
 * @category FormElements
 */
interface IMemoInput {
  value: string
  setValue: (e: string) => void
  onBlur: () => void
  label?: string
}

const MemoInput = ({ value, setValue, onBlur, label }: IMemoInput) => {
  const [editorState, setEditorState] = useState<EditorState>()

  useEffect(() => {
    const contentBlock = convertFromHTML(value)
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )
    setEditorState(EditorState.createWithContent(contentState))
  }, [])

  useEffect(() => {
    if (!value && editorState) {
      setEditorState(undefined)
    }
  }, [value])

  /**
   * Used to change the value of a field.
   *
   * @param e - Used to set the value.
   * @returns Void
   */
  const handleChangeMemo = (e: EditorState) => {
    if (!checkValue(e)) {
      setValue('')
    } else {
      const value = getHtml(e)
      setValue(value)
    }
    setEditorState(e)
  }

  return (
    <div>
      <label className='form-label'>{label}</label>
      <Editor
        toolbarClassName={style.toolbar}
        wrapperClassName='memo-wrapper'
        editorClassName={style.editor}
        toolbar={{
          options: ['inline', 'list', 'textAlign'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline']
          },
          list: { inDropdown: false },
          textAlign: { inDropdown: false }
        }}
        editorState={editorState}
        onEditorStateChange={(e: EditorState) => {
          handleChangeMemo(e)
        }}
        onBlur={onBlur}
      />
    </div>
  )
}

export default MemoInput
