import React, { useCallback, useMemo, useRef } from 'react'
import { Button } from 'reactstrap'
import { RCDockLayout, DockLayout } from '@digitalworkflow/dwreactcommon'
import { RcGroup } from '../../components/RcGroup'
import { getSize } from '../../utils/getSize'
import SystemMessageList from '../../components/SystemMessageList/SystemMessageList'
import { addFloatBox, getTab, removeRCTab } from '../../lib/rcDock-lib'
import AddEditSystemMessage from '../../components/AddEditSystemMessage/AddEditSystemMessage'
import { SystemMessageType } from '@digitalworkflow/dwloginclient'
import { LocalSettings } from '../../utils/LocalSettings'
import { userPermissionOfReadOnly } from '../../utils/userPermissions'

const SystemMessages = () => {
  const dockLayoutRef = useRef<DockLayout | null>(null)
  const messagesListRef = useRef<any>(null)

  const isReadOnlyAccess = useMemo(() => {
    return userPermissionOfReadOnly('Manage System Messages')
  }, [LocalSettings.dwhub_user])

  // const handleNewPortal = useCallback(() => {
  //   removeRCTab(dockLayoutRef, 'addPortal')
  //   portalListRef.current.getAllPortals()
  // }, [dockLayoutRef, portalListRef])

  const handleAddSystemMessage = () => {
    const id = 'addMessage'
    addFloatBox(
      dockLayoutRef,
      id,
      getTab(
        id,
        'Add System Message',
        dockLayoutRef,
        () => <AddEditSystemMessage handleNewMessage={handleNewMessage} />,
        600,
        200,
        550,
        0
      )
    )
  }
  const handleNewMessage = useCallback(
    (isEdit: boolean) => {
      if (isEdit) removeRCTab(dockLayoutRef, 'editMessage')
      else removeRCTab(dockLayoutRef, 'addMessage')
      messagesListRef.current.getAllMessages()
    },
    [dockLayoutRef, messagesListRef]
  )

  const handleEditMessage = (value: SystemMessageType) => {
    const id = 'editMessage'
    addFloatBox(
      dockLayoutRef,
      id,
      getTab(
        id,
        'Edit System Message',
        dockLayoutRef,
        () => (
          <AddEditSystemMessage
            message={value}
            handleNewMessage={handleNewMessage}
          />
        ),
        510
      )
    )
  }
  const systemMessagesListTab = {
    id: 'systemMessageList',
    title: 'System Messages',
    content: (
      <SystemMessageList
        ref={messagesListRef}
        isReadOnlyAccess={isReadOnlyAccess}
        handleEditMessage={handleEditMessage}
      />
    ),
    group: 'close-all'
  }

  const defaultLayout: any = {
    dockbox: {
      mode: 'vertical',
      children: [
        {
          size: getSize(1600, 700),
          mode: 'vertical',
          children: [
            {
              tabs: [
                {
                  id: 'description',
                  title: 'System Message Management',
                  content: (
                    <div className='p-2'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h6>System Messages</h6>
                        <div>
                          <Button
                            type='button'
                            color='add'
                            className='btn-sm mb-1'
                            disabled={isReadOnlyAccess}
                            onClick={handleAddSystemMessage}
                          >
                            + Add System Message
                          </Button>
                        </div>
                      </div>
                      <p className='descripton'>
                        This page displays list of messages configured for
                        various system level actions. You can also perform tasks
                        such as add, update, changing the status enable/disable
                        for the messages.
                      </p>
                    </div>
                  ),
                  group: 'headless',
                  size: 'auto'
                }
              ]
            }
          ]
        },
        {
          mode: 'horizontal',
          children: [
            {
              mode: 'horizontal',
              children: [
                {
                  id: 'emailTemplatesSection',
                  tabs: [systemMessagesListTab],
                  group: 'close-all'
                }
              ]
            }
          ]
        }
      ]
    },
    floatbox: {
      children: []
    }
  }

  return (
    <RCDockLayout
      dockLayoutRef={dockLayoutRef}
      defaultLayout={defaultLayout}
      groups={RcGroup}
    />
  )
}

export default SystemMessages
