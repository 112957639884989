import { RoleType, WorkgroupType } from '@digitalworkflow/dwloginclient'

export const sortArray = (array: any[], key: any) => {
  let _sortedArray = [...array]
  _sortedArray = _sortedArray.sort((a: any, b: any) => {
    if (a[key] && b[key]) {
      const valueA = a[key]
        .trim()
        .toLowerCase()
        .replace(/[^\p{L}\p{N}]/gu, '')
      const valueB = b[key]
        .trim()
        .toLowerCase()
        .replace(/[^\p{L}\p{N}]/gu, '')

      if (valueA < valueB) {
        return -1
      } else if (valueA > valueB) {
        return 1
      }
    }
    return 0
  })

  return _sortedArray
}
// Sorting the array bv "Major :: Minor :: Section :: Part"
export const sortArrayByEveryPart =
  (key: string | null) =>
  (
    a: WorkgroupType | RoleType | string,
    b: WorkgroupType | RoleType | string
  ) => {
    const parseValues = (str: string | undefined): (string | number)[] => {
      if (typeof str !== 'string') {
        return []
      }
      return str
        .split('::')
        .map((value) =>
          isNaN(Number(value)) ? value.trim() : Number(value.toLowerCase())
        )
    }
    const [aMajor, aMinor, aSection, aPart] = parseValues(key ? a[key] : a)
    const [bMajor, bMinor, bSection, bPart] = parseValues(key ? b[key] : b)

    // Custom comparison logic for each level
    if (aMajor !== bMajor) {
      return aMajor < bMajor ? -1 : 1
    }

    if (aMinor !== bMinor) {
      return aMinor < bMinor ? -1 : 1
    }

    if (aSection !== bSection) {
      return aSection < bSection ? -1 : 1
    }

    if (aPart !== bPart) {
      return aPart < bPart ? -1 : 1
    }

    return 0
  }
// Custom sort comparator for AgGrid that removes leading spaces and converts values to lowercase for comparison.
export const sortComparator = (key: string | null) => (a: any, b: any) => {
  if (key) {
    const valueA = a[key]
      .trim()
      .toLowerCase()
      .replace(/[^\p{L}\p{N}]/gu, '')
    const valueB = b[key]
      .trim()
      .toLowerCase()
      .replace(/[^\p{L}\p{N}]/gu, '')

    if (valueA < valueB) {
      return -1
    } else if (valueA > valueB) {
      return 1
    } else {
      return 0
    }
  } else {
    const valueA = a
      .trim()
      .toLowerCase()
      .replace(/[^\p{L}\p{N}]/gu, '')
    const valueB = b
      .trim()
      .toLowerCase()
      .replace(/[^\p{L}\p{N}]/gu, '')

    if (valueA < valueB) {
      return -1
    } else if (valueA > valueB) {
      return 1
    } else {
      return 0
    }
  }
}
