import React, { useMemo } from 'react'
import { useField } from 'formik'
import DateInput from '../../DateInput/DateInput'

interface IFormikDateInput {
  name: string
  label?: string
  className?: string
  placeholder?: string
  showTimeSelect?: boolean
  dateFormat?: string
}

const FormikDateInput = ({
  name,
  className,
  label,
  placeholder,
  showTimeSelect = false,
  dateFormat
}: IFormikDateInput) => {
  const [field, _meta, _helpers] = useField(name)

  const value = useMemo(() => {
    const _value = field.value ? new Date(field.value) : null
    return _value
  }, [field.value])

  return (
    <div className={className}>
      <span className='form-label'>{label}</span>
      <DateInput
        selectedDate={value}
        onChange={(value: Date) => {
          _helpers.setTouched(true)
          _helpers.setValue(value)
        }}
        showTimeSelect={showTimeSelect}
        placeholder={placeholder}
        dateFormat={dateFormat}
      />

      {_meta.error && _meta.touched && (
        <p className='is-invalid'>{_meta.error}</p>
      )}
    </div>
  )
}
export default FormikDateInput
