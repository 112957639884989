/* eslint-disable camelcase */
import React, { RefObject, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import '../../style/roleContianer.scss'
import { RoleType, WorkgroupType } from '@digitalworkflow/dwloginclient'
import { Button, Tooltip, Spinner } from 'reactstrap'
import { exportData } from '../../utils/exportData'
import { DockLayout, TabData } from '@digitalworkflow/dwreactcommon'
import { BoxData } from 'rc-dock'
interface IWorkgroupRole {
  isAllowedToModify: boolean
  activeWorkgroupRole: WorkgroupType | RoleType | null
  handleEditWorkgroupRole: () => void
  handleAddUserFloatBox?: () => void
  editButtonText: string
  addUsersButtonText?: string | ''
  manageUserAccess?: boolean
  isReadOnlyAccess: boolean
  isWorkgroup?: boolean
  isLoading: boolean
  dockLayoutRef: RefObject<DockLayout | null>
}

const WorkgroupRoleDetails = ({
  isAllowedToModify,
  activeWorkgroupRole,
  handleEditWorkgroupRole,
  handleAddUserFloatBox,
  editButtonText,
  manageUserAccess,
  isWorkgroup,
  addUsersButtonText,
  isReadOnlyAccess,
  dockLayoutRef,
  isLoading
}: IWorkgroupRole) => {
  const location = useLocation()
  const [nameTooltipOpen, setNameTooltipOpen] = useState<boolean>(false)
  const [approverNameTooltipOpen, setApproverNameTooltipOpen] =
    useState<boolean>(false)
  const [descriptionTooltipOpen, setDescriptionTooltipOpen] =
    useState<boolean>(false)
  const [isWrap, setIsWrap] = useState(false)

  const nametoggle = () => {
    const nameTooltip = document.querySelector('#nameTooltip') as HTMLElement
    if (nameTooltip && nameTooltip.scrollWidth > nameTooltip.clientWidth)
      setNameTooltipOpen(!nameTooltipOpen)
  }
  const approverNameToggle = () => {
    const approverNameTooltip = document.querySelector(
      '#approverNameTooltip'
    ) as HTMLElement
    if (
      approverNameTooltip &&
      approverNameTooltip.scrollWidth > approverNameTooltip.clientWidth
    )
      setApproverNameTooltipOpen(!approverNameTooltipOpen)
  }
  const descriptionToggle = () => {
    const descriptionTooltip = document.querySelector(
      '#descriptionTooltip'
    ) as HTMLElement
    if (
      descriptionTooltip &&
      descriptionTooltip.scrollWidth > descriptionTooltip.clientWidth
    )
      setDescriptionTooltipOpen(!descriptionTooltipOpen)
  }

  useEffect(() => {
    const workgroupDetail = document.querySelector('.wr-container')
    const roleDetailContainer = document.querySelector('.wr-container')

    const handleWorkgroupDetailResize = () => {
      if (!workgroupDetail) {
        return
      }
      if (workgroupDetail.clientWidth < 790) {
        setIsWrap(true)
      } else {
        setIsWrap(false)
      }
      const tab = dockLayoutRef.current?.find('workgroup_detail') as TabData
      if (tab && workgroupDetail.clientHeight >= 104) {
        const updatedTab = { ...tab, minHeight: 160 }
        dockLayoutRef.current?.updateTab('workgroup_detail', updatedTab)
      } else {
        const updatedTab = { ...tab, minHeight: 118 }
        dockLayoutRef.current?.updateTab('workgroup_detail', updatedTab)
      }
    }

    // useEffect(() => {
    //   // This effect will run whenever isAllowedToModify changes
    //   // You can perform any logic here that should happen when the prop updates
    //   // For now, I'm just logging a message
    //   console.log('isAllowedToModify prop updated:', isAllowedToModify)
    // }, [isAllowedToModify])

    const handleroleDetailContainerResize = () => {
      if (!roleDetailContainer) {
        return
      }
      if (roleDetailContainer.clientWidth < 790) {
        setIsWrap(true)
      } else {
        setIsWrap(false)
      }
      const child1 = dockLayoutRef.current?.state.layout.dockbox
        .children[1] as BoxData
      const child2 = child1.children[1] as BoxData
      const { activeId } = child2.children[0] as { activeId: string }

      if (activeId === 'role_detail') {
        const tab = dockLayoutRef.current?.find('role_detail') as TabData
        if (tab && roleDetailContainer.clientHeight >= 104) {
          const updatedTab = { ...tab, minHeight: 160 }
          dockLayoutRef.current?.updateTab('role_detail', updatedTab)
        } else {
          const updatedTab = { ...tab, minHeight: 118 }
          dockLayoutRef.current?.updateTab('role_detail', updatedTab)
        }
      }
    }

    const workgroupDetailObserver = new ResizeObserver(
      handleWorkgroupDetailResize
    )
    const roleDetailContainerObserver = new ResizeObserver(
      handleroleDetailContainerResize
    )
    workgroupDetail && workgroupDetailObserver.observe(workgroupDetail)
    roleDetailContainer &&
      roleDetailContainerObserver.observe(roleDetailContainer)

    handleWorkgroupDetailResize()
    handleroleDetailContainerResize()

    return () => {
      workgroupDetail && workgroupDetailObserver.unobserve(workgroupDetail)
      roleDetailContainer &&
        roleDetailContainerObserver.unobserve(roleDetailContainer)
    }
  }, [])

  return (
    <div className='workgroup-container'>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div className='wr-container'>
          <div
            className={`flex justify-between header ${
              isWrap ? 'flex-column' : ''
            }`}
          >
            <div className='truncate'>
              <div className='flex items-center headerText'>
                {location.pathname.includes('workgroup') ? (
                  <span className='role-name'>Workgroup Name :</span>
                ) : (
                  <span className='role-name'>Role Name :</span>
                )}
                <p className='role-value' id='nameTooltip'>
                  {(activeWorkgroupRole as RoleType)?.role_name ||
                    (activeWorkgroupRole as WorkgroupType)?.work_group_name}
                </p>
                <Tooltip
                  isOpen={nameTooltipOpen}
                  target='nameTooltip'
                  toggle={nametoggle}
                >
                  {(activeWorkgroupRole as RoleType)?.role_name ||
                    (activeWorkgroupRole as WorkgroupType)?.work_group_name}
                </Tooltip>
              </div>
              <div className='flex items-center headerText'>
                {isWorkgroup ? (
                  <span className='role-name'>Approver Workgroup Name :</span>
                ) : (
                  <span className='role-name'>Approver Role Name :</span>
                )}
                <p className='role-value' id='approverNameTooltip'>
                  {(activeWorkgroupRole as RoleType)
                    ?.formatted_approver_group_name ||
                    (activeWorkgroupRole as any)
                      ?.formated_approver_group_name ||
                    'Self'}
                </p>
                <Tooltip
                  isOpen={approverNameTooltipOpen}
                  target='approverNameTooltip'
                  toggle={approverNameToggle}
                >
                  {(activeWorkgroupRole as RoleType)
                    ?.formatted_approver_group_name ||
                    (activeWorkgroupRole as any)
                      ?.formated_approver_group_name ||
                    'Self'}
                </Tooltip>
              </div>
              <div className='flex items-center headerText'>
                <span className='role-name'>Description:</span>
                <p className='role-value' id='descriptionTooltip'>
                  {activeWorkgroupRole?.description}
                </p>
                <Tooltip
                  isOpen={descriptionTooltipOpen}
                  target='descriptionTooltip'
                  toggle={descriptionToggle}
                >
                  {activeWorkgroupRole?.description}
                </Tooltip>
              </div>
            </div>
            <div>
              <div className='flex buttons text-nowrap'>
                {isAllowedToModify && (
                  <React.Fragment>
                    <Button
                      className='btn-sm border-0'
                      color='edit'
                      disabled={isReadOnlyAccess}
                      onClick={() => handleEditWorkgroupRole()}
                    >
                      <i className='fas fa-edit icon me-2' />
                      {editButtonText}
                    </Button>
                  </React.Fragment>
                )}
                {manageUserAccess && (
                  <Button
                    className='btn-sm border-0'
                    color='edit'
                    disabled={isReadOnlyAccess}
                    onClick={() =>
                      handleAddUserFloatBox && handleAddUserFloatBox()
                    }
                  >
                    <i className='fas fa-edit icon me-2' />
                    {addUsersButtonText}
                  </Button>
                )}
                {isWorkgroup ? (
                  <Button
                    color='light'
                    className='btn-sm button'
                    onClick={() =>
                      exportData(
                        `workgroups/exportMembers/${activeWorkgroupRole?.id}`,
                        'workgroups-members'
                      )
                    }
                  >
                    <i className='fa fa-download icon' />
                    Export Members of this Workgroup
                  </Button>
                ) : (
                  <>
                    <Button
                      color='light'
                      className='btn-sm button'
                      onClick={() =>
                        exportData(
                          `roles/exportRoleWorkgroups/${activeWorkgroupRole?.id}`,
                          'role-workgroup'
                        )
                      }
                    >
                      <i className='fa fa-download icon' />
                      Export workgroups of this role
                    </Button>
                    <Button
                      color='light'
                      className='btn-sm button'
                      onClick={() =>
                        exportData(
                          `roles/exportRoleMembers/${activeWorkgroupRole?.id}`,
                          'role-members'
                        )
                      }
                    >
                      <i className='fa fa-download icon' />
                      Export Members
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default WorkgroupRoleDetails
