import React from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface IDateInput {
  className?: string
  onChange: (e: Date) => void
  selectedDate: Date | null
  placeholder?: string
  showTimeSelect?: boolean
  dateFormat?: string
}

const DateInput = ({
  className,
  selectedDate,
  placeholder = '',
  showTimeSelect = false,
  dateFormat = 'MMMM d, yyyy',
  onChange
}: IDateInput) => {
  const onCalendarOpen = () => {
    requestAnimationFrame(() => {
      const picker = document.querySelector(
        '.react-datepicker-popper'
      ) as HTMLElement
      picker.tabIndex = -1
      picker.focus()
      const transform = picker.style.transform
      const numbers = transform.match(/\d+/g)
      if (numbers) {
        picker.style.marginLeft = `${60 - parseInt(numbers[0])}px`
      }
    })
  }

  return (
    <div className={className}>
      <DatePicker
        showTimeSelect={showTimeSelect}
        selected={selectedDate}
        onChange={onChange}
        onCalendarOpen={onCalendarOpen}
        placeholderText={placeholder}
        className='form-control'
        timeIntervals={10}
        dateFormat={dateFormat}
      />
    </div>
  )
}
export default DateInput
