import React, { InputHTMLAttributes, memo } from 'react'

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  inputClassName?: string
  labelClassName?: string
  placeholder?: string
  label?: string
  note?: string
  name: string
  error?: string
  type?: string
  shadow?: boolean
  rightIcon?: React.ReactNode
  touched?: boolean
  containerClass?: string
  variant?: 'normal' | 'solid' | 'outline' | 'base'
  readonly?: boolean
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      label,
      touched,
      note,
      name,
      error,
      variant = 'normal',
      shadow = false,
      type = 'text',
      inputClassName,
      labelClassName,
      containerClass,
      rightIcon,
      readOnly,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={className}>
        {label && (
          <label htmlFor={name} className={`form-label ${labelClassName}`}>
            {label}
          </label>
        )}
        <div className={`relative d-flex align-items-center ${containerClass}`}>
          <input
            id={name}
            name={name}
            type={type}
            ref={ref}
            className={`form-control ${
              error && touched ? 'invalid-feedback' : ''
            } ${readOnly ? 'bg-gray-200 focus:border' : ''}`}
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            spellCheck='false'
            aria-invalid={error ? 'true' : 'false'}
            readOnly={readOnly}
            {...rest}
          />
          <div className='absolute right-3'>{rightIcon}</div>
        </div>
        {note && <p className='mt-2 text-xs text-body'>{note}</p>}
        {touched && error && <p className='is-invalid'>{error}</p>}
      </div>
    )
  }
)

export default memo(Input)
