import React, { useEffect } from 'react'

const DockPlaceholder = () => {
  useEffect(() => {
    const eventListener = () => {
      requestAnimationFrame(() => {
        // Find all elements with the data attribute 'data-dockid="-maximized-placeholder-"'
        const elements = Array.from(
          document.querySelectorAll('[data-dockid="-maximized-placeholder-"]')
        ) as HTMLElement[]

        // Hide the elements
        elements.forEach((element: HTMLElement) => {
          if (element) {
            element.style.display = 'none'
          }
        })
      })
    }

    // Add event listeners
    document.addEventListener('click', eventListener)
    document.addEventListener('keydown', eventListener)

    return () => {
      // Clean up event listeners
      document.removeEventListener('click', eventListener)
      document.removeEventListener('keydown', eventListener)
    }
  }, [])

  return <React.Fragment />
}

export default DockPlaceholder
