import React, { useCallback, useMemo, useRef } from 'react'
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
  GridReadyEvent,
  IGetRowsParams
} from 'ag-grid-community'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import Badge from '../Badge/Badge'
import { convertStrToLocalDate } from '../../utils/convertStrToLocalDate'
import TableCellJson from './tableCellJson'
import CustomHeader from './customHeader'
import { getCronJobs } from '../../utils/getCronJobs'
import moment from 'moment'
import CustomDateFilter from '../Aggrid/CustomDateFilter'

const pageSize = 100
const CronJobHistoryList = () => {
  const gridRef = useRef<any>(null)

  const columnDefs = useMemo((): ColDef[] => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        unSortIcon: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        field: 'description',
        headerName: 'Description',
        sortable: true,
        unSortIcon: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          maxNumConditions: 1
        },
        floatingFilter: true
      },
      {
        field: 'status',
        headerName: 'Status',
        cellRenderer: (params: ICellRendererParams) => {
          return <Badge status={params?.value} />
        }
      },
      {
        field: 'startedAt',
        headerName: 'Started At',
        sortable: true,
        unSortIcon: true,
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        floatingFilterComponent: CustomDateFilter,
        filterParams: {
          maxNumConditions: 1,
          filterOptions: ['equals']
        },
        floatingFilterComponentParams: (props: any) => ({
          ...props,
          maxValidDate: new Date().toISOString().split('T')[0]
        }),
        valueFormatter: (params: ValueFormatterParams) => {
          return convertStrToLocalDate(
            params?.data?.startedAt,
            new Date().getTimezoneOffset()
          )
        },
        valueGetter: (params: ValueGetterParams) => {
          return convertStrToLocalDate(
            params?.data?.startedAt,
            new Date().getTimezoneOffset()
          )
        },
        // eslint-disable-next-line
        headerComponent: (props: any) => (
          <CustomHeader fieldName='startedAt' {...props} />
        )
      },
      {
        field: 'endedAt',
        headerName: 'Ended At',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        floatingFilterComponent: CustomDateFilter,
        filterParams: {
          maxNumConditions: 1,
          filterOptions: ['equals']
        },
        floatingFilterComponentParams: (props: any) => ({
          ...props,
          maxValidDate: new Date().toISOString().split('T')[0]
        }),
        valueFormatter: (params: ValueFormatterParams) => {
          return convertStrToLocalDate(
            params?.data?.endedAt,
            new Date().getTimezoneOffset()
          )
        },
        valueGetter: (params: ValueGetterParams) => {
          return convertStrToLocalDate(
            params?.data?.endedAt,
            new Date().getTimezoneOffset()
          )
        },
        // eslint-disable-next-line
        headerComponent: (props: any) => (
          <CustomHeader fieldName='endedAt' {...props} />
        )
      },
      {
        field: 'output',
        headerName: 'Output',
        cellRenderer: (params: ICellRendererParams) => {
          return params?.data?.name ? (
            <TableCellJson value={params?.value}></TableCellJson>
          ) : null
        },
        minWidth: 130
      }
    ]
  }, [gridRef.current])

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 90,
      resizable: true
    }
  }, [])

  const getOperator = useCallback((type: string) => {
    let operator = '%'
    switch (type) {
      case 'notContains':
        operator = '!%'
        break
      case 'equals':
        operator = '=='
        break
      case 'contains':
        operator = '%'
        break
      case 'notEqual':
        operator = '!='
        break
      case 'startsWith':
        operator = '^'
        break
      case 'endsWith':
        operator = '!^'
        break
      case 'blank':
        operator = '=='
        break
      default:
        break
    }
    return operator
  }, [])

  const getCronJobsList = useCallback(async (params: IGetRowsParams) => {
    try {
      const { description, name, startedAt, endedAt } =
        gridRef.current.api.getFilterModel()

      const filters = []
      if (name) {
        const operator = getOperator(name.type)
        filters.push(`name ${operator} '${name?.filter ?? ''}'`)
      }

      if (description) {
        const operator = getOperator(description.type)
        filters.push(`description ${operator} '${description?.filter ?? ''}'`)
      }

      if (startedAt) {
        const mStartedAt = moment(startedAt.dateFrom)
        filters.push(
          `startedAt>='${mStartedAt.clone().startOf('D').toISOString()}'`
        )
        filters.push(
          `startedAt<'${mStartedAt.clone().endOf('D').toISOString()}'`
        )
      }
      if (endedAt) {
        const mStartedAt = moment(endedAt.dateFrom)
        filters.push(
          `endedAt>='${mStartedAt.clone().startOf('D').toISOString()}'`
        )
        filters.push(`endedAt<'${mStartedAt.clone().endOf('D').toISOString()}'`)
      }

      const sortColumn = gridRef.current.columnApi
        ?.getColumnState()
        ?.find((item: any) => item.sort)

      let _sort = '-startedAt'
      if (sortColumn) {
        _sort = `${sortColumn?.sort === 'desc' ? '-' : ''}${sortColumn?.colId}`
      }

      gridRef.current.api.showLoadingOverlay()
      const res = await getCronJobs(
        pageSize,
        params.startRow,
        filters.join('&&'),
        _sort
      )

      const lastRow =
        res.data.length < pageSize ? params.startRow + res.data.length : -1
      params.successCallback(res?.data ?? [], lastRow)
      if (res.data.length === 0) {
        gridRef.current.api.showNoRowsOverlay()
      } else {
        gridRef.current.api.hideOverlay()
      }
    } catch (error) {
      gridRef.current.api.showNoRowsOverlay()
      params.failCallback()
    }
  }, [])

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource = {
        rowCount: undefined,
        getRows: getCronJobsList
      }

      params.api.setDatasource(dataSource)
    },
    [getCronJobsList]
  )

  return (
    <div style={{ height: 'calc(100vh - 200px)' }}>
      <LoginPortalGrid
        gridRef={gridRef}
        columnDefs={columnDefs}
        defaulColDef={defaultColDef}
        rowModelType='infinite'
        cacheBlockSize={pageSize}
        cacheOverflowSize={2}
        maxConcurrentDatasourceRequests={1}
        infiniteInitialRowCount={0}
        maxBlocksInCache={10}
        onGridReady={onGridReady}
        pagination={false}
      />
    </div>
  )
}

export default CronJobHistoryList
