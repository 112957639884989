import React from 'react'
import { DWAutoCompleteInput } from '@digitalworkflow/dwreactcommon'

const DWAutocompletePage = () => {
  const handleChange = (_e: any) => {}

  return (
    <div>
      <DWAutoCompleteInput
        filter='City=Rockland and Client_Id=151'
        optionValue='{{POSTAL}}'
        optionText='{{POSTAL}}'
        onChangeValue={handleChange}
      />
    </div>
  )
}

export default DWAutocompletePage
