import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import {
  TaskView,
  TaskButtonProps,
  TaskListRowProps
} from '@digitalworkflow/dwreactcommon'

export const buttonList: TaskButtonProps[] = [
  {
    title: 'IOT: Room Above Temperature',
    variant: 'primary',
    onClick: () => console.log('^^^^^^^'),
    properties: [
      {
        property_name: 'Building ID',
        property_value: 'TD0030',
        property_format: ''
      },
      {
        property_name: 'Sender ID',
        property_value: 'W2312',
        property_format: ''
      },
      {
        property_name: 'Last Reading',
        property_value: '4:14am Today',
        property_format: ''
      },
      {
        property_name: 'Reading',
        property_value: '53',
        property_format: ''
      },
      {
        property_name: 'Last Updated',
        property_value: 'Mary J - 3hrs, 27min',
        property_format: ''
      }
    ]
  },
  {
    title: 'IOT: Room Above Temperature',
    variant: 'warning',
    onClick: () => console.log('^^^^^^^'),
    properties: [
      {
        property_name: 'Building ID',
        property_value: 'TD0030',
        property_format: ''
      },
      {
        property_name: 'Sender ID',
        property_value: 'W2312',
        property_format: ''
      },
      {
        property_name: 'Last Reading',
        property_value: '4:14am Today',
        property_format: ''
      }
    ]
  },
  {
    title: 'IOT: Room Above Temperature',
    variant: 'success',
    onClick: () => console.log('^^^^^^^'),
    properties: [
      {
        property_name: 'Building ID',
        property_value: 'TD0030',
        property_format: ''
      },
      {
        property_name: 'Sender ID',
        property_value: 'W2312',
        property_format: ''
      }
    ]
  },
  {
    title: 'IOT: Room Above Temperature',
    variant: 'info',
    onClick: () => console.log('^^^^^^^'),
    properties: [
      {
        property_name: 'Building ID',
        property_value: 'TD0030',
        property_format: ''
      },
      {
        property_name: 'Reading',
        property_value: '53',
        property_format: ''
      },
      {
        property_name: 'Last Updated',
        property_value: 'Mary J - 3hrs, 27min',
        property_format: ''
      }
    ]
  }
]
export const taskRowList: TaskListRowProps[] = [
  { buttonList: buttonList.slice(2) },
  { buttonList }
]

/**
 * TaskListPage Page component.
 *
 * @remarks
 * page where we showcase all task list
 *
 * @component TaskListPage
 * @category Page
 */
const TaskListPage = () => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>TaskView</h4>
              </CardTitle>
              <div className='d-flex flex-wrap gap-2'>
                <TaskView rowList={taskRowList} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default TaskListPage
